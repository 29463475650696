// LIVRAISON : DOMICILE (at-home)
[data-rbs-commerce-shipping-at-home-step] {
  .btn-edit {
    @include poa(2rem, 2rem, auto, auto);
    display: block;
  }

  // gift and comment
  [data-rbs-commerce-shipping-mode-gift],
  [data-rbs-commerce-shipping-mode-message] {
    padding:2rem 0;
    @media (min-width: $sm-width) {
      margin-left: 3.5rem;
    }
  }

  // liste adresses
  .address {
    &-chosen {
      position: relative;
    }

    &-block {
      padding: 2rem;
      position: relative;

      h4 {
        font-weight: default(weight, medium);

        + div {
          margin-bottom: 2rem;
        }
      }

      .btn {
        margin-bottom: 20px;
        margin-top: 0;
        max-width: width(ctn, sm);
      }

      @media (min-width: $md-width) {
        padding-left: 4rem;
      }
    }
  }

  // edition form address
  [data-ng-if="editionForm"] {
    margin: auto;
    max-width: width(ctn, sm);
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

.process-top-menu {
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  width: 350px;
  padding-bottom: 30px;

  &.hidden-xs {
    display: none;

    @media (min-width: $sm-width) {
      display: block;
    }
  }

  + div {
    display: none !important;
  }

  @media (min-width: $md-width) {
    width: 750px;
  }
}

.order{

  .process-top-menu{
    .row{
      .col-sm-12{
        background-color: transparent;
      }
    }
  }
}

[data-rbs-commerce-shipping-mode-gift] {
  display: none;
}

[data-rbs-commerce-top-menu-process] {
  padding-top: 0;
  text-align: center;

  @media (min-width: $sm-width) {
    padding-top: 0;
  }

  .nav {
    margin-top: 2rem;
  }

  .nav-pills {
    align-items: center;
    display: flex;
    justify-content: center;

    > li {
      float: none;
      padding: 0 0.5rem;
      position: relative;
      @media (min-width: $md-width) {
        padding: 0 1.5rem;
      }
      @media (min-width: $xl-width) {
        padding: 0 2.5rem;
      }
      &:not(:last-child):after{
        @media (min-width: $md-width) {
          content:'';
          display: block;
          width:30px;
          height:1px;
          position: absolute;
          left: 100%;
          transform:translateX(-50%);
          top:21px;
          background-color:color(border, medium);
        }
        @media (min-width: $xl-width) {
          width:43px;
        }
      }

      &.active > a,
      &.active > a:focus,
      &.active > a:hover {
        /*  color: #fff;
              background-color: color(button); */
      }

      a {
        opacity: 50%;
        padding:45px 5px 0;

        @media (max-width: $md-width) {
          padding: 0;
        }
      }

      &.done{
        &:after{
          background-color: color(alert, success);
        }
      }
      &.done,
      &.active {
        a {
          opacity: 100%;
        }
      }

      &.active {
        .badge {
          border-color: color(primary, base);
          color: color(primary, base);
          line-height: 3.1rem;
        }
      }

      .badge {
        background-color: transparent;
        border: 1px solid color(border, light);
        border-radius: 30px;
        bottom: auto;
        color: color(text);
        font-size: 16px;
        font-weight: normal;
        height: 3rem;
        left: 50%;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 5px;
        transform: translateX(-50%);
        width: 3rem;

        &.ok {
          background-color: #fff;
          border-color:color(alert, success);
          span{
            &:before{
              color:color(alert, success);
            }
          }

          @media (max-width: $md-width) {
            left: 0;
            top: 0;
          }

          .glyphicon-ok {
            font-size: 10px;
          }
        }

        &:not(.ok) {
          @media (max-width: $md-width) {
            font-size: 16px;
            line-height: 3.1rem;
            position: static;
          }
        }
      }

      .step-name {
        @media (max-width: $md-width) {
          display: none;
        }
      }
    }
  }

  .nav-stacked {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.address-field-g5Code {
  display: none;
}

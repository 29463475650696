// on this website, modal are converted in offcanvas (panel) with css
.modal {
  &-content {
    background-color: color(background, light);
    border: 0;
    border-radius: 0;
    box-shadow: default(shadow);
    max-height: 100%;
    overflow: auto;
    padding-top: 1px;
  }

  &-dialog {
    width: 100%;
  }

  &-header {
    align-items: center;
    background-color: color(background, light);
    border: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
    padding: 2rem;

    &::before,
    &::after {
      content: none;
    }

    .close {
      border-color: color(mono, dark) !important;
      position: absolute;
      right: 2rem;
      top: auto;

      &::before {
        color: color(mono, dark) !important;
      }

      .icon {
        font-size: 1.6rem;
        margin-right: 0;
      }
    }
  }

  h3 {
    font-family: default(font-family, secondary);
  }

  &-title {
    font-size: 1.8rem;
    margin-right: 1rem;
  }

  &-backdrop.out {
    display: none;
  }

  &-body {
    padding: 2rem;

    .add-to-cart {
      align-items: flex-start;
      background-color: color(mono, light);
      border-radius: default(radius, medium);
      box-shadow: default(shadow, light);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding: 2rem 3rem;

      @media (min-width: $sm-width) {
        flex-direction: row;
      }

      .add-to-cart-description,
      .add-to-cart-actions {
        width: 100%;

        @media (min-width: $sm-width) {
          width: 50%;
        }
      }

      .add-to-cart-description {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        padding-right: 2rem;

        @media (min-width: $sm-width) {
          margin-bottom: 0;
        }

        p {
          font-size: 1.4rem;
        }

        [data-ng-if-start="extendedCartBox.reservation.pickUpDateTime"],
        [data-ng-if-start="extendedCartBox.store.pickUpDateTime"] {
          color: color(alert, success);
        }
      }

      .icon {
        align-items: center;
        background-color: color(secondary);
        border-radius: default(radius, medium);
        color: color(mono, light);
        display: flex;
        font-family: default(font-family, icon);
        font-size: 2.6rem;
        height: 40px;
        justify-content: center;
        margin-right: 2rem;
        width: 40px;
      }

      &[data-ng-show="extendedCartBox.reservation.allowed"] {
        .icon {
          font-size: 2.2rem;
        }
      }

      [data-ng-click="selectStore(true)"],
      [data-ng-click="selectStore(false)"] {
        display: none;
      }
    }
  }

  &-footer {
    border: 0;
    display: flex;
    flex-direction: column;
    padding: 2rem padding(panel-x);

    .btn + .btn {
      margin-left: 0;
    }

    > .btn {
      margin: 0;
      width: 100%;

      @media (max-width: $sm-width-max) {
        padding: 1rem 4rem;
      }

      .glyphicon {
        display: none;
      }

      &-primary {
        order: 1;
      }

      &-default {
        margin-top: 1rem;
        order: 2;
      }
    }
  }

  &-loading-content {
    span {
      background-image: url("../img/loader.gif");
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 80%;
      color: transparent;
      display: block;
      height: 8rem;

      img {
        display: none;
      }
    }
  }

  @media (min-width: $md-width) {
    &-dialog {
      width: 66rem;
    }

    &-header {
      padding: 2.7rem 2rem;
    }

    &-title {
      font-size: 2rem;
    }

    &-body {
      padding: 5rem 2rem;
    }

    &-footer {
      padding: 2rem padding(panel-x, md);

      .btn {
        &-default {
          margin-top: 2rem;
        }
      }
    }
  }

  &.modal-popup {
    &.fade {
      .modal-dialog {
        transform: translate(0, -30%);
      }
    }

    &.in {
      .modal-dialog {
        transform: translate(0, 0%);
      }
    }

    .modal {
      &-content {
        border-radius: default(radius, medium);
      }

      &-dialog {
        position: relative;
      }
    }
  }
}

.modal-store-select {
  z-index: 1519 !important;

  .modal {
    &-dialog {
      margin: 2rem !important;

      @media (min-width: $sm-width) {
        margin: 2rem auto !important;
      }
    }

    &-content {
      align-items: flex-end;
      align-items: flex-start;
      background-color: color(mono, light);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 2.4rem 2rem;

      .store-locator {
        &__result-sentence {
          display: none;
        }
      }

      @media (min-width: $sm-width) {
        align-items: flex-end;
        flex-direction: row-reverse;
        padding: 3rem 3rem 10rem 5rem;
      }

      &-right {
        position: absolute;
        right: -80px;
        top: -30px;
        transform: rotate(180deg);
        width: 158px;

        @media (min-width: $sm-width) {
          right: 60px;
          top: -193px;
          width: 370px;
        }

        img {
          width: 100%;
        }
      }

      &-ctn {
        width: 100%;
      }

      &-left {
        margin-top: 1.6rem;
        width: 50px;

        @media (min-width: $sm-width) {
          margin-right: 2.7rem;
          width: 118px;
        }

        img {
          width: 100%;
        }
      }
    }

    &-header {
      align-items: flex-start;
      background-color: transparent;
      display: flex;
      margin-bottom: 1rem;
      padding: 0;

      @media (min-width: $sm-width) {
        margin-bottom: 5rem;
        padding-top: 3rem;
      }

      &-logo {
        width: 125px;

        @media (min-width: $sm-width) {
          width: 233px;
        }

        img {
          width: 100%;
        }
      }
    }

    &-body {
      &-title {
        font-size: 1.6rem;

        @media (min-width: $sm-width) {
          font-size: 1.8rem;
        }
      }
    }

    &-main-content {
      [data-layout-rbs-storelocator-search] {
        .navbar {
          padding-bottom: 3rem;
          padding-top: 3rem;

          @media (min-width: $sm-width) {
            justify-content: flex-start;
            padding-bottom: 5rem;
            padding-top: 1rem;
          }

          .btn-locate-me {
            align-items: center;
            display: flex;
            padding-left: 0;
            padding-right: 0;
            text-align: left;

            .icon {
              font-size: 2rem;
              margin-right: 1rem;
            }
          }
        }

        .content {
          .row {
            display: block;
          }

          .tab-btn {
            display: none;
          }

          .tab-content {
            display: block;
            visibility: visible;
          }

          .col-md-6 {
            &:nth-child(1) {
              li {
                border: 1px solid color(border, medium);
                box-shadow: none;

                @media (min-width: $sm-width) {
                  display: flex;
                }

                .row {
                  @media (min-width: $sm-width) {
                    width: 60%;
                  }
                }

                .btn-responsive-toolbar {
                  align-items: center;
                  display: none;

                  &.popin {
                    display: flex;
                  }

                  @media (min-width: $sm-width) {
                    width: 40%;
                  }

                  .buttons-ctn {
                    .btn {
                      width: 100%;
                    }
                  }

                  a {
                    margin: 0.5rem 0;
                  }

                  .btn-view-store {
                    display: none;
                  }
                }
              }
            }

            &:nth-child(2) {
              display: none;
            }

            .show-all {
              display: block;
            }
          }

          .text-danger {
            text-align: left;
          }
        }
        .noallow {
          .btn-choose-store {
            display: none;
          }
        }
      }
    }

    .content {
      padding: 0 !important;
    }

    .pac-container {
      z-index: 999999;
    }
  }

  &-footer {
    align-items: flex-end;
    display: flex;

    ul {
      padding-left: 1.5rem;
    }

    li {
      font-size: 1.4rem;
      line-height: 2rem;
      list-style: disc;
      margin-bottom: 1rem;
    }
  }
}

.modal-dialog tr.checkbox {
  display: table-row !important;

  .custom-checkbox {
    position: relative;
  }
}

// hide modal popup everywhere

/* [data-layout-project-groupdigital-store-select-popup] {
  display: none;

  ~ .modal-backdrop {
    display: none;
  }

  &.open {
    ~ .modal-backdrop {
      display: block;
    }
  }
} */

/* .modal-open {
  [data-layout-project-groupdigital-store-select-popup] {
    ~ .modal-backdrop {
      display: none;
    }
  }
} */

[data-store-select-popup] {
  .modal-dialog {
    margin: 10px;
    width: auto;

    @media (min-width: $md-width) {
      margin: 30px auto;
      width: 90rem;
    }
    @media (min-width: $lg-width) {
      width: 101rem;
    }
  }

  .modal-body {
    padding: 3px;
  }
}

.modal-rbs-catalog-add-to-cart-confirmation {
  .modal-body {
    padding-top: 0;
  }

  .alert {
    display: none;
  }

  [data-layout-rbs-catalog-product-added-to-cart] {
    .product-added {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;

      &__image {
        width: 11rem;
      }

      &__info {
        margin-left: 1rem;

        h3 {
          font-family: default(font-family);
          font-size: 1.8rem;
          font-weight: default(weight);
          line-height: 2rem;
        }
      }

      &__title {
        font-family: default(font-family);
        font-size: 1.8rem;
        font-weight: default(weight);
        line-height: 2.2rem;
        margin: 0;
      }

      &__variants {
        margin-bottom: 1rem;

        &__item {
          font-size: 1.2rem;
          line-height: 2rem;

          &__title {
            &::after {
              content: ":";
              margin-left: 0.4rem;
            }
          }
        }
      }

      &__price {
        font-size: 1.8rem;
        font-weight: default(weight, medium);
        line-height: 2.2rem;
      }
    }
  }

  .modal-reinsurance {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    padding-left: 20%;

    @media (min-width: $sm-width) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 3rem;
      padding-left: 0;
    }

    &__item {
      align-items: center;
      display: flex;
      font-size: 1.2rem;
      padding: 1rem 0;

      .icon {
        font-size: 1.8rem;
        margin-right: 1rem;
      }
    }
  }

  .modal-footer {
    align-items: flex-start;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    position: fixed;
    width: 100%;

    @media (min-width: $sm-width) {
      bottom: 5rem;
      flex-direction: row-reverse;
    }

    .btn {
      margin-top: 0;
      width: 100%;

      &:first-child {
        @media (max-width: $sm-width) {
          margin-top: 2rem;
        }
      }

      @media (min-width: $sm-width) {
        width: 48%;
      }
    }

    &::before {
      display: none;
    }
  }
}

// [data-layout-rbs-catalog-product-added-to-cart] {
//   padding-top: 10rem;
//   position: relative;

//   @media (min-width: $md-width) {
//     padding-top: 13rem;
//   }

//   .col-sm-4 {
//     flex-basis: 33.33%;
//     max-width: 33.33%;
//     padding: 0;
//     width: 33.33%;

//     @media (min-width: $xs-width) {
//       flex-basis: 20%;
//       max-width: 20%;
//       width: 20%;
//     }
//   }

//   .col-sm-8 {
//     flex-basis: 66.66%;
//     max-width: 66.66%;
//     padding: 0 0 0 1rem;
//     position: static;
//     width: 66.66%;

//     @media (min-width: $xs-width) {
//       flex-basis: 80%;
//       max-width: 80%;
//       width: 80%;
//     }

//     h3 {
//       font-size: 1.6rem;
//       font-weight: default(weight, medium);
//     }

//     .alert {
//       font-size: 1.6rem;
//       font-weight: default(weight, medium);
//       left: 0;
//       position: absolute;
//       text-align: center;
//       top: 0;
//       width: 100%;

//       @media (min-width: $sm-width) {
//         font-size: 2rem;
//       }

//       &-success {
//         &::before {
//           background-color: color(alert, success);
//           border-radius: 50%;
//           color: color(mono, light);
//           content: icon(dot);
//           display: block;
//           font-family: default(font-family, icon);
//           font-size: 2rem;
//           height: 4rem;
//           line-height: 4rem;
//           margin: 0 auto 1rem;
//           text-align: center;
//           width: 4rem;
//         }
//       }
//     }
//   }

//   .row {
//     border: 1px solid color(grey);
//     margin: 0;
//     padding: 2rem 1rem;
//   }
// }

.modal-ajax-waiting-modal {
  .modal-dialog {
    bottom: auto;
    left: 0;
    margin: auto;
    top: 20%;
    width: 30rem;
  }

  &.fade .modal-dialog {
    transform: translate(0, 0);
  }

  .modal-content .modal-content {
    margin-bottom: 0;
  }
}

.modal-shipping-address {
  .modal-body {
    padding-top: 1rem;

    .add-to-cart-availability {
      font-size: 1.4rem;

      &__text {
        margin-bottom: 3rem;
      }

      &__title {
        display: block;
        margin-bottom: 0.5rem;
      }

      input {
        background-color: transparent;
        padding: 1.5rem 1.7rem;
      }
    }

    .guarantee {
      padding-bottom: 2.5rem;

      .product-warranty {
        padding: 1rem 1.5rem;
        margin-bottom: 0.5rem;

        &__line {
          .checkbox {
            margin: 0;
          }
        }

        &__description,
        .label-checkbox {
          font-weight: default(weight, medium);
          margin-right: 1rem;
        }
      }
    }

    .not-in-delivery {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    .modal-reinsurance {
      display: flex;
      flex-direction: column;
      width: 20rem;
      margin: auto;

      @media (min-width: $sm-width) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 3rem;
        padding-left: 0;
        width: auto;
      }

      &__item {
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        padding: 1rem 0;

        .icon {
          font-size: 1.8rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .modal-footer {
    bottom: 5rem;
    padding: 2rem padding(panel-x);
    position: fixed;
    width: 100%;

    .btn {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.modal-shipping-guarantee {
  .product-warranty__loader {
    height: 6rem;

    img {
      max-height: 100%;
    }
  }
}

.modal-shipping-guarantee,
.modal-shipping-address {
  .modal-header {
    .close {
      z-index: auto;
    }
  }

  .product-warranty {
    &__line {
      align-items: center;
      display: flex;
      justify-content: space-between;

      label {
        margin: 0;

        .custom-radio {
          top: 2px;
        }
      }
    }

    .tooltip-ctn:hover .tooltip-helper {
      max-height: 150px;
      transform: translateX(-80%);
      width: 300px;
    }

    .label-checkbox {
      display: inline-block;
      padding: 0;
    }

    &__description,
    &__content {
      align-items: center;
      display: flex;
    }

    &__description {
      justify-content: center;

      .icon {
        font-size: 2rem;
        margin-right: 1rem;
      }
    }

    &__content {
      justify-content: space-between;
      margin-top: 1rem;

      @media (min-width: $sm-width) {
        margin-top: 0;
      }
      @media (min-width: $md-width) {
        .icon {
          margin-left: 1.5rem;
        }
      }
    }

    &__cta {
      text-decoration: underline;

      @media (min-width: $sm-width) {
        margin-left: 2rem;
      }
    }

    &__loader {
      height: 5rem;

      img {
        max-height: 100%;
      }
    }
  }
}

.cart-modal-address {
  margin-top: 5rem;

  span {
    display: block;
    font-weight: default(weight, medium);
    margin-bottom: 1rem;
  }
}

.modal-rbs-catalog-cart-box {
  .container-fluid {
    &[data-ng-show="extendedCartBox"] {
      padding: 0;
    }
  }
}

[data-rbs-catalog-quick-buy-modal] {
  [data-layout-rbs-catalog-product-detail] {
    .product-details {
      margin-top: 0;
      padding-bottom: 0;

      .product-features {
        display: none;
      }
    }

    .col-lg-6 {
      flex-basis: 100%;
      max-width: 100%;
    }

    .media-visuals {
      .lg10 {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .comparator-button,
    .product-services,
    .contact-block {
      display: none;
    }

    .product-header {
      margin-top: 1rem;
    }
  }
}

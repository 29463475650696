.alert {
  background-color: transparent;
  border: 0;
  border-radius: default(radius);
  display: inline-block;
  margin-bottom: 1.5rem;
  padding: 0;

  &-fw {
    text-align: center;
    width: 100%;
  }

  &-success {
    color: color(alert, success);
    background-color: rgba(color(alert, success), 0.2);
    border: 1px solid color(alert, success);
    padding: 1rem 2rem;
  }

  &-warning {
    color: color(alert, warning);
    background-color: rgba(color(alert, warning), 0.2);
    border: 1px solid color(alert, warning);
    padding: 1rem 2rem;
  }

  &-danger {
    color: color(alert, danger);
    background-color: rgba(color(alert, danger), 0.2);
    border: 1px solid color(alert, danger);
    padding: 1rem 2rem;
  }

  &-info {
    color: color(alert, info);
    background-color: rgba(color(alert, info), 0.2);
    border: 1px solid color(alert, info);
    padding: 1rem 2rem;
  }
}

.label {
  &-success {
    color: color(alert, success);
  }

  &-warning {
    color: color(alert, warning);
  }

  &-danger {
    color: color(alert, danger);
  }

  &-success,
  &-warning,
  &-danger {
    background-color: transparent;
  }
}

.text {
  &-success {
    color: color(alert, success);
  }

  &-warning {
    color: color(alert, warning);
  }

  &-danger {
    color: color(alert, danger);
    font-weight: default(weight);
  }

  &-success,
  &-warning,
  &-danger {
    background-color: transparent;
  }
}

// alert availability
.availability-title {
  &.alert {
    margin-bottom: 0;
    text-align: left;
  }
}

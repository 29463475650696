// reviews

.reviews-panel {
  h3 {
    letter-spacing: 0;
    margin-top: 30px;
    padding-left: 0;

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .panel-heading {
    border: 0;
    font-size: 14px;
    text-align: left;

    small {
      &.text-muted {
        display: none;
      }
    }
  }

  .panel-body {
    border-bottom: 1px solid color(border);
    font-weight: normal;
    padding-top: 0;
  }

  // tableau des reviews
  .reviews {
    padding-left: 1rem;

    .row:first-child {
      .col-sm-5,
      .col-sm-7 {
        display: none;
      }
    }
  }

  // add/edit a review
  .user-interaction {
    width: 100%;

    .img-avatar {
      display: none;
    }

    .btn-default {
      display: block;
      margin-top: 10px;

      @media (min-width: $xs-width) {
        float: right;
      }
    }
  }

  .review-addition {
    .btn-default {
      float: none;
    }
  }

  // review-edition
  .review-edition,
  .review-addition {
    p {
      &::after {
        clear: both;
        content: "";
        display: block;
        margin-bottom: 15px;
      }
    }

    .form-group {
      &:first-child {
        display: none;
      }

      &:nth-child(2) {
        .star-rating-input {
          li {
            &:first-child,
            &:nth-child(2) {
              display: none;
            }
          }

          .text-primary {
            color: color(primary);
          }
        }
      }

      &:last-child {
        .col-sm-offset-4 {
          margin-left: 0;
        }

        .btn {
          margin-top: 1rem !important;

          &-primary {
            float: right;
          }

          &-default {
            float: left;
          }

          &-danger {
            margin-right: 1rem !important;

            &:hover {
              background-color: color(primary);
              border-color: color(primary);
            }
          }
        }

        @media (max-width: $xs-width) {
          .btn {
            &-danger {
              display: none !important;
            }
          }
        }
      }
    }
  }

  // reviews list
  .reviews-list {
    .img-avatar,
    .panel-footer {
      display: none;
    }

    .col-sm-12 {
      width: 100%;
    }
  }
}

.cleverage-product-reviews {
  background-color: color(mono, light) !important;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 0 2rem 5rem 2rem;

  @media (min-width: $md-width) {
    margin-left: -5rem;
    margin-right: -5rem;
    padding: 0;
  }

  &__sort {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 2rem;

    &__logo {
      margin-right: 2rem;
      img {
        display: flex;
      }
    }

    &__infos {
      .info-item {
        font-weight: default(weight, medium);
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          margin-right: 1.5rem;
        }

        .rate {
          font-size: 1.2rem;
          font-weight: default(weight);
        }
      }
    }
  }

  &__content {
    margin: auto;
    padding: 0 2rem;
    background-color: color(background, light);
    border-radius: default(radius, medium);
    @media (min-width: $md-width) {
      max-width: 73.3%;
      padding: 2rem;
    }
    @media (min-width: $xl-width) {
      max-width: 60%;
    }

    .product-reviews-list {
      &__item {
        padding: 2rem 0;
        border-top: 1px solid color(border, dark);
        &__title {
          font-size: 1.4rem;
          font-weight: default(weight);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            margin-right: 1.5rem;
          }

          .rate {
            font-size: 1.2rem;
            font-weight: default(weight);
          }
        }

        &__review {
          background-color: color(mono, light);
          padding: 1rem;
          font-size: 1.4rem;
          margin-top: 1rem;
          border-radius: default(radius, medium);
          position: relative;
          box-shadow: default(shadow);

          .marker {
            width: 1rem;
            height: 1rem;
            background-color: color(mono, light);
            position: absolute;
            top: -0.5rem;
            left: 2rem;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .separator {
    height: 2rem;
    @media (min-width: $md-width) {
      height: 0;
    }
  }
}

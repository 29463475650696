$zindex-modal-custom-background: 1501;
$zindex-modal-custom: 1511;

// Padding applied to the modal body
$modal-custom-inner-padding: 15px;

// Padding applied to the modal-custom title
$modal-custom-title-padding: 15px;

// modal-custom title line-height
$modal-custom-title-line-height: 1.1;
$modal-custom-radius: 0;

// Background color of modal-custom content area
$modal-custom-content-bg: #fff;

// modal-custom content border color
$modal-custom-content-border-color: rgba(0, 0, 0, 20%);

// modal-custom content border color for IE8
$modal-custom-content-fallback-border-color: #999;
$modal-custom-dialog-margin: 15px;

// modal-custom backdrop background color
$modal-custom-backdrop-bg: #000;

// modal-custom backdrop opacity
$modal-custom-backdrop-opacity: 0.5;

// modal-custom header border color

$modal-custom-header-border-color: #e5e5e5;

// modal-custom footer border color
$modal-custom-footer-border-color: $modal-custom-header-border-color;
$modal-custom-lg: 720px;
$modal-custom-md: 600px;
$modal-custom-sm: 300px;

// modal-customs
// --------------------------------------------------

// .modal-custom-open      - body class for killing the scroll
// .modal-custom           - container to scroll within
// .modal-custom-dialog    - positioning shell for the actual modal-custom
// .modal-custom-content   - actual modal-custom w/ bg and corners and shit

// Kill the scroll on the body
.modal-custom-open,
.modal-open {
  overflow: hidden;
}

// Container that the modal-custom scrolls within
.modal-custom {
  bottom: 0;
  display: none;
  left: 0;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zindex-modal-custom;

  &.open {
    display: block;
  }

  &.prevent-close {
    .close {
      display: none;
    }
  }

  // When fading in the modal-custom, animate it to slide down
  &.fade .modal-custom-dialog {
    transform: translate(0, -25%);
    transition: 0.3s ease-out;
  }

  &.in .modal-custom-dialog {
    transform: translate(0, 0);
  }
}

.modal-custom-open .modal-custom {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal-custom with bottom padding
.modal-custom-dialog {
  margin: 10px;
  position: relative;
  width: auto;

  &-centered {
    align-items: center;
    display: flex;
    min-height: calc(100% - #{$modal-custom-dialog-margin} * 2);
  }
}

// Actual modal-custom
.modal-custom-content {
  background-color: $modal-custom-content-bg;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;

  > .close {
    z-index: 1;
  }
}

// modal-custom background
.modal-custom-backdrop {
  background-color: $modal-custom-backdrop-bg;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;

  // Fade for backdrop
  .modal-custom.fade & {
    opacity: 0%;
  }

  .modal-custom.in & {
    opacity: $modal-custom-backdrop-opacity;
  }
}

// modal-custom header
// Top section of the modal-custom w/ title and dismiss
.modal-custom-header {
  border-bottom: 1px solid $modal-custom-header-border-color;
  padding: $modal-custom-title-padding;
}

// Close icon
.modal-custom-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-custom-title {
  line-height: $modal-custom-title-line-height;
  margin: 0;
}

// modal-custom body
// Where all modal-custom content resides (sibling of .modal-custom-header and .modal-custom-footer)
.modal-custom-body {
  padding: $modal-custom-inner-padding;
  position: relative;
}

// Footer (for actions)
.modal-custom-footer {
  border-top: 1px solid $modal-custom-footer-border-color;
  padding: $modal-custom-inner-padding;
  text-align: right; // right align buttons

  // Properly space out buttons
  .btn + .btn {
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    margin-left: 5px;
  }

  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }

  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal-custom show/hide
.modal-custom-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

// Scale up the modal
@media (min-width: $sm-width) {
  // Automatically set modal's width for larger viewports
  .modal-custom-dialog {
    margin: 30px auto;
    width: $modal-custom-md;
  }

  // Modal sizes
  .modal-custom-sm {
    width: $modal-custom-sm;
  }
}
@media (min-width: $md-width) {
  .modal-custom-lg {
    width: $modal-custom-lg;
  }
}

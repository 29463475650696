// inté
[data-layout-rbs-commerce-cart] {
  @extend .ctn-fluid;
  margin: auto;
  max-width: 180rem;
  padding-bottom: 6rem;
  padding-left: 0;
  padding-right: 0;
}

[data-ng-repeat="delivery in cartData.deliveries"] {
  margin-bottom: 8rem;

  h3 {
    @extend .h4;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 3.5rem;
    position: relative;

    @media (min-width: $md-width) {
      font-size: 1.8rem;
      margin-bottom: 4rem;
    }

    &::before {
      align-items: center;
      background-color: color(secondary);
      border-radius: default(radius, medium);
      color: color(mono, light);
      content: "";
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      font-family: icomoon;
      font-size: 2rem;
      height: 4rem;
      justify-content: center;
      margin-right: 1rem;
      text-align: center;
      width: 4rem;

      @media (min-width: $md-width) {
        font-size: 3rem;
        height: 5rem;
        width: 5rem;
      }
    }
  }

  &.atHome {
    h3::before {
      content: icon(reassurance_carrier);
    }
  }

  &.store,
  &.reservation {
    background-color: transparent;
    margin: 0;
    padding: 0;

    h3::before {
      content: icon(store);
    }
  }
}

[data-rbs-commerce-cart-delivery] {
  h3 {
    .btn {
      border: 0;
      border-radius: 0;
      color: color(text);
      flex-shrink: 0;
      font-size: 1.4rem;
      font-weight: default(weight);
      line-height: 1.6;
      margin-left: auto;
      padding: 0;
      padding-left: 1rem;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        box-shadow: none;
        color: color(primary);
      }
    }
  }

  .table {
    margin-bottom: 0;
  }

  .table-vertical-responsive {
    thead {
      display: none;
    }

    [data-role="cart-lines"] {
      [rbs-commerce-cart-line-default],
      [rbs-commerce-cart-line-readonly] {
        & > td > .table > tbody > tr {
          @extend .white-box-padded;
          display: flex;
          margin-bottom: 3rem;
          padding: 2rem 1.5rem;

          &:last-child {
            margin-bottom: 0;
          }

          > td {
            border: 0;
            display: block;
            padding: 0;

            &.line-visual {
              padding-right: 1rem;
              position: static;

              @media (max-width: $sm-width-max) {
                width: 30%;
              }

              [data-rbs-commerce-cart-line-visual] {
                font-size: 0;
              }
            }

            &.line-detail {
              flex-basis: 50%;
              flex-grow: 1;

              @media (max-width: $sm-width-max) {
                flex-grow: 1;
              }

              .line-detail {
                &__title {
                  font-size: 1.8rem;
                }

                &__brand {
                  font-size: 1.8rem;
                  font-weight: default(weight, bold);
                  line-height: default(line-height);
                }

                &__reference {
                  font-size: 1.2rem;
                  line-height: default(line-height);
                }

                &__delivery {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 1.4rem;
                  font-weight: 400;

                  .icon {
                    font-size: 0.8rem;
                    margin-right: 0.5rem;
                    color: color(alert, success);
                  }

                  strong {
                    color: color(alert, success);
                    font-weight: 500;
                  }
                }
              }
            }

            &.line-actions {
              margin-left: 30%;
              padding-top: 2rem;
              position: relative;
              width: 70%;

              @media (min-width: $sm-width) {
                flex-grow: 1;
                margin-left: 0;
                max-width: 32rem;
                padding-top: 0;
                position: static;
                width: auto;
              }

              .row {
                @media (max-width: $sm-width-max) {
                  margin: 0;
                }
              }

              .line-quantity {
                flex-grow: 0;
                padding-right: 0;

                @media (max-width: $sm-width-max) {
                  flex-basis: auto;
                  margin-left: 0;
                  order: -1;
                }

                input {
                  border: 1px solid color(grey, light);
                  border-radius: default(radius, medium);
                  box-shadow: default(shadow, light);
                  height: 5rem;
                  max-width: 100%;
                  padding-left: 1rem;
                  padding-right: 0;
                  width: 100%;
                }

                .form-inline {
                  display: flex;
                }

                .btn {
                  border: 0;
                  color: color(text);
                  font-size: 2rem;
                  margin-left: 1rem;
                  padding: 0;

                  &:hover,
                  &:focus,
                  &:active {
                    background-color: transparent;
                    box-shadow: none;
                    color: color(primary);
                  }
                }
              }

              /* [data-rbs-commerce-line-actions] {

              } */
              .line-price-actions > div {
                @media (max-width: $sm-width-max) {
                  display: flex;
                  flex-wrap: nowrap;

                  > div {
                    flex-grow: 1;
                  }

                  > div:first-child {
                    flex-basis: 50%;
                    min-width: 50%;
                    order: 2;
                    width: 50%;
                  }

                  > div:nth-child(2) {
                    flex-grow: 0;
                    order: 1;
                    width: auto;
                  }
                }
              }

              .line-prices {
                text-align: right;

                @media (max-width: $sm-width-max) {
                  flex-basis: 100%;
                  justify-content: flex-end;
                  margin-left: 0;
                }

                // advised price block
                .advised {
                  &-price-ctn {
                    display: block;
                    line-height: 1.1;
                    margin-bottom: 1rem;
                    padding-right: 0;

                    .tooltip-ctn {
                      padding: 0;

                      .icon-infos {
                        @extend .body-xs;
                      }

                      @media (max-width: $sm-width-max) {
                        .tooltip-helper {
                          transform: translateX(-90%);

                          &::after {
                            left: 90%;
                          }
                        }
                      }
                    }
                  }

                  &-label {
                    @extend .body-xs;
                    @extend .regular;
                  }

                  &-price {
                    text-decoration: none;

                    @extend .body-small;
                  }
                }

                ul {
                  display: block;
                  margin-top: 1rem;

                  span {
                    font-size: 1.8rem;
                  }

                  .price-value {
                    font-size: 1.8rem;
                    font-weight: default(weight, bold);

                    &.colored-price {
                      color: color(secondary);
                    }
                  }

                  .price-old-value {
                    font-size: 1.6rem;
                    margin-left: 1.5rem;
                    text-decoration: line-through;
                  }

                  @media (min-width: $sm-width) {
                    justify-content: flex-end;
                    margin-top: 0;
                  }
                }
              }
            }
          }

          .line-actions {
            &-btns {
              width: 50%;

              .btn-responsive-toolbar {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;

                a {
                  flex-basis: 100%;
                }
              }

              .btn,
              a {
                border: 0;
                border-radius: 0;
                color: color(text);
                display: block;
                font-size: 1.4rem;
                font-weight: default(weight);
                line-height: 1.6;
                padding: 0;

                &:not(.btn-delete) {
                  text-decoration: underline;
                }

                &:hover,
                &:focus,
                &:active {
                  background-color: transparent;
                  box-shadow: none;
                  color: color(primary);
                }
              }

              .btn {
                font-size: 0;
                margin-top: 1rem;
                overflow: visible;
                text-align: right;

                .glyphicon-remove {
                  font-family: "icomoon";

                  &::before {
                    content: icon(trash);
                    font-family: "icomoon";
                    font-size: 2rem;
                  }
                }
              }

              @media (min-width: $sm-width) {
                position: static;
                width: auto;
              }
            }
          }
        }

        .line-cart-default-ctn {
          background-color: transparent;
          border: 0;
          padding: 0;

          > .table {
            background-color: transparent;
          }

          // guarantie line in cart
          .line-options {
            align-items: flex-end;
            background-color: transparent;
            box-shadow: none;
            flex-direction: column;
            padding: 0;

            td {
              width: 83.3333%;
            }
          }

          @media (max-width: $sm-width-max) {
            flex-grow: 1;

            .line-options {
              td {
                width: 92%;
              }
            }
          }
        }
      }

      > tr {
        background-color: transparent;
      }
    }
  }

  [data-ng-if="showAllAmounts"] {
    .btn {
      border: 0;
      border-radius: 0 !important;
      color: color(text);
      font-weight: default(weight);
      padding: 0;
      width: 100%;

      > span {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 2rem 0;
        width: 100%;

        .glyphicon {
          order: 1;

          &::before {
            content: "\e903";
            font-family: default(font-family, icon);
            font-size: 2rem;
            padding: 2rem;
          }
        }

        &:last-child {
          .glyphicon {
            &::before {
              content: "\e904";
            }
          }
        }
      }

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        box-shadow: none;
        color: color(primary);
      }
    }
  }

  [data-ng-show="line.options.pickUpDateTime"] {
    align-items: center;
    color: color(alert, success);
    display: flex;
    font-size: 1.4rem;
    font-weight: default(weight) !important;
    justify-content: flex-start;
    margin-top: 1rem;

    &::before {
      background-color: color(alert, success);
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 8px;
      margin-right: 5px;
      width: 8px;
    }
  }

  .cart-line-more {
    padding-left: 2rem;

    @media (min-width: $md-width) {
      padding-left: 7rem;
    }
    @media (min-width: $xl-width) {
      padding-left: 10rem;
    }
    @media (min-width: $xxl-width) {
      padding-left: 15rem;
    }

    &__item {
      align-items: center;
      column-gap: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem 1.5rem;

      &:first-child {
        margin-top: -1.5rem;
      }

      &:last-child {
        margin-bottom: 3rem;
      }

      i {
        font-size: 1.2rem;
      }

      &-title {
        font-size: 1.4rem;
        font-weight: default(weight, medium);
      }

      &-subtitle {
        font-size: 1.2rem;
        font-weight: default(weight, medium);
      }

      &-list {
        font-size: 1.2rem;

        li {
          align-items: flex-start;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          padding: 0.25rem 0;

          &::before {
            content: icon(check);
            display: block;
            font-family: default(font-family, icon);
            height: 10px;
            margin-right: 0.5rem;
            width: 10px;
          }
        }
      }

      &-infos {
        width: 70%;

        &--input {
          width: 100%;
        }

        label {
          font-size: 1.4rem;
          font-weight: default(weight, medium);
        }
      }

      &-details {
        text-align: right;
        width: 30%;

        &-prices {
          span {
            font-size: 1.8rem;
            font-weight: default(weight, bold);
          }

          .tooltip-helper {
            @media (max-width: $sm-width-max) {
              transform: translateX(-90%);

              &::after {
                left: 90%;
              }
            }
          }
        }

        &-cta {
          font-size: 1.4rem;
          font-weight: default(weight, medium);
          text-decoration: underline;
        }
      }
    }
  }

  .table-amounts {
    td {
      color: color(text, base);
    }
  }

  .more-total {
    padding: 1rem 1.5rem;
  }
}

.product-cashback-offers {
  border-radius: default(radius, medium);
  display: flex;
  margin-bottom: 2rem;
  overflow: hidden;

  &__item {
    align-items: center;
    border: 2px solid;
    color: color(text, light);
    display: flex;
    justify-content: center;
    line-height: 1.4rem;
    min-height: 3.4rem;
    padding: 0.5rem 1rem;
    text-align: center;

    //white-space: nowrap;

    &.left {
      background-color: color(primary);
      border-color: color(primary);
      //flex-basis: 32%;
    }

    &.right {
      background-color: color(secondary);
      border-color: color(secondary);
      //flex-basis: 68%;
      //max-width: 68%;
      flex-grow: 1;

      a {
        &:hover {
          color: color(mono, light);
        }
        &:active,
        &:focus {
          color: color(mono, light);
          text-decoration: none;
        }
      }
    }

    @media (max-width: $lg-width-max) {
      min-height: 2.8rem;

      .right {
        //flex-basis: 60%;
        max-width: 60%;
      }

      /*  &.left {
        flex-basis: 40%;
      } */

      .body-regular {
        font-size: 1.2rem;
      }
    }
    @media (min-width: $md-width) {
      &__item {
        padding: 0.5rem 2rem;
      }
    }
  }

  + .product-cashback-offers {
    margin-top: -1rem;
  }
}

// in listing
.product-odr {
  @media (min-width: $sm-width) {
    display: none;
  }

  /*  &-item {
    color: color(mono, light);
    font-size: 1.2rem;
    font-weight: default(weight, bold);
    line-height: 1.4;
    padding: 0.5rem 1rem;
    text-align: left;

    @media (min-width: $sm-width) {
      flex-grow: 1;
    }
  } */

  &-pc {
    display: none;

    @media (min-width: $sm-width) {
      display: block;
    }
  }
}

.thumbnail-product {
  .product-odr {
    .body-regular {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    .product-cashback-offers {
      flex-direction: column;
    }

    /*  .right,
    .left {
      flex-basis: 100%;
      max-width: 100%;
    } */

    @media (min-width: $sm-width) {
      .product-cashback-offers {
        flex-direction: row;
      }

      /* .right {
        flex-basis: 60%;
        max-width: 60%;
      }

      .left {
        flex-basis: 40%;
      } */
    }
  }
}

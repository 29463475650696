.dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px color(shadow, navbar);

  .divider {
    display: none;
  }

  > li {
    padding: 0 2rem;

    > a {
      padding: 0.5rem 1rem;
    }
  }
}

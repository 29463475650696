[data-layout-rbs-website-thread] {
  @extend .max-width-offset-container;
  margin: 0 -2rem;

  @media (min-width: $md-width) {
    margin: 0 (-$gutter-ctn);
  }
}

.breadcrumb {
  background-color: color(mono, light);
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0;
  padding: 0;

  &__inner {
    @extend .max-container;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: auto;
    padding: 1rem 2rem !important;
    width: 100%;

    @media (min-width: $md-width) {
      padding: 1rem 3.5rem !important;
    }

    > li {
      color: color(text);
      opacity: 50%;
      white-space: nowrap;

      &.active {
        color: color(text);
        opacity: 100%;
      }

      + li::before {
        content: ">";
        padding: 0 5px;
      }
    }
  }

  &.bg-grey {
    background-color: color(grey, light);
  }

  &.light {
    > li {
      color: color(mono, light);

      &.active {
        color: color(mono, light);
      }
    }
  }
}

[data-name="Rbs_Website_Thread"] nav {
  overflow: auto;
}

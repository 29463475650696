// génération de class utilitaires de couleur
// stylelint-disable-next-line scss/dollar-variable-default

$colors-bg: (
  "black": color(mono, dark),
  "white": color(mono, light),
  "grey": color(grey, base),
  "grey-light": color(grey, light),
  "grey-medium": color(grey, medium),
  "grey-dark": color(grey, dark),
  "primary": color(primary, base),
  "secondary": color(secondary, base),
  "tertiary": color(tertiary, base),
  "quaternary": color(quaternary, base),
);
$colors-txt: (
  "white": color(text, light),
  "medium": color(text, medium),
  "default": color(text, base),
  "dark": color(text, dark),
  "light": color(text, light),
  "primary": color(primary, base),
  "secondary": color(secondary, base),
  "tertiary": color(tertiary, base),
  "quaternary": color(quaternary, base),
);

@mixin text-color($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color;
  }
}

/* background colors (bootstrap is _background-variant) */
@mixin bg-color($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color;
    color: inherit;
  }
}

@each $color, $value in $colors-bg {
  @include bg-color(".bg-#{$color}", $value, true);
}
@each $color, $value in $colors-txt {
  @include text-color(".color-#{$color}", $value, true);
}

.text-primary {
  color: color(secondary);
}

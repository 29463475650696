// bloc store : editable
[rbs-storeshipping-store-mode-editor] {
  margin-top: 15px;
  padding-left: 3.5rem;

  > div {
    display: flex;
    flex-direction: column;

    [data-rbs-commerce-shipping-mode-gift] {
      margin-left: 0;
      order: 1;
    }

    > .half-line-bottom-margin {
      order: 2;
    }
  }

  > div > .row > .col-sm-6 {
    flex-basis: 100%;
    max-width: 100%;
  }

  [data-rbs-storeshipping-product-locator] {
    margin: auto;
    max-width: width(ctn, sm);
  }

  .half-line-bottom-margin {
    [data-ng-click="inEdition(true)"] {
      @extend .btn-link;
      display: block;
      margin: 1rem 0 2rem;
    }

    .form-group,
    .form-control {
      width: 100% !important;
    }

    h4 {
      font-weight: default(weight, bold);
      margin: 1rem 0;
    }

    .storeshipping-stores-list {
      padding: 0;

      > div {
        max-width: width(ctn, sm);
      }
    }
  }

  @media (min-width: $sm-width) {
    padding-left: 4rem;
  }
}

// bloc store : non editable (résumé)
[rbs-storeshipping-store-mode-summary] {
  .col-sm-6 {
    @media (max-width: $xs-width) {
      width: 100%;
    }
  }
}


[rbs-storeshipping-reservation-mode-editor]{
  [data-rbs-commerce-shipping-mode-message]{
    padding:2rem 0;
  }
}
// SHIPPING  : SPECIFIC

// MONDIAL RELAY
[rbs-mondialrelay-mode-editor] {
  padding-bottom: 2rem;
  .one-line-bottom-margin {
    margin-bottom: 2rem;
  }

  // fix grid
  .col-sm-12 {
    width: 100%;

    @media (max-width: $sm-width) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  // show label (not sr-only)
  label.sr-only {
    clip: initial;
  }

  // relay list
  .list-group-item.active,
  .list-group-item.active:focus,
  .list-group-item.active:hover {
    background-color: color(primary);
    border-color: color(border, light);
    text-shadow: none;
  }

  // btn locate me in first
  form {
    display: flex;
    flex-direction: column;

    > div,
    button {
      order: 1;
    }

    [data-ng-click="locateMe()"] {
      order: 0;
    }
  }

  .btn-primary {
    @extend .btn-secondary;
  }

  // padding
  .half-line-bottom-margin:nth-child(2) > .col-xs-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

// COLISSIMO
[rbs-socolissimo-mode-editor] {
  // fix big street field
  .one-line-bottom-margin > .col-sm-12 {
    max-width: 100%;

    input {
      max-width: 100%;
    }
  }
}

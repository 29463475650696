.sidebar-content {
  [data-rbs-website-menu] {
    margin-bottom: 4rem;
    padding-top: 2rem;

    @media (min-width: $md-width) {
      padding-top: 4rem;
    }

    h3 a {
      padding-top: 0;
    }

    a {
      display: block;
      font-size: 1.4rem;
      padding: 1rem 2rem;

      &:hover,
      &:focus {
        background-color: color(grey, light);
        color: color(text);
        text-decoration: none;
      }
    }

    ul {
      padding: 0;
    }

    li {
      margin: 0;

      &.active {
        a {
          background-color: color(grey, light);
          color: color(text, dark);
          font-weight: default(weight, medium);
        }
      }

      &::before {
        content: none;
      }
    }

    h3 {
      @extend .h4;
      font-weight: default(weight, medium);
    }
  }
}

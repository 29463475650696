.btn {
  background: color(button);
  background-position: center;
  border: 1px solid color(button);
  border-radius: default(radius, button);
  box-shadow: none;
  color: color(text, light);
  cursor: pointer;
  display: inline-block;
  font-family: default(font-family);
  font-size: 1.4rem;
  font-weight: default(weight, medium);
  line-height: 1;
  outline: 0 !important;
  overflow: hidden;
  padding: 1rem 1.5rem 0.9rem;
  position: relative;
  text-decoration: none;
  text-shadow: none;
  transition: all 0.3s ease 0s;
  white-space: normal;

  &.loading {
    background-image: url(../img/loader-white.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 80%;
    color: transparent;
  }

  @media (min-width: $md-width) {
    padding: 1.4rem 3rem 1.3rem;
  }

  .icon {
    transition: all 0.3s ease 0s;
  }

  &:hover,
  &:focus {
    background-color: color(button, light);
    text-decoration: none;
  }

  &:active,
  &:active:focus,
  &:active:hover {
    background-color: color(button, light);
    box-shadow: none;
  }

  &:disabled,
  &[disabled],
  &:disabled:hover,
  &.disabled:hover {
    background-color: color(button, disabled) !important;
    border-color: transparent;
    box-shadow: none !important;
    opacity: 80%;
  }

  .icon {
    font-size: 2.5rem;
    margin-right: 1rem;
    vertical-align: middle;
  }

  // level of importance
  &-primary {
    &-light {
      background-color: color(mono, light);
      color: color(text);

      &:hover,
      &:focus {
        background-color: color(mono, light);
        color: color(text);
      }

      &:active,
      &:active:focus,
      &:active:hover {
        background-color: color(mono, light);
        color: color(text);
      }
    }
  }

  &-availability {
    align-items: center;
    display: flex;
    justify-content: center;

    &::before {
      color: color(alert, danger);
      content: "\e946";
      font-family: default(font-family, icon);
      font-size: 0.8rem;
      margin-right: 0.5rem;
    }

    &.available {
      &::before {
        color: color(alert, success);
      }
    }
  }

  &-secondary,
  &-default {
    background: transparent;
    border: 1px solid color(button);
    color: color(button);

    &:hover,
    &:active:hover {
      background-color: color(button);
      border-color: color(button);
      color: color(text, light);
      text-decoration: none;
    }

    &:focus,
    &:active,
    &:active:focus {
      background: transparent;
      border: 1px solid color(button);
      color: color(button);
    }

    &:active,
    &:active:focus,
    &:active:hover {
      box-shadow: none;
    }

    &:disabled,
    &[disabled],
    &:disabled:hover {
      background: transparent !important;
      background-color: transparent !important;
      border: 1px solid color(border, medium) !important;
      color: color(text, medium) !important;
    }

    &-light {
      background: transparent;
      border: 1px solid color(border, light);
      color: color(text, light);

      &:hover,
      &:focus,
      &:active,
      &:active:focus,
      &:active:hover {
        background-color: color(background, light);
        color: color(text);
      }
    }
  }

  &-ternary {
    background: transparent !important;
    border: none;
    color: color(text);
    font-size: 1.4rem;
    padding: 0.2rem;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus,
    &:active:focus,
    &:active:hover {
      background-color: color(button);
      border-color: color(button);
      box-shadow: none;

      //color: color(text, medium);
      text-decoration: none;

      .icon {
        border-color: color(text, medium) !important;
      }
    }

    &:disabled,
    &[disabled],
    &:disabled:hover {
      background: transparent !important;
      color: color(text, medium);
      text-decoration: underline;
    }
  }

  &-unstyled {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    line-height: 1;
    padding: 0;

    &:hover,
    &:active,
    &:focus,
    &:active:focus,
    &:active:hover {
      background: transparent;
      box-shadow: none;
      color: inherit;
    }
  }

  &-fw {
    text-align: center;
    width: 100%;
  }

  &-small {
    padding: 1rem 4rem;
  }

  &-small-mobile {
    @media (max-width: ($sm-width - 1px)) {
      padding: 1rem 4rem;
    }
  }

  &-big {
    padding: 2rem 6rem;
  }

  &-link {
    background-color: transparent;
    border: 0 !important;
    color: color(text);
    font-size: 1.2rem;
    font-weight: default(weight);
    overflow: visible;
    padding: 0;
    position: relative;

    &::after {
      border-bottom: 1px solid color(text);
      bottom: -4px;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover {
      background-color: transparent;
      box-shadow: none;
      color: color(primary);
      text-decoration: none;

      &::after {
        border-bottom: 1px solid color(primary);
      }
    }

    &:disabled,
    &[disabled],
    &:disabled:hover {
      background: transparent !important;
      color: color(text, medium);
      text-decoration: none;

      &::after {
        border-color: color(border, medium);
      }
    }

    &--alt {
      color: color(grey, dark);
      font-size: 1.4rem;
      line-height: 1.5rem;

      &::after {
        border-bottom: 1px solid color(grey, dark);
        bottom: 0;
      }
    }
  }

  &-axes {
    background-color: transparent;
    border: none;
    border-radius: 0 !important;
    color: color(text);
    font-weight: normal;
    margin: 1rem;
    overflow: visible;
    padding: 0;

    &::after {
      background-color: color(tertiary);
      content: "";
      height: 2px;
      left: 50%;
      position: absolute;
      top: 1.6rem;
      transition: width 0.3s ease, left 0.3s ease;
      width: 0;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover,
    &.selected {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      font-weight: 500;

      &::after {
        left: 0;
        width: 100%;
      }
    }
  }

  &-prev,
  &-next {
    align-items: center;
    background-color: transparent;
    border: 1px solid color(border);
    border-radius: default(radius, big) !important;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 1rem;
    width: 40px;

    &::before {
      color: color(text);
      content: "\e90f";
      font-family: default(font-family, icon);
      font-size: 1.2rem;
      font-weight: default(weight, bold);
      transition: color 0.3s ease 0s;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover {
      background-color: color(background);
      box-shadow: none;
      text-decoration: none;

      &::before {
        color: color(text, light) !important;
      }
    }

    &:disabled,
    &[disabled],
    &:disabled:hover {
      background: transparent !important;
      border-color: color(border, medium);

      &::before {
        color: color(text, medium) !important;
      }
    }
  }

  &-prev {
    transform: rotate(180deg);
  }
}

// surcharge bootstrap

.btn-social {
  @include size(30);
  background: color(mono, dark);
  border-radius: 50px;
  display: block;
  position: relative;

  .icon {
    color: color(mono, light);

    @extend %full-center;
  }

  &:hover {
    background-color: color(background);
  }
}

/*** product qty ****/
.product-qty {
  text-align: center;

  &-ctn {
    margin-bottom: 10px;
    position: relative;
  }

  &-down,
  &-up {
    background: none;
    border: 0;
    color: color(text);
    cursor: pointer;
    font-size: 2rem;
    height: 100%;
    line-height: 4rem;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 35px;
    z-index: 2;
  }

  &-down {
    left: 0;
  }

  &-up {
    right: 0;
  }

  &-btn,
  &-input {
    font-weight: default(weight, bold);
  }

  &-input {
    border: 1px solid color(border);
    border-radius: default(radius);
    color: color(text);
    height: 4.1rem;
    padding: 0 35px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
  }
}

button.close {
  font-size: 0;
  font-weight: normal;
  line-height: 1.2;
  opacity: 100%;
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 999;

  &::before {
    color: color(primary);
    content: icon(close-round);
    font-family: default(font-family, icon);
    font-size: 2rem;
    font-weight: 700;
  }
}

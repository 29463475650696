[data-layout-rbs-website-site-map] {
  @extend .ctn-fluid;

  nav {
    padding-bottom: 12rem;
    padding-top: 6rem;
  }

  ul.bullet {
    padding-left: 2rem;

    li {
      margin-bottom: 2rem;

      &::before {
        background-color: color(primary);
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 10px;
        margin-left: -20px;
        margin-right: 10px;
        width: 10px;
      }
    }

    ul {
      padding-top: 2rem;

      li::before {
        background-color: color(grey);
      }
    }
  }
}

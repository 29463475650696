.catalog-product-list-one-line {
  &.product-list {
    .product-list-title {
      justify-content: center;
    }
  }
}

.slider-product-one-line {
  .owl-stage-outer {
    display: flex;

    .owl-stage {
      display: flex;
      padding-left: 2rem !important;

      .owl-item {
        float: none;

        .thumbnail-inner {
          height: 100%;
        }

        .product-visual img {
          width: auto;
        }
      }

      @media (min-width: $xs-width) {
        padding-left: 3.5rem !important;
      }
      @media (min-width: $md-width) {
        padding-left: 0 !important;
      }
    }

    @media (max-width: $md-width) {
      margin-left: -3.5rem;
      margin-right: -3.5rem;
    }
    @media (max-width: $xs-width-max) {
      margin-left: -2rem;
      margin-right: -2rem;

      .owl-stage {
        /* .product-odr {
          height: 6rem;
        } */

        .product-visual {
          //min-height: calc(100% - 15.7rem);
        }
      }
    }
  }
}

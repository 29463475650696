.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;

  .pagination {
    margin: 2rem 0 5rem;

    @media (min-width: $sm-width) {
      margin: 3rem 0 5rem;
    }
  }

  .page-count {
    display: none;
  }

  > li {
    display: inline-block;
    text-align: center;

    > a,
    > span {
      background-color: transparent;
      border: 0;
      color: color(text) !important;
      display: block;
      float: none;
      font-size: 1.2rem;
      font-weight: default(weight, bold);
      line-height: 1.8;
      padding: 0 1rem;
      position: relative;

      &::after {
        border-bottom: 2px solid color(text);
        bottom: 0;
        content: "";
        left: 0.8rem;
        opacity: 0%;
        position: absolute;
        transition: all 0.2s ease;
        width: 0;
      }

      &:hover {
        background-color: transparent;
      }
    }

    > a {
      &:hover {
        background-color: transparent;
        color: color(ternary) !important;

        &::after {
          border-bottom: 2px solid color(ternary);
          opacity: 100%;
          width: calc(100% - 1.6rem);
        }
      }
    }

    &.active {
      > a,
      > span {
        color: color(ternary) !important;

        &::after {
          border-bottom: 2px solid color(ternary);
          opacity: 100%;
          width: calc(100% - 1.6rem);
        }
      }
    }
  }
}

[data-layout-rbs-brand-brand-list] {
  .pagination {
    border-bottom: 1px solid color(border, medium);
    border-top: 1px solid color(border, medium);
    column-gap: 1rem;
    justify-content: flex-start;
    margin-top: 5rem;
    overflow-x: auto;
    padding: 2rem 0;

    @media (min-width: $lg-width) {
      column-gap: 0.5rem;
      justify-content: center;
    }
    @media (min-width: $xl-width) {
      column-gap: 1rem;
    }

    li {
      a {
        font-size: 1.8rem;
        font-weight: 500;

        @media (min-width: $sm-width) {
          font-size: 2.4rem;
        }
      }

      &.disabled {
        a {
          background-color: transparent;
          color: color(grey, disabled) !important;
        }
      }
    }
  }
}

// variables mobile
$border-x-recap: 0;
$border-y-recap: 1px solid color(border, light);
$background-color-recap: transparent;
$border-radius-recap: 0;
$padding-x-recap: 2rem;
$background-color-recap-total: color(mono, light);

// variables desktop
$border-x-recap-desktop: 0;
$border-y-recap-desktop: 1px solid color(border, light);
$background-color-recap-desktop: transparent;
$padding-x-recap-desktop: 2rem;
$background-color-recap-total-desktop: color(mono, light);

.cart {
  &__recap {
    @media (min-width: $md-width) {
      margin-top: 9rem;
    }

    .h4 {
      font-size: 1.6rem;
      margin: 0;

      @media (min-width: $md-width) {
        font-size: 1.8rem;
      }
    }

    &-inner {
      overflow: hidden;
      padding: 0;
    }

    &-reassurance {
      .icon {
        font-size: 1.8rem;
      }
    }

    .cart-sidebar {
      background-color: $background-color-recap;

      .table {
        margin-bottom: 0;
      }

      .cart-message {
        .alert-info {
          color: color(alert, success);
        }
      }

      // coupons
      .panel-coupons {
        padding-left: $padding-x-recap;
        padding-right: $padding-x-recap;
        padding-top: 1.5rem;

        @media (min-width: $sm-width) {
          padding-left: $padding-x-recap-desktop;
          padding-right: $padding-x-recap-desktop;
        }

        .panel-body {
          padding: 0;
          transition: all 0.3s ease-in-out;

          .form-group {
            margin: 1.5rem 0 0;

            .input-group {
              &--mixed {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .form-control {
                  background-color: color(mono, light);
                }

                .input-group-btn {
                  flex-shrink: 0;
                  width: inherit;

                  .btn {
                    border: none;
                    border-bottom-left-radius: 0 !important;
                    border-top-left-radius: 0 !important;
                    height: 100%;
                    margin: 0;
                    padding: 0 1rem;
                  }
                }
              }
            }
          }
        }

        .coupon-form {
          max-width: 100%;
          padding: 0;
          width: 100%;

          .btn {
            margin-left: 1.5rem;
            padding: 1rem 4rem;

            .glyphicon {
              display: none;
            }
          }
        }

        .coupon-list {
          .form-control-static {
            align-items: center;
            display: flex;
            justify-content: flex-start;

            .btn {
              border: 0;
              border-radius: 0 !important;
              color: color(text);
              order: 2;
              padding: 0;

              .glyphicon {
                display: none;
              }

              &::after {
                content: "\e920";
                font-family: default(font-family, icon);
                font-size: 1rem;
                justify-self: end;
                margin-left: 0.3rem;
              }

              &:hover,
              &:focus,
              &:active {
                background-color: transparent;
                box-shadow: none;
                color: color(primary);
              }
            }

            .text-warning {
              margin-left: 1rem;
              order: 1;
            }
          }
        }
      }

      .table-amounts {
        margin-bottom: 1rem;

        tr:last-child {
          font-weight: normal;

          td:last-child {
            font-weight: normal;
          }
        }

        &-ctn {
          padding-left: $padding-x-recap;
          padding-right: $padding-x-recap;

          @media (min-width: $md-width) {
            padding-left: $padding-x-recap-desktop;
            padding-right: $padding-x-recap-desktop;
          }
        }

        [data-ng-if="cartData.amounts.displayTotalWithTaxes"] {
          td {
            vertical-align: middle;

            &:last-child {
              font-size: 2.5rem;
            }
          }
        }
      }

      .div-amounts-total {
        background-color: $background-color-recap-total;
        border-top: $border-y-recap;
        font-size: 1.8rem;
        font-weight: default(weight, medium);
        line-height: 2.1rem;
        margin-bottom: -1px;
        padding: 2rem $padding-x-recap-desktop;

        @media (min-width: $md-width) {
          border-top: $border-y-recap-desktop;
        }
      }

      .checkout-btn {
        margin: 2rem 0;

        .btn {
          text-align: center;
          width: 100%;
        }

        @media (max-width: $md-width-max) {
          background-color: rgba(255, 255, 255, 80%);
          bottom: 0;
          left: 0;
          margin: 0;
          padding: 2rem;
          position: fixed;
          text-align: center;
          width: 100%;
          z-index: 999;

          .btn {
            padding: 1rem 4rem;
            width: auto;
          }
        }
      }
    }

    &-reassurance {
      p {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}

// hide native estimate shipping
[data-rbs-commerce-shipping-fees-evaluation] {
  display: none;
}

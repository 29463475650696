// fichier icomoon à compléter qd on est en prod
.icon-arrow-left::before {
  content: icon(chevron-arrow_left);
}

.icon-arrow-right::before {
  content: icon(chevron-arrow_right);
}

.glyphicon-remove::before {
  content: icon(close);
  font-family: default(font-family, icon);
}

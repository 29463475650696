// wysiwyg
.richtext {
  line-height: 1.6;

  b {
    font-weight: default(weight, bold);
  }

  ul {
    padding: 5px 0;

    li {
      line-height: 1.5;
      list-style-position: inside;
      list-style-type: disc;
      padding: 0.2rem 0 0.2rem 2.4rem;
      position: relative;
    }
  }

  ol {
    counter-reset: olCount;
    padding: 5px 0;

    li {
      counter-increment: olCount;
      line-height: 1.5;
      list-style-position: inside;
      list-style-type: initial;
      padding: 0.2rem 0 0.2rem 2.4rem;
      position: relative;
    }
  }

  strong {
    font-weight: default(weight, bold);
  }

  em,
  i {
    font-style: italic;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  a {
    text-decoration: underline;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .table-responsive {
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  table {
    width: 100%;
  }

  th {
    background-color: color(grey, light);
    border: 1px solid color(grey);
    color: color(text);
    font-weight: default(weight, medium);
    padding: 1.5rem 2rem;
  }

  td {
    border: 1px solid color(grey);
    padding: 1rem 2rem;
    text-align: left;
  }

  blockquote {
    align-items: center;
    color: color(primary);
    display: flex;
    font-size: 2.4rem;
    font-weight: default(weight, bold);
    line-height: 1.1;
    margin: 0 auto;
    max-width: 72rem;
    min-height: 20.5rem;
    padding: 2rem 0;
    text-align: center;

    @media (min-width: $xxs-width) {
      background-size: auto 100%;
    }
    @media (min-width: $md-width) {
      font-size: 3rem;
      margin-bottom: 3rem;
      margin-top: 3rem;
    }

    p {
      line-height: 1.1;
    }
  }

  &-custom {
    &-ctn {
      &.padded {
        padding-bottom: 5rem !important;
        padding-top: 6rem !important;
      }
    }
  }
}

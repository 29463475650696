$visual-mark-icon-size: 1.4rem;
$visual-mark-ctn-width: 9rem;
$visual-mark-color-icon-full: color(quaternary);
$visual-mark-color-icon-empty: color(border, star);

.visual-marks {
  width: $visual-mark-ctn-width;
  margin: auto;

  .as-icon {
    // same height than icon
    height: $visual-mark-icon-size;
  }

  .icon {
    font-size: $visual-mark-icon-size;

    &.is-empty {
      color: $visual-mark-color-icon-empty;
      &::before {
        content: "\e90d";
      }
    }

    &.is-full {
      color: $visual-mark-color-icon-full;
    }
  }
}

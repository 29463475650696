/* grid & space */

hr {
  border-top: 1px solid color(primary);
}

.no-underline {
  text-decoration: none !important;
}

.cursor {
  cursor: pointer;
}

.border {
  border: 1px solid color(border);
}

.valign-middle {
  > * {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}

.md-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 107rem;
}

.sm-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 65rem;
}

.xs-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 45rem;
}

.unvisible {
  display: none;
}

/* bg color */
.bg-white {
  background-color: color(mono, light);
}

.bg-black {
  background-color: color(mono, dark);
  color: color(mono, light);
}

.bg-grey {
  background-color: color(background);
}

.bg-ternary {
  background-color: color(ternary);
}

.bg-ternary-light {
  background-color: rgba(color(ternary), 0.1);
}

.color-secondary {
  color: color(secondary);
}

.color-ternary {
  color: color(ternary);
}

/* links */
.link {
  &:hover {
    text-decoration: underline;
  }
}

.link2 {
  position: relative;

  &::after {
    background-color: color(text);
    bottom: -0.5rem;
    content: "";
    height: 0.05rem;
    left: 0;
    position: absolute;
    transition: all 0.3s linear;
    width: 100%;
  }

  &:hover {
    &::after {
      background-color: color(primary);
    }
  }
}

// autres helper
.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-flex {
  display: inline-flex;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.underline {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.barre {
  text-decoration: line-through;
}

.upper {
  text-transform: uppercase;
}

.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.nw {
  white-space: nowrap;
}

.cover {
  background-size: cover;
}

.no-repeat {
  background-repeat: no-repeat;
}

.img-responsive {
  max-width: 100%;
}

.img-responsive-ib {
  max-width: 100%;
}

.fix-ht-img {
  font-size: 0;
  line-height: 0;
}

.regular {
  font-weight: default(weight);
}

.strong {
  font-weight: default(weight, bold);
}

.strong-medium {
  font-weight: default(weight, medium);
}

.no-margin {
  margin: 0 !important;
}

.help-block {
  color: color(text);
  font-size: 1.4rem;
  line-height: 1.6;
  font-weight: default(weight);

  ul {
    list-style: disc;
    list-style-position: inside;
  }
}

.break {
  word-break: break-all;
}

.tag {
  background-color: color(mono, light);
  border: 1px solid color(tertiary);
  border-radius: default(radius, big) !important;
  color: color(tertiary);
  display: inline-block;
  font-size: 1.2rem;
  font-weight: default(weight, medium);
  line-height: 1.4rem;
  margin-bottom: 0.7rem;
  padding: 0.3rem 1rem;
}

.en-dur {
  border-color: red !important;
  color: red !important;
}

.white-box-padded {
  background-color: color(mono, light);
  border-radius: default(radius, medium);
  box-shadow: default(shadow, light);
  margin-bottom: 2rem;
  padding: 2rem;

  @media (min-width: $md-width) {
    padding: 2rem 3rem;
  }
}

.switch {
  font-size: 0;
  top: 0;

  &::before {
    background-color: color(grey, dark);
    border-radius: 3rem;
    content: "";
    height: 1.8rem;
    transition: all 0.3s ease;
    width: 3rem;
  }

  &::after {
    background-color: color(mono, light);
    border-radius: 1.4rem;
    content: "";
    height: 1.4rem;
    left: 0.2rem;
    position: absolute;
    top: 0.2rem;
    transition: all 0.3s ease;
    width: 1.4rem;
    z-index: 1;
  }

  input {
    height: 1.8rem;
    width: 3rem;
  }

  &.checked {
    &::before {
      background-color: color(button, green);
    }

    &::after {
      left: calc(100% - 1.6rem);
    }
  }

  &.disabled {
    &::before {
      background-color: color(grey, medium);
    }
  }
}

.tooltip-ctn {
  display: inline-block;
  padding: 0.5rem;
  position: relative;

  &.tooltip-guarantee-included {
    p:first-child {
      text-transform: uppercase;
    }

    hr {
      display: none;

      + span {
        font-size: inherit;
      }
    }
  }

  &:hover {
    .tooltip-helper {
      height: inherit;
      opacity: 100%;
      overflow: visible;
      transition: opacity 0.25s;
      width: 200px;
      z-index: 5;

      &__inner {
        max-height: 300px;
        overflow: auto;
        position: relative;
        z-index: 1;
      }
    }
  }

  .tooltip-helper {
    bottom: 100%;
    height: 0;
    left: 50%;
    opacity: 0%;
    overflow: hidden;
    position: absolute;
    transform: translateX(-50%);
    width: 0;

    &__inner {
      background-color: color(background);
      border-radius: default(radius, medium);
      box-shadow: default(shadow, light);
      color: color(mono, light);
      display: block;
      font-size: 1.2rem !important;
      font-weight: default(weight) !important;
      padding: 1rem;
      text-align: left;
      white-space: initial;
    }

    &::after {
      background-color: color(background);
      bottom: 0;
      content: "";
      height: 1rem;
      left: 50%;
      position: absolute;
      transform: rotateZ(-45deg) translateX(-50%);
      width: 1rem;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.target-link {
  position: static;

  &:hover {
    background-color: inherit;
    color: inherit;
  }

  &.btn-ternary:hover {
  }

  &-ctn {
    position: relative;
  }

  &::before {
    content: "";

    @include poa(0, 0, 0, 0);
  }
}

.radius {
  border-radius: default(radius);

  &-medium {
    border-radius: default(radius, medium);
  }
}

.shadow {
  box-shadow: default(shadow);

  &-light {
    box-shadow: default(shadow, light);
  }
}

/* overflow mobile */
.overflow-mobile {
  @media (max-width: $sm-width-max) {
    overflow: auto;

    > div,
    > ul {
      display: flex !important;
      flex-wrap: nowrap;
    }

    &-item-one-half {
      flex-basis: 80%;
      height: auto;
      min-width: 80%;
    }

    &-item-two-half {
      flex-basis: 47%;
      min-width: 47%;
    }

    &-item-three-half {
      flex-basis: 30%;
      min-width: 30%;
    }
  }
}

/* overflow tablet */
.overflow-tablet {
  @media (min-width: $sm-width) and (max-width: $md-width) {
    overflow: auto;

    > div,
    > ul {
      display: flex;
      flex-wrap: nowrap;
    }

    &-item-two-half {
      flex-basis: 47%;
      min-width: 47%;
    }

    &-item-three-half {
      flex-basis: 30%;
      min-width: 30%;
    }
  }
}

// when stage padding
.reverse-gutter-mobile {
  @media (max-width: $sm-width-max) {
    margin-left: -(break(xxs, gutter-ctn));
    margin-right: -(break(xxs, gutter-ctn));
  }
}

// line-clamp utils
.line-clamp {
  &-base {
    -webkit-box-orient: vertical;
    display: block;

    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;

    // -webkit-line-clamp: $numLines;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
  }

  @for $i from 1 through 12 {
    &-#{$i} {
      -webkit-line-clamp: $i;
    }
  }
}

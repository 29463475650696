.header {
  background: color(mono, light);
  position: relative;
  z-index: 110;

  &-banner {
    align-items: center;
    background-color: color(primary);
    color: color(text, light);
    display: flex;
    height: height(banner, xs);
    overflow: auto;
    padding: 0 4rem;
    text-align: center;

    @media (min-width: $xs-width) {
      height: height(banner, xs);
    }
    @media (min-width: $md-width) {
      height: height(banner, md);
    }

    .block {
      width: 100%;
    }

    .richtext {
      font-size: 1.2rem;
      white-space: nowrap;

      @media (min-width: $md-width) {
        font-size: 1.4rem;
      }
    }
  }

  &-main {
    padding-top: 2rem;

    @media (min-width: $md-width) {
      padding-bottom: 3rem;
    }

    .row {
      align-items: center;
    }
  }

  &-secondary-nav {
    li {
      padding-right: 1rem;

      @media (min-width: $lg-width) {
        padding-right: 1.5rem;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &-logo {
    margin-bottom: 2rem;

    @media (min-width: $md-width) {
      flex-basis: auto;
      flex-grow: 0;
      margin-bottom: 0;
    }

    img {
      height: 3rem;

      @media (min-width: $sm-width) {
        height: 6rem;
      }
    }
  }

  &-store {
    margin-bottom: 2rem;

    @media (min-width: $md-width) {
      margin-bottom: 0;
    }

    [data-name="Rbs_Storeshipping_ShortStore"] {
      display: inline-block;
    }
  }

  &-action {
    .navbar-action {
      color: color(primary);

      .icon {
        font-size: 2.4rem;
      }
    }
  }

  .header-top-right {
    .flex {
      align-items: center;
      flex-wrap: nowrap;
      font-weight: default(weight, medium);
      justify-content: flex-end;

      > div {
        @media (min-width: $xs-width) {
          margin: 0 0.4rem 0 0;
        }
        @media (min-width: $md-width) {
          margin: 0 2rem 0 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  [data-name="Rbs_User_AccountShort"],
  [data-name="Rbs_Commerce_ShortCart"] {
    a {
      align-items: center;
      display: flex;
      padding: 0 0 1rem 1rem;

      @media (min-width: $md-width) {
        padding: 1rem 0;
      }
    }

    .dropdown {
      &-header {
        color: inherit;
        font-size: inherit;
      }
    }
  }

  [data-name="Rbs_Storeshipping_ShortStore"] {
    .short-store {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      &__logo {
        height: 4rem;
        width: 7.7rem;

        a {
          align-items: flex-end;
          display: flex;
          height: 100%;
          justify-content: flex-end;
        }

        img {
          max-height: 100%;
          max-width: 100%;
          width: auto;
        }

        @media (min-width: $md-width) {
          height: 6.4rem;
          width: 14.8rem;

          img {
            height: 100%;
          }
        }
      }

      &__info {
        margin-left: 1rem;
        margin-top: -0.4rem;
        text-align: left;

        @media (min-width: $md-width) {
          margin-top: 0;
        }

        &__title {
          font-size: 1.2rem;
          font-weight: 500;

          @media (min-width: $md-width) {
            font-size: 1.4rem;
          }
        }

        &__sign {
          font-size: 1rem;
          font-weight: 400;
        }

        &__edit {
          color: color(grey, dark);
          display: block;
          font-size: 1rem;
          font-weight: 400;
          text-decoration: underline;

          @media (min-width: $md-width) {
            font-size: 1.2rem;
          }
        }
      }
    }

    .title,
    .header-label {
      display: inline-block !important;
      font-size: 1.4rem;
      font-weight: default(weight);
      margin-left: 1rem;
      text-decoration: underline;
    }

    .glyphicon::before {
      content: icon(map-marker);
    }
  }

  [data-name="Rbs_User_AccountShort"] {
    .glyphicon::before {
      content: icon(user);
    }
  }

  [data-name="Rbs_Commerce_ShortCart"] {
    .glyphicon-shopping-cart::before {
      content: icon(shopping_cart_plein);
    }

    .empty-cart {
      .glyphicon-shopping-cart::before {
        content: icon(shopping_cart_vide);
      }

      .badge {
        display: none;
      }
    }
  }

  .glyphicon,
  i {
    color: color(primary);
    display: inline-block;
    font-family: default(font-family, icon);
    font-size: 1.9rem;
  }

  .dropdown-menu {
    border-radius: default(radius, medium);
    padding: 2rem;

    h3,
    .media {
      color: color(text);
    }

    .media {
      padding: 1rem;
    }

    li {
      padding: 0;

      a {
        padding: 1rem;

        &:hover,
        &:focus,
        &:active {
          background-color: color(grey, light);
        }
      }
    }

    .active > a {
      color: color(text);
    }
  }

  .badge,
  .header-pin {
    background: transparent;
    color: color(primary);
    display: inline-block;
    font-size: 1.2rem;
    font-weight: default(weight, medium);
    left: 18px;
    line-height: 1;
    min-width: none;
    padding: 0;
    position: absolute;
    top: 2px;

    @media (min-width: $md-width) {
      left: 8px;
      top: 12px;
    }

    &.visible-xs-inline-block {
      display: none !important;
    }
  }

  .caret {
    display: none;
  }

  .dropdown-toggle,
  a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none !important;
    }
  }

  .title,
  .header-label {
    display: none;
  }
}

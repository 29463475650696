// block recao product
.block-recap-products {
  margin-top: 4rem;

  .table,
  tr,
  tbody,
  td {
    display: block;
    margin: 0;
  }

  thead {
    display: none;
  }

  tbody {
    display: flex;
    flex-wrap: wrap;
  }

  .line-visual {
    border: none;
    padding: 0;
    text-align: center;
  }

  .line-details {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 1.2rem;
    font-weight: default(weight, medium);
  }

  tr {
    background-color: transparent !important;
    margin-right: 1.5rem;
    max-width: calc(50% - 1.5rem);
    width: 15rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .line-quantity,
  .line-prices {
    display: none;
  }

  .product-brand {
    display: none;
  }

  [data-ng-show="line.options.pickUpDateTime"] {
    font-size: 1.2rem;
  }

  + .process-button-edit-cart {
    display: inline-block;
    margin-bottom: 3rem;
  }

  .gift-summary {
    display: none;
  }
}

.brand-header {
  padding: 5rem 0 0;
  width: 100%;

  @media (min-width: $md-width) {
    width: 60%;
  }

  &__title {
    margin-bottom: 0;
  }

  &__description {
    margin-top: 3.8rem;
  }

  &__link {
    display: block;
    margin-top: 2rem;
    text-decoration: underline;
  }
}

.highlight-mention {
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: center;

  @media (min-width: $md-width) {
    margin-top: 2rem;
  }
}

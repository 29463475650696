.process-identification {
  padding-top: 100px;

  @media (min-width: $sm-width) {
    padding-top: 130px;
  }

  &-container {
    @media (min-width: $md-width) {
      margin: auto;
      max-width: 718px;
      width: 100%;

      .tabs {
        margin-top: 3rem;
      }

      .tab-btn {
        br {
          display: none;
        }
      }
    }

    &.logged {
      @media (min-width: $md-width) {
        max-width: $container-order-process;
      }
    }

    .identification-ctn {
      border-radius: 2rem;
      margin: 0;
      padding: 0;
    }

    .identification-resume {
      margin-bottom: 2rem;
      padding: 2rem;
      width: 100%;

      @media (min-width: $md-width) {
        margin-left: 0;
        padding: 3rem;
        width: 100%;
      }
    }

    .process-shipping-title {
      font-size: 3rem !important;
      margin-bottom: 0;
    }
  }

  &-connected-user {
    display: block;
    margin-top: 2rem;
    padding: 2rem;

    @media (min-width: $md-width) {
      padding: 3rem;
    }
  }

  // box to change identification or confirm
  &-confirm,
  &-edit,
  &-change {
    &.panel {
      @media (min-width: $sm-width) {
        padding: 0 5%;
      }
    }

    .panel-heading {
      border-bottom: none;
      color: color(text);
      text-align: center;
    }
  }

  &-summary {
    padding: 0 2rem 2rem;

    @media (min-width: $md-width) {
      padding: 0 3rem 3rem;
    }

    [data-rbs-geo-phone-formatter] {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      text-align: center;

      + .form-inline {
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        text-align: center;

        i {
          display: block;
          font-style: italic;
          margin-top: 0.5rem;
        }

        .btn-primary {
          @extend .btn-secondary;

          @media (max-width: $xs-width) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
      }
    }

    [data-ng-if="!hideStepAction"] {
      margin: auto;
      text-align: center;
    }

    .process-button-edit-step {
      @extend .btn-ternary;
      @extend .btn-small;
      margin-top: 1rem;
    }
  }

  @media (max-width: $sm-width-max) {
    &-summary {
      .form-control {
        width: 100% !important;
      }
    }
  }
  @media (min-width: $sm-width) {
    .panel {
      &.process-identification-confirm,
      &.process-identification-change {
        margin: 0;
      }

      &.process-identification-edit {
        margin-bottom: 4rem;
      }
    }

    &-summary {
      .form-ctrl-ctn > * {
        margin-right: 1.5rem;

        + .btn {
          margin-bottom: 0;
          margin-right: 1.5rem;
        }
      }
    }
  }

  &-change {
    .panel-body {
      .btn {
        margin-top: 2rem;
      }
    }
  }

  .col-sm-6 {
    width: 100%;

    @media (min-width: $sm-width) {
      width: 50%;
    }
  }
}

[data-rbs-user-forgot-password] {
  margin-bottom: 2rem;
  text-decoration: underline;
}

.social-login__ctn {
  display: flex;
  flex-direction: column;

  .panel-heading {
    @extend .h4;
    border-color: color(border, medium);
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 3rem;
    margin-top: 5rem;
  }

  .panel-body {
    padding-bottom: 0;
  }

  .list-inline {
    @extend .flex;

    li {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .btn-login-social {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-top: 0;

    &-facebook {
      background-color: #3b5998;
      border-color: #3b5998;
    }

    &-google {
      background-color: #db4a39;
      border-color: #db4a39;
    }

    img {
      margin-right: 1.5rem;
    }
  }
}

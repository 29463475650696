.block-reinsurance {
  background-color: color(mono, light);
  overflow-x: auto;
  position: relative;
  z-index: 100;

  &.previous-overlay {
    background-color: rgba(color(mono, light), 0.7);
    margin-top: -6.7rem;
  }

  .ctn-fluid {
    padding-right: 0;
  }

  &-inner {
    display: block;
    overflow-y: hidden;
    padding: 1rem;

    @media (min-width: $sm-width) {
      align-items: center;
      display: flex;
      height: 6.7rem;
      justify-content: center;
    }

    &.inline {
      align-items: center;
      display: flex;
      height: 6.7rem;
      justify-content: flex-start;
      padding: 0;

      @media (min-width: $sm-width) {
        justify-content: center;
      }
    }
  }

  &-items-list {
    display: block;

    @media (min-width: $sm-width) {
      display: flex;
      flex: 0 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &.inline {
      display: flex;
      flex: 0 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &-item {
    margin: 2rem 0;
    padding: 0 2.5rem;
    position: relative;
    text-align: left;

    @media (min-width: $lg-width) {
      padding: 0 4.5rem;
    }

    &::after {
      background-color: color(grey);
      bottom: 0;
      content: "";
      display: block;
      display: none;
      height: 1px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 4rem;

      @media (min-width: $lg-width) {
        bottom: auto;
        height: 60%;
        left: auto;
        right: -1.5rem;
        top: 20%;
        transform: none;
        width: 1px;
      }
    }
  }

  &-title {
    color: color(text);
    display: inline;
    font-size: 1.6rem;
    font-weight: default(weight, medium);
    line-height: 1.4;
    vertical-align: middle;
  }

  &-img {
    display: none;
    max-width: 7rem;
    width: 5rem;
  }

  &.footer-reinsurance {
    background-color: color(primary);

    .block-reinsurance {
      &-title {
        color: white !important;
      }

      &-img {
        display: inline;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }
  }
}

[data-layout-rbs-website-richtext] {
  .header-image {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    padding: 0;

    .ctn-fluid {
      width: 100%;
    }

    &-title {
      margin-bottom: 0;
      margin-top: 10rem;
      text-align: center;

      @media (min-width: $sm-width) {
        margin-top: 29.6rem;
      }
    }

    &-content {
      margin-top: 3rem;
      text-align: center;
    }

    &.has-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: default(radius, medium);
      margin-top: 2rem;
      min-height: 20rem;

      @media (min-width: $md-width) {
        margin-top: 5rem;
        min-height: 50rem;
      }

      .ctn-fluid {
        width: 100%;
      }

      .header-image-title,
      .header-image-content {
        color: color(mono, light);
      }
    }
  }

  &.main-title {
    h1 {
      @extend .h2;
      margin-bottom: 0;
    }

    .header-image {
      padding: 5rem 0 0;

      @media (min-width: $md-width) {
        padding: 10rem 0 0;
      }

      &-content {
        margin-top: 3rem;

        @media (min-width: $md-width) {
          margin-top: 5rem;
        }
      }
    }
  }

  &.main-title-brand {
    h1 {
      @extend .h2;
      margin-bottom: 0;
    }

    .header-image {
      padding: 5rem 0 0;

      @media (min-width: $md-width) {
        padding: 10rem 0 0;
      }

      &-title {
        margin-bottom: 0 !important;
      }

      &-content {
        display: none;
      }
    }
  }
}

.image-title {
  @media (min-width: $md-width) {
    margin-bottom: 4.5rem;
  }

  .h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.block-text-slider {
  margin-top: 7rem;

  .row {
    align-items: flex-end;
  }

  &-content,
  &__button {
    margin-top: 5rem;
    padding-left: 5rem;
    padding-top: 5rem;
    position: relative;

    @media (min-width: $sm-width) {
      padding-left: 8.6rem;
      padding-top: 8rem;
    }
    @media (min-width: $md-width) {
      margin-top: 0;
    }
    @media (min-width: $lg-width) {
      padding-left: 0;
    }

    .block-text-slider-title {
      line-height: 1;
    }
  }

  &__button {
    margin-top: 0;
    padding-top: 0;
  }

  &-img {
    left: 0;
    position: absolute;
    top: 0;
    width: 5rem;

    @media (min-width: $sm-width) {
      width: auto;
    }
    @media (min-width: $lg-width) {
      left: -8.6rem;
    }
  }

  &-owl-ctn {
    margin-top: 0;

    @media (min-width: $md-width) {
      margin-top: 2rem;
    }

    .owl-stage-outer {
      border-radius: 2rem;
      overflow: hidden;
    }
  }

  &-video {
    margin-top: 0;

    @media (min-width: $md-width) {
      margin-top: 2rem;
    }

    iframe {
      min-height: 20rem;
      width: 100%;

      @media (min-width: $sm-width) {
        min-height: 38rem;
      }
    }
  }
}

.bloc-title-image-text {
  .title,
  .image {
    display: block;
    margin-bottom: 5rem !important;

    img {
      width: 100%;
    }
  }
}

.block-mea {
  margin-top: 5rem;

  &-items {
    @media (min-width: $sm-width) {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(2, auto);
    }
    @media (min-width: $md-width) {
      grid-template-columns: repeat(3, auto);
    }
    @media (max-width: $sm-width-max) {
      overflow-x: auto;
      white-space: nowrap;
      width: 106%;
    }
  }

  &-item {
    border-radius: default(radius, medium);
    overflow: hidden;
    position: relative;

    @media (max-width: $sm-width-max) {
      display: inline-block;
      height: 65vw;
      margin-right: 2rem;
      width: 65vw;
    }

    &-ctn {
      height: 100%;

      img {
        width: 100%;

        @media (max-width: $sm-width-max) {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-content {
      align-items: center;
      background: rgba(0, 0, 0, 30%);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      left: 0;
      padding: 2rem;
      position: absolute;
      top: 0;
      width: 100%;

      @media (min-width: $sm-width) {
        justify-content: center;
      }

      &-text {
        font-size: 1.8rem;
        line-height: 1.5;
        margin-top: 4rem;
        white-space: break-spaces;

        @media (min-width: $sm-width) {
          margin-top: 0;
        }
      }

      .btn {
        border-color: color(border, light);
        color: color(text, light);
        margin-top: 2.5rem;

        @media (min-width: $sm-width) {
          margin-top: 1rem;
        }

        &:hover {
          background-color: color(background, light);
          color: color(text);
        }
      }
    }
  }
}

.block-mea-double {
  .block-mea-item {
    @media (max-width: $sm-width) {
      display: inline-block;
      height: auto;
      margin-right: 0;
      width: 100%;
    }

    &-title {
      color: color(mono, light);
      font-size: 2.4rem;
      font-weight: default(weight, bold);
      line-height: 1.2;

      @media (min-width: $sm-width) {
        font-size: 3.5rem;
      }
      @media (min-width: $lg-width) {
        font-size: 5rem;
      }
    }

    &-content {
      justify-content: center;
      padding: 2rem;

      &-text {
        font-size: 1.4rem;
        font-weight: default(weight);
        line-height: 1.2;
        margin-top: 1rem;

        @media (min-width: $sm-width) {
          font-size: 1.8rem;
        }
        @media (min-width: $lg-width) {
          font-size: 2.4rem;
          font-weight: default(weight, medium);
        }
      }

      .btn {
        @media (min-width: $sm-width) {
          margin-top: 3.5rem;
        }
      }
    }

    &-last-child {
      margin-top: 2rem;

      @media (min-width: $sm-width) {
        margin-top: 0;
      }
    }
  }

  &-small {
    .sm-ctn {
      width: 750px;
    }

    .block-mea-item {
      &-title {
        font-size: 2.4rem;
      }

      &-content-text {
        font-size: 1.4rem;
      }
    }
  }
}

/* .block-mea-double {
    margin: 0 auto 2rem auto;
    padding: 0 3.5rem;

    &-cards {
        border-bottom: 2px solid color(border, medium);
        padding-bottom: 3rem;
        @media (min-width: $sm-width) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &-card {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;

        @media (min-width: $sm-width) {
            width: 48%;
            margin-bottom: 0;
        }

        &-image {
            width: 100%;

            img {
                width: 100%;
            }
        }

        &-content {
            @include poa(0, 0);
            margin: 0;

            h3 {
                margin-bottom: 0.5rem;
            }

            .btn {
                margin-top: 3rem;
            }
        }
    }
} */

.contact-block {
  margin: 5rem auto 7rem;

  &-links {
    margin-top: 3rem;

    @media (min-width: $sm-width) {
      align-items: flex-start;
      display: flex;
      justify-content: space-around;

      > div {
        width: 100%;
      }
    }

    a {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;

      @media (min-width: $sm-width) {
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .icon {
      color: color(primary);
      font-size: 3rem;
      margin-right: 1rem;

      @media (min-width: $sm-width) {
        font-size: 2rem;
      }
    }
  }
}

.slider-two-products-with-highlight {
  margin: 5rem auto 2rem;
  padding: 1.5rem !important;

  .owl-carousel .owl-item img {
    width: auto;
  }

  @media (min-width: $md-width) {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 4.7rem 3.5rem 2rem !important;
  }

  .visual {
    @media (min-width: $md-width) {
      width: 50%;
    }

    img {
      border-radius: 1rem;
      width: 100%;
    }

    .corner {
      margin: -35% auto 0;
      width: 50%;

      @media (min-width: $md-width) {
        margin: -30% auto 0;
      }

      img {
        border-radius: 0;
        width: 100%;
      }
    }
  }

  .content {
    margin-top: -3rem;

    @media (min-width: $md-width) {
      margin-top: 0;
      width: 47%;
    }

    &__title {
      line-height: 4rem;
      margin-bottom: 2.5rem;

      @media (min-width: $sm-width) {
        line-height: 5.5rem;
        margin-bottom: 1rem;
      }
    }

    &__description {
      font-size: 1.2rem;
      line-height: 2rem;
      margin-bottom: 2rem;

      @media (min-width: $sm-width) {
        font-size: 1.8rem;
        line-height: 2.1rem;
      }
    }

    &__btn {
      margin-bottom: 2rem;
      width: 26rem;
    }

    &__products-list {
      @media (min-width: $md-width) {
        margin-left: -22%;
        width: 117%;
      }

      .owl-stage-outer {
        .owl-stage {
          display: flex;

          .owl-item {
            float: none;

            img {
              width: auto;
            }

            .thumbnail-inner {
              height: 100%;
            }
          }
        }

        @media (max-width: $xs-width-max) {
          margin-right: -3.5rem;

          .product-odr {
            //height: 5.5rem;
            margin-top: 0.5rem;
          }

          .product-visual {
            min-height: calc(100% - 15.7rem);
          }
        }
      }

      .owl-nav {
        display: none;
        margin-top: 0;

        @media (min-width: $sm-width) {
          display: block;
        }

        .owl-next {
          right: -3.5rem;
        }

        .owl-prev {
          left: -3.5rem;
        }
      }
    }
  }
}

.faqs-title {
  margin: 5rem auto;

  @media (min-width: $sm-width) {
    margin: 10rem auto 5rem;
  }

  div {
    padding: 0 !important;
  }
}

.faq {
  background-color: color(mono, light);
  box-shadow: default(shadow, light);
  margin: auto;
  padding: 1rem 1.5rem;

  @media (min-width: $sm-width) {
    padding: 1rem 3rem;
  }

  &.open {
    .faq__question {
      border-radius: 5px 5px 0 0;

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__question {
    background-color: color(background, light);
    border: 1px solid color(border, medium);
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: default(weight, medium);
    line-height: 1.8rem;
    margin-bottom: 0;
    padding: 1.5rem;
    position: relative;
    transition: border-radius 0.3s ease;

    &::after {
      content: icon(circle-arrow-down);
      font-family: default(font-family, icon);
      font-size: 2rem;
      position: absolute;
      right: 1.5rem;
    }
  }

  &__answer {
    border: 1px solid color(border, medium);
    border-radius: 0 0 5px 5px;
    border-top: none;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1.5rem;
  }
}

.grid-category-menu {
  &__title {
    text-align: center;
  }

  &__navbar {
    display: grid;
    gap: 0 2rem;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;

    @media (min-width: $sm-width) {
      grid-template-columns: repeat(6, 1fr);
    }
    @media (min-width: $lg-width) {
      gap: 0 4rem;
      grid-template-columns: repeat(8, 1fr);
    }

    // grid-auto-rows: minmax(100px, auto);
  }

  &__item {
    text-align: center;

    &__image {
      border-radius: default(radius, menu);
      box-shadow: default(shadow);
      display: flex;
      overflow: hidden;
      width: 100%;

      &__img {
        width: 100%;
      }
    }

    &__label {
      font-size: 1.4rem;
      margin-top: 1rem;
    }
  }
}

.return-link {
  margin: 6rem 0;

  a {
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: underline;
  }
}

.store-news {
  &__title {
    margin-bottom: 3rem !important;
  }

  &__content {
    @media (min-width: $sm-width) {
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__new {
    @media (min-width: $sm-width) {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    &__image {
      margin-bottom: 2rem;
      width: 100%;

      @media (min-width: $sm-width) {
        margin-bottom: 0;
      }

      img {
        width: 100%;
      }
    }

    &__content {
      width: 100%;

      @media (min-width: $sm-width) {
        padding-left: 2rem;
      }
    }
  }

  &__catalog {
    align-items: center;
    background-color: color(secondary) !important;
    box-shadow: none;
    color: color(mono, light);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;

    &__icon {
      .icon {
        font-size: 7.4rem;
      }
    }

    &__title {
      color: color(mono, light) !important;
      font-family: default(font-family, secondary) !important;
      margin: 3rem 0 !important;

      @media (min-width: $sm-width) {
        margin: 1.5rem 0 !important;
      }
    }

    &__btn {
      margin-top: 3rem;
      width: 22rem;

      @media (min-width: $sm-width) {
        margin-top: 1.5rem;
      }
    }
  }
}

.generic-vertical-menu {
  padding-top: 5rem !important;

  .bullet {
    li {
      a {
        align-items: center;
        background-color: color(mono, light);
        border-radius: 1rem;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 5%);
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding: 1.8rem 2rem;

        &::after {
          content: icon(arrow-right);
          display: block;
          font-family: default(font-family, icon);
          font-size: 1.2rem;
          margin-left: 1rem;
        }

        &.active,
        &:hover,
        &:active,
        &:focus {
          background-color: color(secondary);
          color: color(mono, light);

          &::after {
            color: color(mono, light);
          }
        }
      }
    }
  }
}

.block-brand {
  margin-top: 1rem;

  @media (min-width: $md-width) {
    margin-top: 3rem;
  }

  &-items-list {
    @extend .row;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  &-item {
    @extend .xxs6;
    margin-top: 2rem;

    @media (min-width: $sm-width) {
      @extend .sm4;
    }
    @media (min-width: $md-width) {
      @extend .md3;
    }
    @media (min-width: $xl-width) {
      @extend .xl2;
    }

    &-inner {
      background-color: color(mono, light);
      border-radius: default(radius, button);
      font-size: 0;
      line-height: 0;
      padding: 1.5rem 2.7rem;
      text-align: center;

      @media (min-width: $sm-width) {
        padding: 2.5rem 3rem;
      }

      img {
        margin: 0 auto;
        max-width: 100%;
        width: auto;
      }
    }
  }
}

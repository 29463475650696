// helper
.order-process-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-order-process;
}

// header order
body.order {
  .header-main {
    background-color: color(mono, light);
    left: 0;
    padding-top: 0;
    position: fixed;
    right: 0;
    z-index: 5;

    @media (min-width: $md-width) {
      padding: 2rem 0;
    }
  }

  .logo-ctn {
    text-align: left;

    img {
      height: 4rem;
    }

    [data-ng-if="!hideStepAction"] {
      margin: auto;
      text-align: center;
    }

    .process-button-edit-step {
      @extend .btn-ternary;
      @extend .btn-small;
      margin-top: 1rem;
    }
  }

  [data-rbs-commerce-short-cart] {
    .title,
    .caret {
      display: none;
    }
  }

  @media (min-width: $md-width) {
    text-align: center;
  }

  .header-main .header-top-right {
    position: static;
  }

  .shipping-content, .guarantees-content {
    border-radius: 2rem;
    overflow: visible;
    padding: 0 2rem;

    @media (min-width: $md-width) {
      padding: 0 3rem;
    }

    .shipping-footer, .guarantees-footer {
      padding-bottom: 2rem;
    }
  }

  .heading-shipping , .heading-guarantees {
    @extend .h4;
    align-items: center;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;

    @media (min-width: $md-width) {
      font-size: 1.8rem;
    }

    &::before {
      align-items: center;
      background-color: color(secondary);
      border-radius: default(radius, medium);
      color: color(mono, light);
      content: icon(store);
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      font-family: icomoon;
      font-size: 2rem;
      height: 4rem;
      justify-content: center;
      margin-right: 1rem;
      text-align: center;
      width: 4rem;

      @media (min-width: $md-width) {
        font-size: 3rem;
        height: 5rem;
        width: 5rem;
      }
    }

    &::after {
      margin-left: auto;
    }

    &.athome {
      &::before {
        content: icon(reassurance_carrier);
      }
    }
  }

  .heading-guarantees {
    &::before {
      content: icon(rdv_conseil);
    }

    &.athome {
      &::before {
        content: icon(rdv_conseil);
      }
    }
  }

  .guarantees-content {

    .panel {
      border-bottom: none !important;
    }

    .products-list {
      &__item {
        padding: 1.5rem 0;
        border-bottom: 1px solid color(border, medium);

        @media (min-width: $sm-width) {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 3rem 0;
          
        }

        &:first-child {
          padding-top: 1rem;
        }
        &__content {
          min-width: 10rem;
          max-width: 10rem;
          margin-right: 5rem;

          img {
            max-width: 100%;
          }

          &__info {
            // border: 1px solid orange;
            text-align: left;
            font-size: 1.2rem;
            line-height: 1.4rem;

            .line-detail {
              &__brand, &__title  {
                font-weight: default(weight, medium);
              }

              &__reference {
                color: color(text,dark);
              }
            }
          }

        }

        &__guarantees {
          width: 100%;
          margin-top: 1rem;
          position: relative;
          min-height: 7rem;

          @media (min-width: $sm-width) {
            margin-top: 0;
          }

          .product-warranty {
            padding: 1rem 1.5rem;
            margin-bottom: 1rem;

            &__line {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
            }

            &__description {
              margin: 0 1rem 0 0;

              label {
                margin: 0;
                font-weight: default(weight, medium);
                text-align: left;
              }

              .custom-radio {
                top: 0.2rem;
              }
            }

            &__price {
              .tooltip-ctn {
                padding: 0 0.5rem;
              }
            }

            &__loader {
              width: 7rem;
              margin: 2rem auto 0;
              position: absolute;
              top: calc(50% - 35px);
              left: calc(50% - 35px);
              img {
                width: 100%;
              }
            }
          }


        }
      }
    }
  }
}

// order-process layout
[data-layout-rbs-commerce-order-process] hr {
  border-top: 0;
  margin: 0;

  @media (min-width: $sm-width) {
    + [class*="process-"] {
      @media (min-width: $md-width) {
        margin-left: auto;
        margin-right: auto;
        max-width: $container-order-process;
      }
    }
  }
}

// order process big titles
.process-order-title {
  font-size: 2.4rem;
  font-weight: default(weight);
  text-align: left;

  &.accordion-btn {
    border-color: color(border, medium);

    &.active {
      border: none;
    }

    &::after {
      color: color(mono, dark);
      content: icon(plus);
      font-family: icomoon;
      font-size: 1.5rem;
    }

    &.active::after {
      content: icon(minus);
    }
  }
}

// panel override
#shipping,
#payment {
  .process-shipping-delivery {
    border: 0;
    padding: 2rem 0;

    @media (min-width: $md-width) {
      padding: 3rem 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid color(border, medium);
    }

    .tab-content {
      border: 1px solid color(border, medium);
      margin-bottom: 2rem;

      @media (min-width: $md-width) {
        margin-bottom: 3rem;
      }
    }
  }

  .panel-body {
    padding: 0;

    h3 {
      border: 0;
      cursor: pointer;
      font-weight: default(weight, medium);
      margin: 0;
      padding: 20px 15px;

      &.checked {
        + div {
          display: block;
        }
      }

      .custom-radio {
        position: relative;

        label {
          font-weight: default(weight, medium);
          padding-left: 2rem;
        }
      }

      + div,
      + address {
        font-weight: default(weight);
        padding: 0 1.5rem;

        > div {
          border-top: 1px solid color(border);
          padding-top: 1.5rem;
        }

        @media (min-width: $sm-width) {
          padding: 0 3rem 3rem 6rem;
        }
      }
    }
  }

  .blockshipping {
    .panel-body {
      // border: 1px solid color(border);
    }
  }

  // .process-button-next-step {
  //   float: none!important;
  //   margin: 0 auto 7rem;
  //   max-width: width(ctn, sm);
  //   width: 100%;
  //   white-space: normal;

  //   @media (min-width: $sm-width) {
  //     margin: 0 auto 15rem;
  //   }
  // }
}

[data-rbs-commerce-process] {
  @extend .ctn;
  padding-bottom: 8rem;

  .process-button-next-step {
    float: none !important;
    margin: 2rem auto;
    max-width: width(ctn, sm);
    white-space: normal;
    width: 100%;

    @media (min-width: $sm-width) {
      margin: 0 auto 15rem;
    }
  }

  address {
    line-height: 1.4;

    strong {
      font-weight: normal;

      ~ div {
        font-weight: normal;
      }
    }
  }

  .row {
    .info {
      background-color: color(primary, ultra-light);
      font-weight: normal;
      padding: 2rem;
      text-align: center;

      &-cookies {
        padding-bottom: 2rem;
      }

      span {
        display: block;
        padding-bottom: 2rem;
      }
    }
  }

  .btn-primary {
    display: block;
    margin: 2rem auto 0;
    width: 100%;
  }

  .panel {
    margin: 0;

    .panel-body {
      h3 {
        border-bottom: 1px solid color(border, light);
        padding: 0 0 1rem;
      }

      .form-control-static {
        align-items: center;
        display: flex;
        margin-bottom: 1rem;

        //padding: 0;

        span {
          flex-grow: 1;
        }

        .btn {
          background: transparent;
          border: 0;
          box-shadow: none;
          color: #000;
          font-size: 2rem;
          font-size: 0 !important;
          font-weight: normal;
          height: auto;
          line-height: 0;
          padding: 0.5rem 1rem;

          &::before {
            content: "x";
            font-size: 2rem !important;
            line-height: 1;
          }

          .glyphicon {
            display: none !important;
          }
        }
      }
    }
  }
}

.form-delivery {
  .mode-selector-card {
    border-bottom: none !important;
  }
}

.blockshipping {
  width: 100%;
}

// tabs
.tabs {
  align-items: center;
  border: 1px solid color(border, base);
  border-radius: default(radius, medium);
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-bottom: 3rem;
  overflow: hidden;

  .tab-btn {
    background-color: color(mono, light);
    font-size: 1.4rem;
    font-weight: default(weight);
    padding: 1.35rem 1rem;
    width: 50%;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: color(grey, base);
      color: color(mono, light);
    }
  }
}

// .tab-btn {
//   align-items: center;
//   background-color: transparent;
//   cursor: pointer;
//   display: flex;
//   height: 8rem;
//   justify-content: center;
//   position: relative;
//   text-decoration: underline;
//   width: 50%;

//   &.active {
//     background-color: color(primary);
//     color: color(mono, light);
//     text-decoration: none;

//     &::after {
//       border-color: color(primary) transparent transparent transparent;
//       border-style: solid;
//       border-width: 1rem;
//       content: " ";
//       left: 50%;
//       margin-left: -1rem;
//       position: absolute;
//       top: 100%;
//     }
//   }

//   span {
//     font-size: 1.2rem;
//     font-weight: bold;
//     line-height: 2rem;
//     text-transform: uppercase;
//   }
// }
.tab-content {
  border-radius: default(radius, medium);
  display: none;
  width: 100%;

  &.active {
    display: block;
  }
}

// accordion btn
.accordion-btn {
  border-bottom: 1px solid color(border);
  cursor: pointer;
  margin: 0;
  padding-bottom: 3rem;
  padding-top: 3rem;
  width: 100%;

  &::after {
    content: "\e905";
    display: block;
    float: right;
    font-family: "icomoon";
    font-weight: 100;
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  &.active::after {
    transform: rotate(0deg);
  }

  &.selected {
    pointer-events: none;

    &::after{
      display: none;
    }
  }
}

.accordion-content {
  display: none;

  &.active {
    display: block;
  }
}

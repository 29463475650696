html {
  font-family: default(font-family);
  font-size: 62.5%;
  text-size-adjust: 100%;

  &.noScroll {
    overflow: hidden !important;
  }
}

body {
  background-color: color(background, light);
  color: color(text);
  font-size: default(font-size);
  line-height: default(line-height);
  overflow-x: hidden;
}

/* Site content */
.site-content {
  padding: 0 2rem 3.5rem;

  @media (min-width: $md-width) {
    padding: 0 3.5rem 5rem;
  }

  .ctn-fluid {
    .ctn-fluid {
      padding: 0;
    }
  }
}

.max-container {
  @media (min-width: $xxxl-width) {
    padding-left: $gutter-ctn;
    padding-right: $gutter-ctn;
  }
}

.site-content,
.max-container {
  @media (min-width: $xxxl-width) {
    margin-left: auto;
    margin-right: auto;
    max-width: 192rem;
  }
}

/* for block with bg which are in container but we need that it looks full-width because of background */
.max-width-offset-container {
  @media (min-width: $xxxl-width) {
    margin-left: calc(-1 / 2 * ((100vw - 1.5rem) - 100%)) !important;
    width: 100vw;

    &__inner {
      margin-left: auto;
      margin-right: auto;
      max-width: 192rem;
    }
  }
}

.short-store {
  .dropdown-menu-left {
    left: auto;
    right: 0;

    @media (min-width: $md-width) {
      left: 0;
      right: auto;
    }
  }
}

@media (max-width: $md-width) {
  .proximis-toolbar {
    display: none;
  }
}

[data-layout-rbs-website-error] {
  padding-bottom: 6rem;
  padding-top: 6rem;
  text-align: center;
}

#reset-password-modal-main-content {
  .text-danger {
    margin-bottom: 1rem;
  }

  .text-success {
    margin-bottom: 1rem;
  }
}

.gototop {
  align-items: center;
  background-color: color(background);
  border-radius: default(radius, big);
  bottom: 8rem;
  cursor: pointer;
  display: flex;
  height: 5rem;
  justify-content: center;
  opacity: 0%;
  position: fixed;
  right: 1rem;
  /* stylelint-disable-next-line prettier/prettier */
  transition:
    background-color 0.3s ease,
    opacity 0.3s ease,
    visibility 0.3s ease;
  visibility: hidden;
  width: 5rem;
  z-index: 999999;

  &:hover {
    background-color: color(primary);
  }

  &.active {
    opacity: 100%;
    visibility: visible;
  }

  .icon {
    color: color(mono, light);
    transition: color 0.3s ease;
  }
}

.pac-container {
  z-index: 999999;
}

.fixed-contact-block {
  bottom: 2rem;
  position: fixed;
  right: 1rem;
  z-index: 9999999;

  .popup {
    background-color: color(mono, light);
    border-radius: default(radius, medium) !important;
    box-shadow: 3px 3px 5px color(shadow, button);
    color: color(text);
    padding: 5rem 4rem 3rem;
    transition: visibility 0.3s ease, opacity 0.3s ease;

    &-close {
      @include poa(1rem, 1rem, null, null);

      .icon {
        font-size: 2rem;
      }
    }
  }

  .open-button {
    .btn {
      background-color: color(mono, light);
      border: none;
      border-radius: default(radius, big) !important;
      box-shadow: 3px 3px 5px color(shadow, button);
      display: flex;
      height: 5rem;
      overflow: visible;
      padding: 0 0 0 2rem;

      .icon {
        background-color: color(background);
        border-radius: default(radius, big) !important;
        font-size: 2rem;
        margin: 0 -1px 0 1rem;
        width: 5rem;
      }

      &:hover {
        background-color: color(background);
        color: color(text, light);
      }
    }
  }
}

[data-layout-rbs-catalog-cross-selling],
[data-layout-project-groupdigital-seen-recently] {
  margin-top: 5rem;

  @media (min-width: $md-width) {
    margin-top: 10rem;
  }

  [data-rbs-catalog-products-list-v3] {
    padding-top: 0;
  }
}

// for very big screen

/* @media (min-width: $xxxl-width) {
  .sidebar-content {
    flex-basis: 16.6666666667%;
    min-width: 16.6666666667%;
  }

  .main-content {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
} */

// SHIPPING ou global
// titre secondaire : livraison / retrait
.heading-shipping {
  font-size: 1.8rem;
  font-weight: default(weight, bold);
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.process-shipping {

  h3 {
    color: color(text, dark);
  }

  .process-button-edit-step {
    position: relative;

    @extend .btn-ternary;
    @extend .btn-small;
  }

  // LIVRAISON
}

// gift option checkbox global
[data-rbs-commerce-shipping-mode-gift] {
  label {
    line-height: 1.5;
  }

  @media (max-width: $sm-width) {
    label {
      font-size: 1.3rem;
      line-height: 1.5;
    }
  }
}

.process-payment-amounts {
  @media (min-width: $md-width) {
    .block-left {
      order: 2;
    }

    .block-right {
      order: 1;
    }
  }
}

// uniformisation formulaire - champ 100%
[rbs-storeshipping-store-mode-editor],
[data-rbs-commerce-shipping-at-home-step] {
  .control-label + div {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }

  .col-md-9.col-md-offset-3 {
    flex-basis: 100%;
    margin-left: 0;
    max-width: 100%;
    width: 100%;

    .btn-primary {
      display: block;
      margin: auto;
      margin-top: 15px;
    }

    .btn-primary,
    .btn-default {
      max-width: width(ctn, sm);
      width: 100%;
    }
  }

  address {
    margin-bottom: 1rem;
  }

  .row {
    &::before {
      display: none;
    }
  }

  .bg-info {
    background-color: transparent !important;
  }

  [data-ng-hide="isEmptyAddress(atHomeAddress)"] {
    .col-md-4,
    .col-md-8 {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
    }

    .col-md-4 {
      margin-bottom: 1.5rem;

      .btn-default {
        @extend .btn-ternary;
        @extend .btn-small;
      }

      @media (min-width: $md-width) {
        margin-bottom: 3rem;
        padding-left: 5rem;
      }
    }

    .col-md-8 {
      order: -1;
    }
  }
}

/* [data-rbs-commerce-shipping-step] {
  .table-amounts {
    margin-top: 1rem;

    @media (max-width: $xs-width) {
      margin-top: 2rem;
    }
  }

  [data-rbs-commerce-process-delivery-lines] {
    .col-sm-6 {
      @media (max-width: $xs-width) {
        width: 100%;
      }
    }
  }

  [data-ng-hide="shippingModesValid()"] {
    margin-bottom: 1rem;
  }
}
 */

// summary shipping
.panel-body {
  padding-top: 0;

  h3 + [data-rbs-commerce-summary-shipping-at-home-step],
  h3 + [data-rbs-commerce-shipping-other-step][data-summary="true"] {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

    .row {
      margin: 0;

      .col-md-4,
      .col-md-8,
      .col-sm-6 {
        padding: 0;
      }
    }

    @media (max-width: $xs-width) {
      .col-md-4,
      .col-md-8,
      .col-sm-6 {
        width: 100%;
      }
    }

    [rbs-storeshipping-store-mode-summary] {
      padding-bottom: 1.5rem;

      @media (min-width: $md-width) {
        padding-bottom: 0;
      }
    }

    [rbs-shipping-mode-summary] {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;

      img {
        order: 2;
      }

      label {
        flex-grow: 1;
        order: 1;
      }
    }
  }
}

[data-rbs-commerce-shipping-other-step] {
  .list-group-item.active,
  .list-group-item.active:focus,
  .list-group-item.active:hover {
    background-color: color(primary);
    border-color: color(border, light);
    text-shadow: none;
  }
}

// étape CADEAUX
.order {
  .glyphicon-gift::before {
    content: icon(gift);
    font-family: icomoon;
  }

  .process-gift-content {
    border-radius: 2rem;
    padding: 2rem;

    @media (min-width: $sm-width) {
      padding: 3rem;
    }
  }
}

.process-gift {
  margin-top: 0 !important;
  max-width: $container-order-process;
  padding-top: 0 !important;
  width: 100%;

  &-delivery {
    padding-bottom: 0 !important;

    .panel-heading {
      text-align: left;
    }

    // exception for this label
    .block-recap-products {
      .form-group,
      .checkbox {
        margin-bottom: 0;
        margin-top: 0;
      }

      label {
        height: auto;
        line-height: initial;
        overflow: visible;
        padding-left: 3.5rem !important;
        pointer-events: auto;
        position: static;
        white-space: normal;
      }

      tr {
        flex-basis: 100%;
        margin-bottom: 2rem;
        max-width: none;
        padding-bottom: 2rem;
        padding-left: 32%;
        width: auto;

        .line-visual {
          left: 0;
        }

        @media (min-width: $sm-width) {
          margin-bottom: 1.5rem;
          max-width: 30%;
          padding-bottom: 0;
          padding-left: 0;
          width: 30%;

          .line-visual {
            display: inline-block;
          }
        }
      }

      textarea {
        margin-bottom: 1.5rem;
      }
    }

    + div p {
      margin-bottom: 1.5rem;
      text-align: left;
    }

    [data-ng-show="line.options.pickUpDateTime"] {
      display: none;
    }
  }

  .process-button-next-step {
    margin-bottom: 4.5rem;
  }

  // summary
  .dl-horizontal dt {
    float: left;
    text-align: left;
    width: 20rem;
  }
}

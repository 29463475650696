[data-layout-rbs-mailinglist-short-subscription] {
  .form-group {
    margin: 0;

    .input-group {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      flex-wrap: wrap;
      justify-content: center;

      @media (min-width: $sm-width) {
        flex-wrap: nowrap;
      }
    }

    label {
      flex-basis: 100%;
      flex-grow: 1;
      max-width: 100%;

      @media (min-width: $sm-width) {
        flex-basis: auto;
        flex-grow: 0;
        margin-bottom: 0;
        margin-right: 2rem;
        max-width: 100%;
      }
      @media (min-width: $md-width) {
        margin-right: 6rem;
      }
    }

    .form-control {
      display: block;
      flex-basis: 100%;
      flex-grow: 1;
      float: none;
      margin-bottom: 1.5rem;
      margin-right: 0;
      max-width: 100%;
      max-width: 35rem;
      width: auto;

      @media (min-width: $xs-width) {
        flex-basis: auto;
        margin-bottom: 0;
        margin-right: 1.5rem;
      }
      @media (min-width: $sm-width) {
        margin-right: 2rem;
      }
    }

    .input-group-btn {
      display: block;
      flex-grow: 0;
      width: auto;

      .btn {
        @extend .btn-primary-light;
        @extend .btn-small;
        margin-left: 0 !important;
      }
    }
  }

  .input-group-addon {
    display: none;
  }

  .mailinglist-mini-with-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
    column-gap: 3rem;
    margin-bottom: 3rem;
    @media (min-width: $md-width) {
      flex-direction: row;
      margin-bottom: 9rem;
    }
    &__title {
      text-align: center;
      @media (min-width: $md-width) {
        text-align: left;
      }
    }
    &__form {
      width: 100%;
      @media (min-width: $md-width) {
        width: inherit;
      }
      .form-group {
        max-width: 35rem;
        margin: 0 auto;
        .form-control {
          width: 100%;
          background-color: color(mono, light);
          padding-right: 7rem;
          border-color: color(primary);
        }
      }
      .input-group-btn {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

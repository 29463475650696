.comparator-panel {
  bottom: 0;
  left: 0;
  max-height: 100%;
  opacity: 100%;
  overflow: hidden;
  position: fixed;
  transition: all 0.3s linear;
  width: 100%;
  z-index: 201;

  &.closed {
    max-height: 4rem;
  }

  &.empty {
    bottom: -4rem;
    opacity: 0%;
  }

  &-header {
    align-items: center;
    background-color: color(primary);
    display: flex;
    flex-wrap: nowrap;
    height: 4rem;
    justify-content: space-between;
    padding: 0 2rem;

    @media (min-width: $md-width) {
      height: 6rem;
      padding: 0 4.5rem;
    }

    &-title {
      color: color(mono, light);
      font-size: 1.4rem;
      font-weight: default(weight, medium);

      @media (min-width: $md-width) {
        font-size: 1.8rem;
      }
    }

    &-hide {
      background-color: transparent;
      border: 0;
      color: color(mono, light);
      font-size: 1.4rem;

      .icon {
        font-size: 1rem;
        margin-left: 1rem;
      }
    }
  }

  &-content {
    background-color: color(mono, light);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 2rem;

    @media (min-width: $sm-width) {
      flex-wrap: nowrap;
    }
    @media (min-width: $lg-width) {
      padding: 3rem 4.5rem;
      padding: 3rem 6%;
    }

    .comparator-panel-list {
      flex-grow: 1;
      margin: 0;
      max-width: 1200px;
      min-width: 100%;
      overflow-x: auto;
      padding-bottom: 2rem;
      white-space: nowrap;

      @media (min-width: $sm-width) {
        display: flex;
        margin: 0;
        min-width: 0;
        overflow-x: auto;
        padding-bottom: 0;
        white-space: normal;
      }

      &-item {
        align-items: center;
        border: 1px solid color(grey, light);
        border-radius: 5px;
        display: inline-block;
        flex-basis: 33%;
        flex-direction: column;
        flex-grow: 1;
        height: 13rem;
        margin: 0 0.5rem;
        max-width: 80%;
        padding: 0.8rem;
        position: relative;
        vertical-align: top;
        white-space: normal;
        width: 26rem;

        @media (min-width: $sm-width) {
          display: flex;
          max-width: 33%;
          width: auto;
        }
        @media (min-width: $md-width) {
          flex-direction: row;
          height: 10.5rem;
          margin: 0 1.5rem;
          padding: 0 1rem;
        }

        &.empty {
          background-color: color(grey, light);
          font-size: 1.4rem;
          font-weight: default(weight, bold);
          justify-content: center;
          padding: 0 2rem;
          text-align: center;

          @media (min-width: $md-width) {
            padding: 0 6rem;
          }

          .text-mobile {
            display: inline;

            @media (min-width: $md-width) {
              display: none;
            }
          }

          .text-pc {
            display: none;

            @media (min-width: $md-width) {
              display: inline;
            }
          }
        }

        &__inner {
          align-items: flex-start;
          display: flex;
          justify-content: flex-start;
        }

        &__image {
          height: 11rem;
          width: 11rem;

          @media (min-width: $sm-width) {
            height: 9rem;
            width: 9rem;
          }

          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        &__content {
          margin-left: 1rem;
        }

        &-remove {
          cursor: pointer;
          font-size: 1rem;
          padding: 0.5rem;
          position: absolute;
          right: 0;
          top: 0;
        }

        &-title {
          display: block;
          font-size: 1.4rem;
          padding: 0.8rem 0 0;
          text-align: left;

          @include line-clamp(3, 1.4);

          @media (min-width: $md-width) {
            @include line-clamp(2, 1.4);
          }
        }

        &-brand,
        &-ref {
          display: block;
        }

        &-brand {
          font-size: 1.4rem;
        }

        &-ref {
          font-size: 1.2rem;
        }
      }
    }

    .comparator-panel-actions {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      min-width: 100%;
      padding-top: 1rem;

      @media (min-width: $sm-width) {
        flex-direction: column;
        justify-content: center;
        min-width: auto;
        padding-left: 3rem;
        padding-top: 0;
      }

      .btn {
        padding: 1rem 4rem;

        @media (min-width: $sm-width) {
          margin-bottom: 2rem;
        }
        @media (min-width: $md-width) {
          padding: 2rem 6rem;
        }
      }
    }
  }
}

.comparator {
  padding-bottom: 10rem;

  .ctn-fluid {
    overflow: auto;
    padding-bottom: 2rem;
  }

  &-table {
    width: 100%;

    tr {
      &:nth-child(even) {
        background-color: color(grey, light);
      }

      td {
        min-width: 27rem;

        &:first-child {
          display: none;
          font-size: 1.6rem;
          font-weight: default(weight, bold);

          @media (min-width: $md-width) {
            display: table-cell;
          }
        }
      }
    }
  }

  &-products {
    td {
      padding: 0 1rem;

      @media (min-width: $md-width) {
        padding: 0 2rem;
      }
    }

    .product-item-ctn {
      justify-content: center;
      margin: 0;
      padding: 0;
    }

    .product-item {
      margin-bottom: 0;
      position: relative;

      .thumbnail {
        padding-bottom: 3rem;

        @media (min-width: $md-width) {
          padding-bottom: 5rem;
        }

        .caption {
          padding-bottom: 0;
          width: 100%;
        }

        [data-rbs-catalog-product-list-item-add-to-cart-buttons-v3] {
          display: block;
          margin-top: 2rem;
          text-align: center;

          .btn {
            width: 100%;

            @extend .btn-secondary;
            @extend .btn-small;
          }
        }
      }

      .comparator-button {
        display: none;
      }

      .btn {
        @media (max-width: $sm-width-max) {
          padding: 1rem !important;
        }
      }
    }
  }

  &-attributes {
    td {
      border: 1px solid color(grey);
      padding: 1.5rem 1rem;

      @media (min-width: $md-width) {
        padding: 1.5rem 2rem;
      }
    }

    &-name {
      display: inline;
      font-weight: default(weight, bold);

      @media (min-width: $md-width) {
        display: none;
      }
    }
  }
}

// PAYMENT

#payment {
  // amount
  .process-payment-amounts {
    border-bottom: 1px solid color(border, medium);
    border-top: 1px solid color(border, medium);
    padding: 1rem 0;

    table tr {
      border: 0;
    }

    @media (min-width: $md-width) {
      padding: 2rem 0;
    }
  }

  // edit invoice address
  [data-ng-if="processData.addressEdition"] {
    [data-rbs-geo-address-editor] + .row > .col-sm-8 {
      flex-basis: 100%;
      margin: 0;
      max-width: 100%;
      width: 100%;

      p {
        margin-bottom: 1rem;
      }
    }

    [data-ng-show="userAddresses.length && !processData.addressEditionForm"] {
      margin: 0;
      padding: 0;

      .bg-info {
        background-color: transparent;
      }

      > div {
        padding: 15px 0;

        .btn-primary {
          margin-top: 10px;
          width: 100%;

          @media (min-width: $sm-width) {
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 1.5rem;
            width: auto;
          }
        }
      }
    }
  }
}

[data-rbs-commerce-payment-step-transaction] {
  [data-rbs-commerce-payment-connector-deferred] {
    .panel-body {
      text-align: center;
    }
  }

  [name="adyenForm"] {
    .col-md-6 {
      flex-basis: 100%;
      max-width: 100%;
      text-align: left;

      @media (min-width: $sm-width) {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
    float: none !important;
    text-align: center;

    .form-inline {
      display: flex;
    }

    .custom-select + .custom-select {
      margin-left: 2rem;
    }
  }

  .list-group-item {
    border: 1px solid color(border, medium) !important;
    border-radius: default(radius, medium) !important;
    margin-bottom: 1.5rem;
    padding: 3rem 1.5rem;
    text-align: left;

    > .row {
      align-items: center;
      margin: 0;

      > div:first-child {
        padding-left: 4rem;
      }
    }

    .col-sm-5 {
      font-size: 0;
      padding-left: 4rem;

      img {
        margin-top: 1rem;
      }

      @media (min-width: $sm-width) {
        padding-left: 1.5rem;
        text-align: right;

        img {
          margin-top: 0;
        }
      }
    }
  }

  .list-group-item.active,
  .list-group-item.active:focus,
  .list-group-item.active:hover {
    background-color: transparent;
    background-image: none;
    border: 1px solid color(primary) !important;
    color: color(text);
    text-shadow: none;
  }

  .form-validation {
    text-align: center;
  }
}

.process-payment {
  padding-bottom: 5rem;

  .panel-heading {
    border-bottom: none !important;
    font-size: 2.5rem !important;
    margin-bottom: 0 !important;

    @media (min-width: $sm-width) {
      font-size: 3rem !important;
    }
  }

  &-content {
    border-radius: 2rem;
    padding: 2rem !important;

    @media (min-width: $md-width) {
      padding: 3rem !important;
    }
  }

  .block {
    width: 100%;
  }

  .connector-configuration-zone {
    .pull-right {
      float: none !important;
    }
  }

  .blockrecap {
    background-color: color(primary, ultra-light);
    padding: 2rem 0;

    .table-amounts {
      font-size: 1.5rem;
      margin: 0;

      td {
        font-weight: 100 !important;
      }

      &.total,
      &.taxes {
        font-weight: 500 !important;

        tr td {
          font-weight: 500 !important;
        }
      }
    }
  }

  .billing-address {
    text-align: center;

    .address {
      &__delivery-info {
        font-size: 1.4rem;
        margin-bottom: 1rem;

        li {
          color: color(alert, success);
        }
      }
    }

    .address-container {
      border: 1px solid color(border, medium);
      border-radius: default(radius, medium);
      margin: 2rem 0;
      padding: 3rem;
      text-align: left;
    }
  }

  .process-payment-coupons {
    border: 1px solid color(border);
    margin: 2rem 0 4rem;
    padding: 3rem;

    .input-group,
    .label-coupon {
      margin: auto;
      max-width: 50rem;
    }

    .label-coupon {
      display: block;
      margin-bottom: 1rem;
    }

    .btn {
      margin-top: 0;
    }
  }

  [data-connector-configuration] {
    form {
      float: none !important;
    }
  }
}

.addressEdition {
  &-title {
    margin: 2rem 3rem 0;
  }
}

// cb
[data-proximis-datatrans-payment-connector-secure-fields] {
  .form-ctrl-ctn label,
  .form-ctrl-ctn.focused label {
    font-size: 1.4rem;
    height: auto;
    line-height: 1.4;
    overflow: visible;
    padding: 0;
    position: static;
  }

  select {
    display: block !important;
    padding-top: 0 !important;
  }

  .custom-select {
    display: inline-block;
    width: 47%;

    select {
      padding: 0 1rem;
      text-align: left;
    }

    span,
    ul {
      display: none !important;
    }
  }
}

/* stylelint-disable scss/at-import-no-partial-leading-underscore */

// Import des styles communs
@import "_communs/_init/_variables";

// Import des styles communs
@import "../../../Antapx/Assets/scss/_communs/_init/_breakpoints-fn";
@import "../../../Antapx/Assets/scss/_communs/_init/_flexivarious";
@import "_communs/_init/_fonts";
@import "../../../Antapx/Assets/scss/_communs/_init/_reset";
@import "_communs/_init/_mixins";
@import "../../../Antapx/Assets/scss/_communs/_init/_placeholders";
@import "_lib/_owl";
@import "_lib/_lightgallery";
@import "_communs/_k2000/_icons";
@import "_communs/_k2000/_alert";
@import "_communs/_k2000/_block";
@import "_communs/_k2000/_borders";
@import "_communs/_k2000/_breadcrumb";
@import "_communs/_k2000/_btn";
@import "_communs/_k2000/_read-more";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_carousel";
@import "_communs/_k2000/_colors";
@import "_communs/_k2000/_doofinder";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_dropdown";
@import "_communs/_k2000/_form";
@import "_communs/_k2000/_helper";
@import "_communs/_k2000/_mailinglist-subscription";
@import "_communs/_k2000/_modal";
@import "_communs/_k2000/_rating";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_modal-custom";
@import "_communs/_k2000/_navbar";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_panels";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_popover";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_reviews";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_spacing";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_store-list";
@import "_communs/_k2000/_table-responsive";
@import "../../../Antapx/Assets/scss/_communs/_k2000/_trackers-ask-consent-gdpr";
@import "_communs/_k2000/_typo";
@import "_communs/_k2000/_tabs";
@import "_communs/_k2000/_richtext";
@import "_communs/_footer";
@import "_communs/_header";
@import "_communs/_layout";
@import "_communs/_k2000/_slider-product-one-line";
@import "_communs/_k2000/_one-line";
@import "_communs/_k2000/_blocks/_product-odr";
@import "_communs/_k2000/_blocks/_product-reviews";

// Import styles de pages
@import "_pages/account/_menu-account";
@import "_pages/account/_account";
@import "_pages/order-process/_order-process";
@import "_pages/order-process/_order-process-identification";
@import "_pages/order-process/_order-process-recap-products";
@import "_pages/order-process/_order-process-shipping";
@import "_pages/order-process/_order-process-shipping-relay";
@import "_pages/order-process/_order-process-shipping-mode-selector";
@import "_pages/order-process/_order-process-shipping-store";
@import "_pages/order-process/_order-process-shipping-athome";
@import "_pages/order-process/_order-process-gift";
@import "_pages/order-process/_order-process-payment";
@import "_pages/order-process/_order-process-simplified";
@import "_pages/order-process/_order-process-confirmation";
@import "_pages/order-process/_order-steps";
@import "_pages/order-process/_order-summary";
@import "_pages/order-process/_order-summary-recap";
@import "../../../Antapx/Assets/scss/_pages/_404";
@import "_pages/_authentication";
@import "_pages/category/_category";
@import "_pages/category/_facets";
@import "_pages/category/_pagination";
@import "_pages/category/_announcement";
@import "_pages/_brand";
@import "_pages/_blog";
@import "../../../Antapx/Assets/scss/_pages/_glossaire";
@import "_pages/_index";
@import "_pages/_product";
@import "../../../Antapx/Assets/scss/_pages/_sitemap";
@import "_pages/_stores";
@import "_pages/_comparator";
@import "_pages/_styleguide";

/* stylelint-enable scss/at-import-no-partial-leading-underscore */

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  // display: none;

  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  z-index: 1;

  .owl-stage {
    backface-visibility: hidden; /* fix firefox animation glitch */
    position: relative;
    touch-action: pan-y;
    touch-action: manipulation;
  }

  .owl-stage::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
  }

  .owl-stage-outer {
    overflow: hidden;
    position: relative;

    /* fix for flashing background */
    transform: translate3d(0, 0, 0);
  }

  .owl-wrapper,
  .owl-item {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }

  .owl-item {
    backface-visibility: hidden;
    float: left;
    min-height: 1px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  .owl-item img {
    display: block;
    width: 100%;
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    user-select: none;
  }

  .owl-nav button.owl-prev,
  .owl-nav button.owl-next,
  button.owl-dot {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    outline: 0;
    padding: 0 !important;
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    display: block;
    opacity: 0%;
  }

  &.owl-hidden {
    opacity: 0%;
  }

  &.owl-refresh .owl-item {
    visibility: hidden;
  }

  &.owl-drag .owl-item {
    touch-action: pan-y;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }

  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }

  .owl-animated-in {
    z-index: 0;
  }

  .owl-animated-out {
    z-index: 1;
  }

  .fadeOut {
    animation-name: fadeOut;
  }
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

/*
 * 	Default theme - Owl Carousel CSS File
 */

$color-base: color(button) !default;
$color-white: #fff !default;
$color-gray: rgba(color(button), 0.3) !default;

// nav

$nav-color: $color-white !default;
$nav-color-hover: $color-white !default;
$nav-font-size: 14px !default;
$nav-rounded: 3px !default;
$nav-margin: 5px !default;
$nav-padding: 4px 7px !default;
$nav-background: $color-gray !default;
$nav-background-hover: $color-base !default;
$nav-disabled-opacity: 0.5 !default;

// dots

$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-rounded: 30px !default;
$dot-margin: 5px !default;
$dot-background: color(mono, light) !default;
$dot-border: none !default;
$dot-background-active: color(tertiary) !default;

// Styling Next and Prev buttons
.owl-nav {
  margin-top: 20px;
  -webkit-tap-highlight-color: transparent;
  text-align: center;

  [class*="owl-"] {
    background: $nav-background;
    border-radius: 3px;
    color: $nav-color;
    cursor: pointer;
    display: inline-block;
    font-size: $nav-font-size;
    margin: $nav-margin;
    padding: $nav-padding;

    &:hover {
      background: $nav-background-hover;
      color: $nav-color-hover;
      text-decoration: none;
    }
  }

  .disabled {
    cursor: default;
    opacity: $nav-disabled-opacity;
  }
}

// Styling dots
.owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-dots {
  -webkit-tap-highlight-color: transparent;
  text-align: center;

  .owl-dot {
    display: inline-block;
    *display: inline;
    zoom: 1;

    span {
      backface-visibility: visible;
      background: $dot-background;
      border: $dot-border;
      border-radius: $dot-rounded;
      display: block;
      height: $dot-height;
      margin: $dot-margin;
      transition: opacity 200ms ease;
      width: $dot-width;
    }

    &.active,
    &:hover {
      span {
        background: $dot-background-active;
      }
    }
  }
}

.owl-prev,
.owl-next {
  bottom: 0;
  margin: auto;
  position: absolute;
  top: 0;

  .icon {
    background-color: color(background);
    border-radius: default(radius, big);
    color: color(text, light);
    font-size: 1rem;
    padding: 0.7rem;
    transition: color 0.3s ease, background-color 0.3s ease;

   
  }
  @media (hover: none) {
    &:hover {
      .icon {
        background-color: color(background);
        color: color(text, light);
      }
    }
  }

  @media (hover: hover) {
    &:not(.disabled):hover {
      .icon {
        background-color: color(background, light);
        color: color(text);
      }
    }
    &.disabled:hover {
      cursor: default;
    }
  }
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}

// owl brand
.brand-owl-ctn {
  .ctn-small {
    padding: 0;

    .owl-stage-outer {
      padding: 0 1.5rem;
    }
  }

  @media (max-width: $sm-width) {
    .owl-nav {
      display: none;
    }
  }
}

.owl-ctn {
  &.y-padded {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;

    @media (max-width: $sm-width) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.with-logo {
    a {
      display: block;
      height: 100%;
    }

    li {
      background-color: color(mono, light);
      border: 1px solid color(grey);
      border-radius: default(radius);
      display: flex;
      height: 7.2rem;
      justify-content: center;
      padding: 1rem;
      text-align: center;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
      }
    }
  }
}

.facets-list-container {
  display: none;

  @media (min-width: $sm-width) {
    display: block;
    padding-bottom: 4rem;
    padding-top: 10.2rem;
  }
}

.facets-list {
  background-color: color(mono, light);
  border-radius: default(radius, block);
  box-shadow: default(shadow, light);
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .checkbox {
    margin-bottom: 0;
  }

  > div {
    &:first-child {
      h4 {
        margin-top: 0;
      }
    }

    &[data-facets-recapitulatory] {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .active-filter {
        background-color: color(grey, light);
        border-radius: default(radius);
        display: inline-block;
        font-size: 1.4rem;
        line-height: 1;
        margin: 0 1rem 1rem 0;
        padding: 0.5rem 1rem;

        &::before {
          content: icon(dropdown-expand);
          font-family: default(font-family, icon);
          font-size: 0.9rem;
          font-weight: default(weight, bold);
          margin-right: 0.5rem;
        }
      }
    }

    hr {
      border-top: 1px solid color(border);

      @media (min-width: $sm-width) {
        display: none;
      }
    }
  }

  .h4 {
    align-items: center;
    color: color(text);
    display: flex;
    font-size: 1.6rem;
    font-weight: default(weight, medium);
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 2rem;

    .btn {
      border: 0;
      border-radius: 0 !important;
      display: none;
      padding: 0.2rem 0 0.2rem 1rem;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        box-shadow: none;
        color: color(text);
      }
    }
  }

  [data-uib-collapse="!isCollapsed"] {
    @media (min-width: $sm-width) {
      background-color: color(mono, light);
      border: 1px solid color(grey, medium);
      left: 0;
      max-height: 300px;
      overflow: auto;
      padding: 0.5rem 1.5rem 1rem;
      position: absolute;
      top: 3.6rem;
      width: 100%;
      z-index: 10;
    }
  }

  .facet-values-container {
    li, .filter-list-display-more {
      margin-top: 1.5rem;
    }

    .radio label,
    .checkbox label {
      align-items: center;
      display: flex;
    }

    .badge {
      display: none;
      margin-left: 5px;
    }

    // checkbox
    .filter-list-item-label {
      + .filter-list-display-more {
        display: none;
      }

      &.ng-hide + .filter-list-display-more {
        cursor: pointer;
        display: block;
      }
    }

    [data-rbs-elasticsearch-facet-checkbox] {
      li.active {
        ~ li .filter-list-item-label.ng-hide,
        ~ li .filter-list-item-checkbox.ng-hide {
          display: block !important;
        }
      }
    }
  }

  [data-rbs-elasticsearch-facet-interval=""] {
    height: 1.5rem;
    margin-top: 4rem;
    padding: 0 0.9rem;
    position: relative;
  }

  .noUi-horizontal {
    background-color: color(grey, medium);
    border: 0;
    box-shadow: none;
    height: 5px;
  }

  .range-slider-labels {
    display: none;
  }

  .range-slider {
    .noUi-handle {
      background: color(mono, light);
      border: 2px solid color(primary);
      border-radius: 50%;
      box-shadow: none;
      cursor: grab;
      height: 18px;
      right: -9px;
      top: -7px;
      width: 18px;

      &::before,
      &::after {
        display: none;
      }

      .tooltip-inner {
        background: color(mono, light);
        color: color(text);
        font-size: 1.6rem;
        font-weight: default(weight);
        padding: 0;
      }

      .tooltip {
        font-family: default(font-family);
        font-size: 14px;
        opacity: 100%;

        &.top {
          left: -1rem;
          margin-top: 0;
          padding: 0;
          top: -25px;

          .tooltip-arrow {
            display: none;
          }
        }
      }
    }

    .noUi-base {
      .noUi-origin {
        &:last-child {
          .tooltip {
            &.top {
              left: auto;
              right: -1rem;
            }
          }
        }
      }
    }

    &.noUi-state-drag {
      cursor: grabbing !important;

      .noUi-handle {
        cursor: grabbing !important;
      }
    }

    .noUi-target {
      background: color(background);
      border: 1px solid color(background);
      border-radius: 5px;
      box-shadow: none;
    }

    .noUi-horizontal {
      border-radius: 5px;
      height: 10px;
    }

    .noUi-connect {
      background: color(primary);
    }
  }

  .btn-responsive-toolbar {
    display: none;
  }
}

.modal-rbs-elasticsearch-facet-responsive {
  .modal-body {
    height: calc(100% - 6.5rem);
  }

  .facets-list {
    height: 100%;
    padding-right: 1rem;

    h4 {
      .btn {
        display: none !important;
      }
    }

    .collapse {
      display: block !important;
      height: auto !important;
    }

    &__content {
      // border: 1px solid red;
      height: calc(100% - 6.5rem);
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 1rem;
    }

    &__buttons {
      direction: rtl;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      padding: 2rem 0 1rem;
    }

    .noUi-handle-upper {
      .tooltip {
        margin-right: 1rem;
      }
    }
  }

  .modal-filter-btns {
    align-items: center;
    background-color: rgba(255, 255, 255, 80%);
    bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    left: 0;
    padding: 2rem;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 2;

    .btn-default {
      border: 0;
      border-radius: 0 !important;
      color: color(text);
      order: -1;
      padding: 0 2rem 0 0;
      text-decoration: underline;

      &:hover,
      &:focus {
        background-color: transparent;
        color: color(primary);
      }
    }
  }
}

[data-rbs-catalog-stock-avalability-filter] {
  label {
    padding-left: 4.5rem !important;
  }

  .custom-checkbox {
    @extend .switch;
  }
}

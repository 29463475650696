// eresa
.simplified-process {
  &-title {
    @extend .order-process-ctn;
  }
}

.process-identification-summary.white-box-padded {
  padding-top: 2rem;

  @media (min-width: $md-width) {
    padding-top: 3rem;
  }
}

#confirm {
  @extend .order-process-ctn;
}

%horizontal-center {
  left: 50%;
  position: absolute;

  @include transform(translateX(-50%));
}

%vertical-center {
  position: absolute;
  top: 50%;

  @include transform(translateY(-50%));
}

%full-center {
  left: 50%;
  position: absolute;
  top: 50%;

  @include transform(translate(-50%, -50%));
}

// detail news
.event-details {
  padding: 0;

  &__title {
    font-family: default(font-family);
    font-size: 2.4rem;
    font-weight: 500;

    @media (min-width: $sm-width) {
      font-size: 4.4rem;
    }
  }

  .event-share {
    align-items: center;
    display: flex;
    margin-bottom: 3rem;

    &__title {
      font-size: 1.4rem;

      @media (min-width: $sm-width) {
        font-size: 1.8rem;
      }
    }

    &__link {
      .icon {
        font-size: 3rem;
        margin-left: 1rem;
      }
    }
  }

  .event-visual {
    border-radius: default(radius, medium);
    width: 100%;
  }

  .event-summary,
  .event-text {
    margin: auto;
  }
}

// listing news
[data-layout-rbs-event-contextual-list],
.event-contextual-list {
  h1 {
    @extend .h2;
    text-align: center;
  }

  .list-items {
    .inner,
    [class^="list-items-"] {
      display: grid;
      gap: 0;
      grid-template-columns: repeat(1, 1fr);
      padding: 0;

      @media (min-width: $sm-width) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: $lg-width) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .events-list__item {
    @extend .xxs;
    @extend .xs;
    @extend .sm;
  }

  // case page news mobile
  @media (max-width: $sm-width-max) {
    .overflow-mobile {
      > div {
        grid-gap: 0;

        &::before {
          content: "";
          display: block;
          flex-basis: 0.5rem;
          min-width: 2rem;
        }
      }

      .events-list__item {
        height: auto;
        padding: 0 1rem;

        .event-details-news {
          height: 100%;
        }
      }
    }
  }
}

// listing news - bloc news
.event-details-news {
  height: 100%;
  margin-bottom: 0;
  padding: 2rem 2rem 3rem;

  .event-visual {
    width: 100%;
  }

  .event-title {
    line-height: 2.8rem;
    margin-top: 2rem;
  }

  .event-summary {
    font-size: 1.8rem;
    line-height: 2.1rem;
    position: relative;
    z-index: 1;
  }

  .event-link {
    display: block;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-top: 2rem;
    text-decoration: underline;
  }
}

.store-caption {
  display: flex;

  &-hours {
    ul li {
      display: inline-block;
      font-size: 1.2rem;
      padding-right: 2rem;
      vertical-align: top;

      @media (min-width: $sm-width) {
        font-size: 1.6rem;
      }

      &:last-child {
        padding-right: 0;
      }

      li {
        display: block;
        padding-bottom: 1rem;
        padding-right: 0;
      }
    }

    .day {
      font-weight: 700;
    }
  }
}

.store-reviews {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  &__text {
    font-size: 1.2rem;
    margin-left: 1rem;
  }
}

[data-layout-rbs-storelocator-search] {
  .navbar {
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    min-height: auto;
    padding: 3rem 0 5rem;

    @media (min-width: $sm-width) {
      padding: 7rem 0 10rem;
    }

    & + .content > div > .text-danger {
      text-align: center;
    }

    @media (min-width: $sm-width) {
      flex-direction: row;
    }

    &::before,
    &::after {
      content: none;
    }

    &-form {
      border: 0;
      margin-bottom: 0;
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
      width: 100%;

      @media (min-width: $sm-width) {
        width: auto;
      }

      &:first-child {
        order: 2;
        text-align: center;

        .btn,
        .icon-geoloc {
          border-radius: 0 !important;
          display: inline-block;
          margin-top: 1.5rem;
          vertical-align: middle;

          @media (min-width: $sm-width) {
            margin-top: 0;
          }
        }

        .btn {
          @extend .btn-link;

          .glyphicon {
            display: none;
          }
        }
      }

      select {
        display: none;
      }

      .btn {
        text-decoration: underline;

        &::after {
          display: none !important;
        }
      }

      &:nth-child(3) {
        padding: 0;

        @media (min-width: $sm-width) {
          padding-right: 2rem;
        }

        .pac-target-input {
          background-color: color(mono, light);
          border-color: color(primary);
          padding: 1rem 2rem;
          padding: 1rem 4rem 1rem 2rem;
          text-overflow: ellipsis;
          width: 100%;

          @media (min-width: $sm-width) {
            width: 56rem;
          }
        }

        .form-group {
          position: relative;

          &::after {
            color: color(primary);
            content: icon(search);
            font-family: default(font-family, icon);
            position: absolute;
            right: 2rem;
            top: 1.5rem;
          }
        }
      }
    }

    .navbar-text {
      display: none;
    }
  }

  .content {
    margin: auto;
    max-width: 1600px;
    padding: 0 0 5rem;

    @media (min-width: $sm-width) {
      padding: 0 2rem 5rem 0;
    }

    & > div > p {
      font-size: 1.4rem;
      font-weight: default(weight, medium);
      margin-bottom: 2rem;

      @media (min-width: $sm-width) {
        font-size: 2.4rem;
        margin-bottom: 4rem;
      }
    }

    .tab-btn {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      width: 100%;

      @media (min-width: $sm-width) {
        display: none;
      }

      .icon {
        font-size: 1.6rem;
      }
    }

    .tab-content {
      border: none;

      @media (min-width: $sm-width) {
        display: block;
        visibility: visible;
      }

      .tags {
        > div {
          justify-content: flex-start;

          .tag {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
    }

    .col-md-6 {
      &:nth-child(1) {
        flex-basis: 3100%;
        width: 100%;

        @media (min-width: $sm-width) {
          flex-basis: 35%;
          max-width: 96%;
        }

        li {
          background-color: color(mono, light);
          border-radius: default(radius, medium);
          box-shadow: default(shadow, light);
          display: block;
          margin-bottom: 1.5rem;
          padding: 2rem;

          .glyphicon {
            display: none;
          }

          .row {
            margin: 0 !important;
            width: 100%;

            address {
              .flag {
                display: none;
              }

              .tags {
                margin: 1rem 0;
              }

              .phone {
                font-size: 1.8rem;
                margin-top: 1rem;
              }
            }
          }

          .btn-responsive-toolbar {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 2rem;
            width: 100%;

            @media (min-width: $sm-width) {
              margin-top: 0;
            }

            &.popin {
              display: none;
            }

            .buttons-ctn {
              margin: 0.5rem 0;
              width: 100%;

              @media (min-width: $sm-width) {
                display: flex;
              }

              .btn {
                width: 100%;

                @media (min-width: $sm-width) {
                  width: auto;
                }

                &:last-child {
                  margin-top: 1rem;

                  @media (min-width: $sm-width) {
                    margin-left: 1rem;
                    margin-top: 0;
                  }
                }
              }
            }

            a {
              display: block;
            }

            .btn-view-store {
              margin-top: 1rem;
            }
          }

          .view-store {
            display: none;
          }
        }

        .btn-ternary {
          text-decoration: underline !important;
        }
      }

      &:nth-child(2) {
        @media (min-width: $sm-width) {
          flex-basis: 65%;
          max-width: 65%;
          padding: 0;
          width: 65%;
        }

        .map-container {
          border-radius: default(radius, medium);
          min-height: 436px;
          overflow: hidden;

          @media (min-width: $sm-width) {
            min-height: 812px;
          }
        }
      }

      .show-all {
        display: none;
      }
    }

    .store-facets {
      .facet-nav {
        margin: auto;
        max-width: 1260px;
      }
    }
  }

  .marker-popup-content {
    font-family: default(font-family);

    strong {
      display: inline-block;
      margin-bottom: 1rem;
    }

    [data-rbs-geo-phone-formatter] {
      margin-top: 1rem;
    }

    .btn {
      margin-top: 2rem;
      padding: 1rem 4rem;
      width: 100%;

      .glyphicon {
        display: none;
      }
    }
  }
}

.store {
  background-color: color(mono, light);
  border-radius: default(radius, medium);
  margin: 5rem auto 0;
  padding: 5rem 0;

  &-content {
    margin: auto;
    width: 90%;

    @media (min-width: $sm-width) {
      width: 70%;
    }

    &-infos {
      margin-top: 3rem;

      @media (min-width: $sm-width) {
        margin-top: 5rem;
      }
    }

    &-location,
    .opening-hours {
      width: 100%;

      @media (min-width: $sm-width) {
        width: 50%;
      }
    }

    .opening-hours {
      margin-top: 3rem;

      @media (min-width: $sm-width) {
        margin-top: 0;
      }

      &-item {
        text-align: left;
      }

      &-day {
        width: 10rem;
      }

      &-hour {
        margin-bottom: 1rem;
        width: calc(100% - 10rem);
      }

      &-specials {
        margin-top: 3rem;
      }
    }

    &-map {
      border-radius: default(radius, medium);
      overflow: hidden;

      &-buttons {
        display: none;
      }
    }

    &-map-container {
      margin-top: 5rem;
    }

    .virtual-visit {
      margin-top: 2rem;

      .btn {
        align-items: center;
        display: flex;
        justify-content: center;

        img {
          margin-right: 1rem;
        }
      }
    }
  }

  &-buttons {
    margin: auto;
    margin-top: 3rem;
    width: 90%;

    @media (min-width: $sm-width) {
      display: flex;
      justify-content: center;
      width: 70%;
    }

    .btn {
      display: block;
      padding: 1.4rem 3rem;
      width: 100%;

      @media (min-width: $sm-width) {
        margin-bottom: 0;
        margin-right: 1.5rem;
      }
    }
  }

  &-presentation {
    margin: 4rem auto 2rem;
    text-align: center;

    &-title {
      margin: 0;
      padding: 2rem 0;
    }

    &-description {
      padding-bottom: 2rem;
    }

    &-slider {
      width: 100%;

      .owl-item {
        img {
          border-radius: 1rem;
          margin: auto;
          max-width: 100%;
          width: auto;
        }
      }
    }
  }

  &-about {
    margin: 5rem auto 2rem;

    @media (min-width: $sm-width) {
      margin: 10rem auto 2rem;
    }
  }

  &-services {
    margin: 5rem auto 2rem;
  }

  &-services2 {
    margin: 5rem auto 2rem;

    ul {
      list-style: disc;
      padding-left: 1.2rem;
    }
  }

  &-service {
    flex-wrap: nowrap;

    &-image {
      align-items: center;
      background-color: color(mono, light);
      border-radius: 1.2rem;
      box-shadow: 0 5px 8px rgba(0, 0, 0, 5%);
      display: flex;
      height: 7rem;
      justify-content: center;
      min-width: 7rem;
      overflow: hidden;

      @media (min-width: $md-width) {
        height: 10rem;
        min-width: 10rem;
      }
    }
  }

  &-team {
    margin: 5rem auto 0;

    &-cards {
      overflow-x: auto;
      padding-left: 2rem;
      display: flex;
      flex-wrap: nowrap;
      
      @media (min-width: $sm-width) {
        padding-left: 0;
        width: auto;
        flex-wrap: wrap;
      }
    }

    &-card {
      display: inline-block;
      margin-right: 2rem;
      margin-bottom: 2rem;
      text-align: center;
      
      @media (min-width: $sm-width) {
        width: calc(33.33% - 2rem);
      }

      @media (min-width: $md-width) {
        width: calc(25% - 2rem);
      }

      &-image {
        border-radius: default(radius, big);
        overflow: hidden;
        width: 10rem;
        height: 10rem;
        font-size: 0;
        line-height: 0;
        background-size: cover;
        background-position: center;
        display: inline-block;
        
        &.has-image {
          box-shadow: default(shadow);
        }
      }
    }
  }
}

// storelocator
.map-container {
  .btn {
    margin-top: 1rem;

    .glyphicon-arrow-right {
      display: none;
    }
  }
}

.store-locator {
  &__alert {
    &--white-zone {
      .icon {
        font-size: 5rem;

        + div {
          padding-left: 2rem;
        }
      }

      .btn {
        text-align: left;
      }

      + .store-locator__results {
        .show-all {
          display: none !important;
        }
      }
    }
  }
}

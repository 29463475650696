/* stylelint-disable prettier/prettier */
@font-face {
  font-display: swap;
  font-family: "Fira";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/fira/FiraSans-Regular.eot");
  src:
    url("../fonts/fira/FiraSans-Regular.eot?#iefix")
    format("embedded-opentype"),
    url("../fonts/fira/FiraSans-Regular.woff2") format("woff2"),
    url("../fonts/fira/FiraSans-Regular.woff") format("woff"),
    url("../fonts/fira/FiraSans-Regular.ttf") format("truetype"),
    url("../fonts/fira/FiraSans-Regular.svg#FiraSans-Regular") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Fira";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/fira/FiraSans-Medium.eot");
  src:
    url("../fonts/fira/FiraSans-Medium.eot?#iefix")
    format("embedded-opentype"),
    url("../fonts/fira/FiraSans-Medium.woff2") format("woff2"),
    url("../fonts/fira/FiraSans-Medium.woff") format("woff"),
    url("../fonts/fira/FiraSans-Medium.ttf") format("truetype"),
    url("../fonts/fira/FiraSans-Medium.svg#FiraSans-Medium") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src:
    url("../fonts/poppins/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-display: block;
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/icomoon/icomoon.eot?bmsgoo");
  src:
    url("../fonts/icomoon/icomoon.eot?bmsgoo#iefix")
    format("embedded-opentype"),
    url("../fonts/icomoon/icomoon.woff2?bmsgoo") format("woff2"),
    url("../fonts/icomoon/icomoon.ttf?bmsgoo") format("truetype"),
    url("../fonts/icomoon/icomoon.woff?bmsgoo") format("woff"),
    url("../fonts/icomoon/icomoon.svg?bmsgoo#icomoon") format("svg");
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-logo_insta::before {
  content: "\e94e";
}

.icon-point::before {
  content: "\e946";
}

.icon-product-grid::before {
  content: "\e944";
}

.icon-product-list::before {
  content: "\e945";
}

.icon-bulle_sourir::before {
  content: "\e900";
}

.icon-carte::before {
  content: "\e901";
}

.icon-centrer::before {
  content: "\e902";
}

.icon-checkbox-false::before {
  content: "\e903";
}

.icon-checkbox-true::before {
  content: "\e904";
}

.icon-arrow-down::before {
  content: "\e905";
}

.icon-arrow-left::before {
  content: "\e906";
}

.icon-arrow-right::before {
  content: "\e907";
}

.icon-arrow-up::before {
  content: "\e908";
}

.icon-check::before {
  content: "\e909";
}

.icon-comparator::before {
  content: "\e90a";
}

.icon-close::before {
  content: "\e90b";
}

.icon-talk::before {
  content: "\e90c";
}

.icon-empty-star::before {
  content: "\e90d";
}

.icon-full-star::before {
  content: "\e90e";
}

.icon-arrow::before {
  content: "\e90f";
}

.icon-circle-arrow-down::before {
  content: "\e910";
}

.icon-circle-arrow-right::before {
  content: "\e911";
}

.icon-circle-arrow-left::before {
  content: "\e912";
}

.icon-circle-arrow-top::before {
  content: "\e913";
}

.icon-mail::before {
  content: "\e914";
}

.icon-phone::before {
  content: "\e915";
}

.icon-geoloc::before {
  content: "\e916";
}

.icon-heart::before {
  content: "\e917";
}

.icon-print::before {
  content: "\e918";
}

.icon-infos::before {
  content: "\e919";
}

.icon-installation::before {
  content: "\e91a";
}

.icon-list::before {
  content: "\e91b";
}

.icon-store::before {
  content: "\e91c";
}

.icon-magasin_petit::before {
  content: "\e91d";
}

.icon-map-marker::before {
  content: "\e91e";
}

.icon-menu::before {
  content: "\e91f";
}

.icon-minus::before {
  content: "\e920";
}

.icon-pictos_univers-conseils::before {
  content: "\e921";
}

.icon-pictos_univers-lessive::before {
  content: "\e922";
}

.icon-pictos_univers-top_marques::before {
  content: "\e923";
}

.icon-pictos_univers-top_ventes::before {
  content: "\e924";
}

.icon-plus::before {
  content: "\e925";
}

.icon-produits_similaires::before {
  content: "\e926";
}

.icon-user::before {
  content: "\e927";
}

.icon-radio-false::before {
  content: "\e928";
}

.icon-radio-true::before {
  content: "\e929";
}

.icon-rdv_conseil::before {
  content: "\e92a";
}

.icon-refresh::before {
  content: "\e92b";
}

.icon-Rssurances-footer-e-resa::before {
  content: "\e92c";
}

.icon-Rssurances-footer-etrait-magasin::before {
  content: "\e92d";
}

.icon-Rssurances-footer-livraison_et_installation::before {
  content: "\e92f";
}

.icon-Rssurances-footer-sav_reactif::before {
  content: "\e930";
}

.icon-retour_colis::before {
  content: "\e931";
}

.icon-sav1::before {
  color: #464747;
  content: "\e932";
}

.icon-sav2::before {
  content: "\e933";
}

.icon-search::before {
  content: "\e934";
}

.icon-shopping_cart_plein::before {
  content: "\e935";
}

.icon-shopping_cart_vide::before {
  content: "\e936";
}

.icon-smiley_triste::before {
  content: "\e937";
}

.icon-switch-desabled .path1::before {
  color: rgb(231, 231, 231);
  content: "\e938";
}

.icon-switch-desabled .path2::before {
  color: rgb(255, 255, 255);
  content: "\e939";
  margin-left: -1em;
}

.icon-switch-false .path1::before {
  color: rgb(119, 119, 119);
  content: "\e93a";
}

.icon-switch-false .path2::before {
  color: rgb(255, 255, 255);
  content: "\e93b";
  margin-left: -1em;
}

.icon-switch-true .path1::before {
  color: rgb(85, 192, 122);
  content: "\e93c";
}

.icon-switch-true .path2::before {
  color: rgb(255, 255, 255);
  content: "\e93d";
  margin-left: -1em;
}

.icon-download::before {
  content: "\e93e";
}

.icon-union::before {
  content: "\e93f";
}

.icon-alert::before {
  content: "\e940";
}

.icon-facebook::before {
  content: "\e92e";
}

.icon-linkedin::before {
  content: "\e941";
}

.icon-bin::before {
  content: "\e947";
}

.icon-reassurance-check::before {
  content: "\e948";
}

/* stylelint-enable */

.font-primary {
  font-family: default(font-family);
  font-weight: default(weight);
}

.font-primary-medium {
  font-family: default(font-family);
  font-weight: default(weight, medium);
}

.font-secondary {
  font-family: default(font-family, secondary);
}

.style-title {
  font-family: inherit;
  font-weight: default(weight, medium);
}

.h1,
h1 {
  @include heading(color(text), default(font-size, mh1), 15, false, false);
  font-family: default(font-family, secondary);
  font-weight: default(weight, medium);
  line-height: 1.4;
  margin-top: 0;

  @media (min-width: $sm-width) {
    @include heading(color(text), default(font-size, h1), 15, false, false);
  }
}

.h2,
h2 {
  font-family: default(font-family, secondary);
  font-weight: default(weight, medium);
  line-height: 2.8rem;
  margin-top: 0;

  @include heading(color(text), default(font-size, mh2), 15, false, false);

  @media (min-width: $sm-width) {
    @include heading(color(text), default(font-size, h2), 15, false, false);
    line-height: 4rem;
  }
  @media (min-width: $lg-width) {
    margin-bottom: 3rem;
  }
}

.h3,
h3 {
  font-family: default(font-family);
  font-weight: default(weight, medium);
  margin-top: 0;

  @include heading(color(text), default(font-size, mh3), 15, false, false);

  @media (min-width: $sm-width) {
    @include heading(color(text), default(font-size, h3), 15, false, false);
  }
}

.h3-secondary {
  font-family: default(font-family, secondary);
  font-weight: default(weight, medium);
  margin-top: 0;

  @include heading(color(text), 1.8rem, 15, false, false);

  @media (min-width: $sm-width) {
    @include heading(color(text), 2.4rem, 15, false, false);
  }
}

.h4,
h4 {
  font-family: default(font-family, secondary);
  font-weight: default(weight, medium);
  margin-top: 0;

  @include heading(color(text), default(font-size), 10, false, false);
}

.h5,
h5 {
  font-family: default(font-family, secondary);
  margin-top: 0;

  @include heading(color(text), default(font-size), 10, false, false);
}

a {
  color: inherit;
  cursor: pointer;

  @include animated;

  &:hover,
  &:active,
  &:focus {
    color: color(primary);
  }
}

strong,
b {
  font-weight: default(weight, medium);
}

.barre {
  text-decoration: line-through;
}

.body-regular {
  font-size: 1.6rem;
}

.body-large {
  font-size: 1.8rem;
}

.body-small {
  font-size: 1.4rem;
}

.body-xs {
  font-size: 1.2rem;
}

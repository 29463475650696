.oneline-item {
  flex-direction: column;
  @media (min-width: $md-width) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &__img {
    flex-shrink: 0;
    width: 100%;
    padding: 0 0 3rem 0;
    border-bottom: 1px solid color(border, medium);
    @media (min-width: $md-width) {
      padding: 0 3rem 0 0;
      border-bottom: none;
      border-right: 1px solid color(border, medium);
      width: 150px;
    }
    img {
      max-width: 150px;
      @media (min-width: $md-width) {
        max-width: 100%;
      }
    }
  }
  &__content {
    padding: 3rem 0 0 0;
    @media (min-width: $md-width) {
      padding: 0 0 0 3rem;
    }
  }
  &__title {
    font-size: 1.8rem;
    font-weight: default(weight, medium);
    font-family: default(font-family);
    @media (min-width: $md-width) {
      font-size: 2.4rem;
    }
  }
  &__subtitle {
    font-size: 1.4rem;
    font-weight: default(weight, medium);
    font-family: default(font-family);
    @media (min-width: $md-width) {
      font-size: 1.8rem;
    }
  }
  &__cta {
    flex-shrink: 0;
    width: 100%;
    @media (min-width: $md-width) {
      padding-left: 2rem;
      width: auto;
    }

    .btn {
      width: 100%;
      @media (min-width: $md-width) {
        width: 290px;
      }
    }
  }
}

[data-layout-rbs-simpleform-form] {
  .simpleform-header {
    background-color: color(grey, light);
    padding: 5rem 0 3rem;
    text-align: center;

    @extend .ctn-fluid;

    @media (min-width: $md-width) {
      padding: 10rem 0 5rem;
    }

    h1 {
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.form-control {
  border-color: color(grey, medium);
  border-radius: default(radius, input);
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.form-group {
  margin-bottom: 15px;

  @media (min-width: $sm-width) {
    margin-bottom: 20px;
  }

  label {
    @include rem(14);
    color: color(text, dark);
    display: inline-block;
    font-weight: default(weight);
    line-height: 1.4;

    @media (min-width: $xs-width) {
      margin-bottom: 10px;
    }
  }

  .form-control {
    @include placeholder {
      color: color(text, dark);
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.01rem;
    }
    background-color: color(background, light);
    border: 2px solid color(grey, medium);
    border-radius: default(radius, input);
    box-shadow: none;
    color: color(text);
    height: 5rem;
    padding: 0 15px;
    width: 100%;
    z-index: 1;
  }

  .text-danger {
    font-size: 1.4rem;
    margin-top: 0.3rem;
  }

  textarea.form-control {
    min-height: 12rem;
    padding-bottom: 1.5rem;
    padding-top: 2rem;
  }

  &.password-change {
    > label {
      height: auto;

      @media (min-width: $md-width) {
        height: 45px;
      }
    }
  }

  &.password-valid {
    margin-top: 20px;
  }

  &.form-ok .form-control {
    border-color: color(alert, success);
    color: color(alert, success);
  }

  &.form-error,
  &.has-error {
    label {
      color: inherit;
    }

    .form-control {
      border-color: color(alert, danger);

      //color: color(alert, danger);
    }
  }
}

.custom-select {
  position: relative;

  select {
    display: none;

    &.form-control {
      display: none;
    }
  }

  > label {
    color: color(mono, dark);
    display: block;
    margin-bottom: 7px;
    text-align: left;
    text-transform: uppercase;

    @include rem(12);

    @media (min-width: $xs-width) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm-width) {
      font-size: default(font-size);
    }
    @media (min-width: $md-width) {
      font-size: default(font-size);
    }
  }

  > span {
    background-color: color(background, light);
    border: 2px solid color(grey, medium);
    border-radius: default(radius, input);
    color: color(text, dark);
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    height: 5rem;
    line-height: 4.8rem;
    overflow: hidden;
    padding: 0.7rem 5rem 0 1.5rem !important;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 4;

    &::after {
      color: color(text, dark);
      content: "\e905";
      cursor: pointer;
      display: block;
      font-family: default(font-family, icon);
      font-size: 2rem;
      font-style: normal;
      line-height: 1;
      margin-top: -10px;
      opacity: 100%;
      position: absolute;
      right: 1.2rem;
      top: 50%;
      transform-origin: 10px 9px 0;
      transition: all 0.2s ease 0s;
    }

    &.open {
      border-color: color(grey, dark);

      &::after {
        opacity: 100%;
        transform: rotate(180deg);
      }
    }
  }

  > ul {
    background-color: color(background, light);
    border: 1px solid color(grey, medium);
    border-radius: 0 0 default(radius, input) default(radius, input);
    display: none;
    margin: -1px 0 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    z-index: 10;

    li {
      color: color(text, dark);
      cursor: pointer;
      font-size: 14px;
      padding: 12px 10px;

      input {
        height: 30px;
        width: 100%;
      }

      &:hover,
      &.selected {
        background-color: color(background, medium);
      }
    }

    &.open {
      display: block;
    }
  }

  &.disabled > span {
    cursor: not-allowed;
    opacity: 50%;
  }

  &.disabled > label .label {
    cursor: not-allowed;
    opacity: 50%;
  }

  & > select {
    display: none;
  }

  &.form-control {
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0 !important;
  }
}

.custom-checkbox {
  overflow: hidden;
  position: relative;

  label {
    cursor: pointer;
    display: block;
    font-weight: default(weight);
    margin-bottom: 0;
  }

  input {
    box-sizing: border-box;
    cursor: pointer;
    height: 25px;
    left: 0;
    margin: 0;
    opacity: 0%;
    padding: 0;
    position: absolute;
    top: 0;
    width: 25px;
    z-index: 3;
  }

  &::before {
    color: color(primary);
    content: "\e903";
    cursor: pointer;
    display: block;
    font-family: default(font-family, icon);
    font-size: 1.6rem;
    height: 1.6rem;
    line-height: 2.3rem;
    position: relative;
    text-align: center;
    top: 0;
    transition: 0.2s ease;
    width: 1.6rem;
    z-index: 1;
  }

  &.checked::before {
    content: "\e904";
  }

  &.inline {
    &::before {
      display: inline-block;
      vertical-align: top;
    }

    label {
      color: #010102;
      display: inline-block;
      letter-spacing: 0;
      max-width: 85%;
      padding-top: 1px;
      text-transform: none;

      @include rem(13);

      @media (min-width: $xs-width) {
        font-size: default(font-size);
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 50%;

    &::before {
      cursor: not-allowed;
    }
  }
}

.custom-radio {
  clear: both;
  padding: 2px 0 0 1.6rem;
  position: relative;

  label {
    color: color(text);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    text-transform: none;
    vertical-align: middle;

    small {
      display: block;
      font-size: 12px;
    }
  }

  input {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    color: color(primary);
    content: "\e928";
    cursor: pointer;
    display: block;
    font-family: default(font-family, icon);
    font-size: 1.6rem;
    height: 1.6rem;
    left: 0;
    line-height: 1.6rem;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1.6rem;
  }

  &:active {
    &::before {
      color: color(secondary);
    }
  }

  &.checked::before {
    content: "\e929";
  }

  &.inline {
    label {
      margin-bottom: 0;
      max-width: 100%;
    }

    &::before {
      cursor: pointer;
      display: inline-block;
      text-transform: none;
      vertical-align: middle;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 50%;

    &::before {
      cursor: not-allowed;
    }
  }
}

// CUSTOM FILE (CONTACT PAGE)
.custom-file {
  align-items: center;
  display: flex;
  position: relative;

  input {
    height: 35px;
    left: 0;
    opacity: 0%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  label {
    display: none;
  }

  .filename {
    color: color(text);
    overflow: hidden;
    padding-left: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .action {
    @extend .btn;
    @extend .btn-secondary;
    cursor: pointer;
    min-width: 15rem;
    order: -1;
    text-align: center;
    white-space: nowrap;

    @media (max-width: $xs-width-max) {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
    @media (min-width: $xs-width) {
      flex-basis: auto;
    }
  }
}

/*************** surcharge bootstrap **************/
.checkbox,
.radio {
  label {
    font-size: 1.4rem;
    min-height: 1.6rem;
    padding-left: 2.5rem !important;
    position: relative;

    .required-symbol {
      float: right;
      margin-left: 1rem;
    }

    &:hover {
      .custom-checkbox,
      .custom-radio {
        &:not(.checked)::before {
          color: color(secondary);
        }
      }
    }
  }

  .custom-checkbox,
  .custom-radio {
    bottom: 0;
    float: none;
    left: 0;
    margin-top: 0;
    min-width: 15px;
    overflow: visible;
    position: absolute;
    top: -2px;

    a {
      text-decoration: underline;
    }

    input {
      margin-left: 0;
    }
  }

  &.side-label {
    label {
      padding-left: 2rem !important;
    }

    .custom-checkbox,
    .custom-radio {
      position: static;
    }
  }

  &.small {
    .custom-checkbox {
      top: 0;

      &::before {
        height: 1.5rem;
        line-height: 1.3rem;
        width: 1.5rem;
      }

      input {
        height: 15px;
        width: 15px;
      }
    }

    label {
      font-size: 1.2rem;
      min-height: auto;
      padding-left: 2.5rem;
    }
  }
}

.form-inline {
  .checkbox,
  .radio {
    label {
      padding-left: 3.5rem;
      position: relative;
    }
  }

  .custom-checkbox {
    input[type="checkbox"] {
      position: absolute;
    }
  }
}

.form-horizontal {
  .form-group {
    margin-left: 0;
    margin-right: 0;

    &::before,
    &::after {
      content: none;
    }
  }

  .control-label {
    flex-basis: auto;
    margin-bottom: 1rem;
    max-width: 100%;
    padding: 0;
    text-align: left;
    width: auto;
  }

  .col-sm-8,
  .col-md-9,
  .col-sm-4,
  .col-md-5 {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
    width: 100%;

    img {
      margin-top: 1rem;
    }
  }

  .col-sm-offset-4 {
    margin-left: 0;
  }
}

.form-ctrl-ctn {
  position: relative;
  text-align: left;

  > .col-sm-8 {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  .control-label,
  label {
    @include labelAnimated;

    .required-symbol {
      color: color(text, dark) !important;
      float: right;
      margin-left: 0.4rem;
    }
  }

  &.form-ctrl-select-ctn {
    label {
      z-index: 5;
    }
  }

  &.form-ctrl-tel-ctn {
    .control-label,
    label {
      left: 7.4rem;
      max-width: calc(100% - 7.4rem);
    }

    .input-group {
      width: 100%;

      .form-control {
        @include placeholder {
          color: color(background, light);
          font-style: normal;
          font-weight: normal;
          letter-spacing: 0.01rem;
        }
      }

      &-btn {
        width: 7.4rem;

        .btn {
          width: 100%;

          &.dropdown-toggle {
            .caret {
              border: none;
              font-size: 1.2rem;
              height: 1.2rem;
              margin-left: 1rem;
              width: auto;

              &::before {
                color: color(grey);
                content: icon(arrow-down);
                font-family: default(font-family, icon);
              }
            }
          }
        }
      }
    }
  }

  .form-control {
    text-indent: -1000px;

    &:placeholder-shown {
      color: color(text, dark);
      font-style: italic;
      text-transform: none;
    }
  }

  input:-internal-autofill-selected,
  input:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset;
  }

  /* focused */
  &.focused {
    .control-label,
    label {
      @include labelFocused;
    }

    input:-internal-autofill-selected {
      box-shadow: none;
    }

    .form-control {
      box-shadow: none;
      padding-top: 1.5rem;
      text-indent: 0;
    }

    .form-ctrl-select-ctn {
      .custom-select > span {
        line-height: 3.5rem;
        padding-top: 1.5rem !important;
      }
    }

    textarea {
      &.form-control {
        padding-top: 2rem;
      }
    }
  }
}

[data-rbs-date-picker] {
  .btn {
    @extend .btn-small;
  }
}

.input-group-btn:first-child {
  width: 7.4rem;

  > .btn,
  > .btn-group {
    border: none;
    border-radius: 4px !important;
    box-shadow: none;
    margin-right: -1px;
    padding: 6px 10px;
  }

  > .btn {
    background-color: color(background, light) !important;
    border: 2px solid color(grey, medium) !important;
    border-bottom-left-radius: default(radius, input) !important;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
    border-top-left-radius: default(radius, input) !important;
    border-top-right-radius: 0 !important;
    height: 5rem;
    line-height: normal;
    margin-bottom: -1px;
    margin-right: -2px;
    margin-top: -1px;
  }
}

.required-fields {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

// static
.form-control-static {
  background-color: color(background, light);
  border-radius: default(radius);
  cursor: not-allowed;
  height: 5rem;
  margin-bottom: 0;
  min-height: 34px;
  opacity: 50%;
  padding-bottom: 7px;
  padding-left: 1.5rem;
  padding-top: 2rem;
}

[data-rbs-user-password-toggle="allowShowPassword"],
[data-rbs-user-password-toggle="parameters.allowShowPassword"] {
  .input-group {
    width: 100%;

    .form-control {
      border-radius: default(radius, medium);
    }
  }
  .input-group-btn {
    position: absolute;
    display: block;
    right: 2rem;
    width: auto;
  }
  .btn {
    background-color: color(background, light);
    border: none;
    border-top: 2px solid color(border, medium);
    border-bottom: 2px solid color(border, medium);
    height: 5rem;
    position: absolute;
    right: 0;
    border-radius: 0 !important;
    padding: 1rem !important;
    font-size: 2rem;

    &:hover {
      color: color(primary);
    }

    &:active:focus {
      border: none;
      border-top: 2px solid color(border, medium);
      border-bottom: 2px solid color(border, medium);
    }

    .glyphicon {
      font-family: "icomoon";

      &-eye-close {
        &::before {
          content: "\e94f";
        }
      }

      &-eye-open {
        &::before {
          content: "\e950";
        }
      }
    }
  }
}

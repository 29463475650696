// mode-selector = block shipping or collection
.mode-selector {
  &:not(:first-child) {
    border-top: 1px solid color(border, medium);
  }

  // mode-selector-card = radio-box
  &-card {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem 0;
    position: relative;

    @media (min-width: $sm-width) {
      padding: 2rem;
    }

    // click on all radio box :
    .mode-selector-content,
    .custom-radio {
      position: static;

      input {
        @include poa(0, 0, 0, 0);
        z-index: 1;
      }

      &::before {
        bottom: 0;
        left: 1.5rem;
        margin: auto;
        top: 0;

        @media (min-width: $sm-width) {
          left: 3rem;
        }
      }
    }

    // label padding when summary without edition
    .mode-selector-form.ng-hide ~ .mode-selector-label {
      margin-left: 0;

      label {
        padding-left: 0 !important;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .custom-radio {
      z-index: 1000;
    }
  }

  &-label {
    @media (min-width: $sm-width) {
      display: inline-block;
      margin-left: 2rem;
      margin-top: 0.5rem;
      vertical-align: top;
    }
  }

  // img transporteur
  &-icon {
    height: 3rem;

    @media (min-width: $sm-width) {
      display: inline-block;
      margin-left: 2rem;
    }

    img {
      height: 100%;
    }
  }

  &-prices {
    margin-top: 1rem;

    @media (min-width: $sm-width) {
      margin-top: 1.5rem;
    }
  }

  // mode-selector-options = what appears under radio-box
  &-options {
    padding: 0 2rem 2rem;
    position: relative;
    @media (min-width: $md-width) {
      padding: 0 2rem 3rem;
    }

    > div {
      display: flex;
      flex-direction: column;

      [data-rbs-commerce-shipping-mode-gift] {
        order: 1;

        > div {
          border-bottom: 1px solid color(border);
          margin-bottom: 30px !important;
        }

        @media (min-width: $sm-width) {
          margin-left: 35px;
        }
      }

      > .one-line-bottom-margin {
        order: 2;
      }

      > .half-line-bottom-margin {
        order: 3;
      }
    }

    .form-inline,
    .form-horizontal,
    .help-block {
      margin: auto;
      max-width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: width(ctn, sm);

      .form-group,
      .btn-primary {
        display: block;
        margin-bottom: 1.5rem;
      }

      .btn-primary {
        width: 100%;
      }

      @media (min-width: $sm-width) {
        padding-left: 35px;
      }
    }

    .help-block {
      margin-bottom: 1.5rem;
    }
  }

  [data-rbs-commerce-shipping-mode-gift] {
    margin-top: 3rem;

    .form-inline {
      max-width: none !important;
      padding-left: 0 !important;
    }
  }
}
.doofinder-search {
  border: 0;
  border: 1px solid color(primary);
  border-radius: default(radius, input) !important;
  color: color(text);
  height: 4rem;
  padding: 0 5rem 0 2rem;
  position: relative;
  width: 100%;

  &::placeholder {
    color: color(text);
  }
}

[data-layout-project-doofinder-doofinder] {
  .input-group {
    display: block;
    position: relative;
    z-index: 2;

    &-btn {
      color: color(text);
      display: inline-block;
      font-size: 1.8rem;
      position: absolute;
      right: 2rem;
      top: 9px;
      width: auto;
      z-index: 1511;

      .glyphicon {
        font-family: default(font-family, icon);

        &::before {
          content: icon(search);
        }
      }
    }

    @media (min-width: $md-width) {
      margin-top: 0.6rem;
    }
  }
}

.search-open {
  .search-doofinder-ctn {
    @media (min-width: $sm-width) {
      left: 4rem;
      position: absolute;
      right: 4rem;
      top: 5rem;
    }
    @media (min-width: $md-width) {
      left: 12rem;
      right: 12rem;
    }
    @media (min-width: $xxl-width) {
      left: 31rem;
      right: 31rem;
    }
  }
}

// mobile
.df-mobile {
  &__header {
    background-color: color(background, light) !important;
    padding: 3rem 2rem 0 !important;

    .df-mobile__searchbox {
      @extend .doofinder-search;
      box-shadow: none !important;
      max-width: calc(100% - 5rem);

      input {
        background: transparent !important;
        box-shadow: none;
        font-size: 1.4rem !important;
        height: 100% !important;
      }

      button {
        background: transparent !important;
        height: 100% !important;
      }

      .df-mobile__searchbox__loupe {
        fill: color(primary);
        order: 2;

        /*  &::before {
          content: icon(search);
          font-family: default(font-family, icon);
        } */
      }

      [data-role="voicesearch"] {
        display: none !important;
      }

      [data-role="close"],
      [data-role="clear"] {
        @extend .df-icon--close;
        font-size: 0;
        height: 100%;
        position: absolute;
        right: -6rem;
      }
    }
  }
  .df-mobile__suggestions {
    flex-wrap: wrap !important;
    margin-top: 3rem;
  }

  .df-suggestion-label {
    min-width: 100%;
    flex-grow: 1;
    flex-basis: 100%;
  }
}

// desktop
.df-classic {
  font-family: default(font-family);

  .df-main {
    padding-bottom: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .df-suggestions-list {
    margin-top: 3rem;
  }

  @media (min-width: $sm-width) {
    overflow: auto;
    padding-top: 9rem !important;
    z-index: 1 !important;

    .df-main {
      padding-bottom: 8rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }

    .df-results {
      overflow: visible !important;
    }

    .df-icon--close {
      right: 5rem !important;
      top: 6rem !important;
    }
  }
  @media (min-width: $md-width) {
    padding-top: 14rem !important;

    .df-main {
      padding-bottom: 8rem;
      padding-left: 12rem;
      padding-right: 12rem;
    }
  }
  @media (min-width: $xxl-width) {
    .df-main {
      padding-bottom: 8rem;
      padding-left: 31rem;
      padding-right: 31rem;
    }
  }
}

// mobile / desktop
.df-classic,
.df-mobile {
  @include poa(0, 0, 0, 0);
  background-color: color(grey, light) !important;
  border: 0;
  border-radius: default(radius);
  font-family: inherit;
  left: auto !important;
  min-height: auto !important;
  width: 100% !important;

  .df-layer__content {
    flex-direction: row !important;
  }

  .df-term[data-selected] {
    background-color: color(primary) !important;
    border-color: color(primary) !important;

    &:hover,
    &:active,
    &:focus {
      background-color: color(primary) !important;
      border-color: color(primary) !important;
    }
  }

  .full-grid {
    grid-column: 1;
    grid-column-end: -1;
    grid-column-start: 1;
    grid-row: 1;
  }

  .df-suggestions-list {
    border: 0 !important;

    .df-suggestion-label {
      font-size: 1.8rem;

      @media (min-width: $md-width) {
        display: inline-block !important;
        font-size: 2.4rem;
        margin-right: 2rem;
        margin-bottom: 1rem;
        vertical-align: middle;
      }
    }

    .df-suggestion {
      @media (min-width: $md-width) {
        margin-top: -1rem;
        vertical-align: middle;        
      }
    }
  }

  .df-pub-banner {
    margin-top: 3rem;
    width: 100%;

    @media (min-width: $md-width) {
      margin-top: 0;      
    }

    img {
      max-width: 100%;
      width: 100%;
      display: flex;
    }
  }

  .df-suggestion {
    padding: 0 !important;
    padding-right: 3rem !important;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
    }
  }

  .df-results,
  .df-mobile__content {
    font-family: default(font-family);
    grid-column-gap: 2.3rem !important;
    grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr)) !important;
    min-width: 28rem !important;
    padding: 0 2rem;
  }

  .products-list-title {
    margin: 3rem 0;
  }

  .df-mobile__content {
    background-color: transparent !important;
    padding: 0 2rem !important;
  }

  .product-visual {
    margin: 0;
  }

  .df-mobile__action-button {
    display: none !important;
  }

  .product-cashback-offers {
    flex-direction: column;
    display: none;

    @media (min-width: $xs-width) {
      display: flex;
    }

    .product-cashback-offers__item {
      .body-regular {
        font-size: 1.2rem;
        line-height: 1.4;
      }
    }

    &.mobile-only {
      display: flex;

      @media (min-width: $xs-width) {
        display: none;
      }
    }
  }
}

.df-icon--close {
  color: color(grey);
  font-family: default(font-family, icon);
  font-weight: normal;

  &::before {
    content: icon(close-round);
    font-size: 2rem;
  }

  svg {
    display: none;
  }
}

.df-slider .noUi-connect {
  background-color: color(primary);
}

.df-history,
.df-header,
.df-branding {
  display: none !important;
}

.df-suggestion-label {
  @extend .h2;
  display: block !important;
  padding: 0 !important;
}

.df-results {
  .thumbnail {
    .caption {
      margin-bottom: 1.5rem;
    }
  }
}

.df-card__pricing {
  .df-card__price {
    font-weight: default(weight, medium);
    font-size: 2.8rem;
    line-height: 1.2;

    &.df-card__price--new {
      color: inherit;
    }
  }

  .df-card__baseprice {
    font-size: 1.2rem;
    line-height: 1.4;

    .df-card__price {
      font-weight: default(weight);
      font-size: 1.8rem;

      &--old {
        color: color(tertiary);
      }
    }

    .tooltip-ctn {
      .icon-infos {
        font-size: 1.2rem;
        color: inherit;
      }
    }
  }
}

// confirmation

[data-layout-rbs-commerce-payment-return] {
  @extend .sm-ctn;
  @extend .white-box-padded;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 7rem auto;

  h4 {
    font-size: 1.4rem;
  }

  @media (min-width: $md-width) {
    margin: 7rem auto;
  }
  @media (min-width: $md-width) {
    margin-bottom: 15rem;
  }

  .panel-heading {
    margin-bottom: 1.5rem;
  }

  .panel-info {
    @extend .panel-default;
  }

  .panel-success {
    @extend .panel-default;
    margin: 0;
    order: 2;

    &:last-child {
      order: 1;

      .panel-heading {
        display: none;
      }
    }
  }

  .panel-body {
    font-weight: default(weight);
    line-height: 1.8rem;
  }

  [data-rbs-user-create-account] {
    .form-group .col-sm-offset-4 {
      margin: 0;

      span {
        display: block;
        margin-top: 1.5rem;
        text-align: right;
      }
    }
  }
}

.header-nav {
  @include animated(left);
  @include position(fixed, 0, null, null, 0);
  background: color(mono, light);
  height: 100%;
  left: -101%;
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
  width: 95%;
  z-index: 1999;

  @media (min-width: $xs-width) {
    width: 92%;
  }
  @media (min-width: $sm-width) {
    width: 90%;
  }
  @media (min-width: $md-width) {
    border: 0;
    min-height: auto;
    overflow: visible;
    padding-bottom: 0;
    position: static;
    width: 100%;
  }

  &.open {
    left: 0;
  }

  .navbar-action {
    align-items: center;
    margin-right: 2rem;
    z-index: 1;
  }

  &-inner {
    @media (min-width: $md-width) {
      background-color: color(mono, light);
      border-bottom: 1px solid color(border, medium);
      position: relative;
    }
    @media (min-width: $md-width) and (max-width: $xxxl-width-max) {
      padding: 0 2.25rem;
    }

    .navbar {
      background: color(mono, light);
      border: 0;
      border-radius: 0;
      height: 100%;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
      position: static;
      width: 100%;
      z-index: 3;

      @media (min-width: $md-width) {
        min-height: auto;
      }

      &-header {
        align-items: center;
        background-color: color(background);
        border-radius: 0;
        display: flex;
        float: none;
        justify-content: space-between;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
        width: 100%;

        @media (min-width: $md-width) {
          display: none;
        }

        &::before,
        &::after {
          content: none;
          display: none;
        }

        &-title {
          color: color(mono, light);
          font-size: 1.8rem;
          font-weight: default(weight, medium);
        }
      }

      &-close {
        padding: 0 1rem;

        .icon {
          color: color(mono, light);
          font-size: 1.2rem;
        }
      }

      &-content {
        @media (min-width: $md-width) {
          display: flex;
          justify-content: space-between;

          &::after {
            background-color: color(shadow, overlay);
            bottom: 0;
            content: "";
            display: none;
            left: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 199;
          }

          &.menu-open {
            &::after {
              display: block;
            }

            .navbar-root-item-content {
              z-index: 200;
            }
          }
        }
      }

      &-root-link {
        align-items: center;
        color: color(mono, dark);
        display: flex;
        font-size: 1.4rem;
        font-weight: default(weight, medium);
        height: 100%;
        justify-content: space-between;
        padding: 1rem 2rem;
        position: relative;
        transition: none;

        @media (min-width: $md-width) {
          display: inline-flex;
          max-width: 22rem;
          padding: 1rem 1.25rem;
        }

        &::after {
          @media (min-width: $md-width) {
            background-color: color(secondary);
            bottom: -1rem;
            content: "";
            height: 6px;
            left: 1.25rem;
            opacity: 0%;
            position: absolute;
            transition: all 0.2s ease;
            width: 0;
            z-index: 200;
          }
        }

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;

          &::after {
            opacity: 100%;
            width: calc(100% - 2.5rem);
          }
        }

        .icon {
          font-size: 1.2rem;

          @media (min-width: $md-width) {
            display: none;
          }
        }
      }

      &-root-item {
        @media (min-width: $md-width) {
          align-items: center;
          display: flex;
          flex-grow: 1;

          &:first-child {
            .navbar-root-item-content {
              border-top-left-radius: 1rem;
            }
          }

          &:last-child {
            flex-grow: 0;

            .navbar-root-item-content {
              border-top-right-radius: 1rem;
            }
          }
        }
        @media (min-width: $xxxl-width) {
          max-width: none;
        }

        &-content {
          height: 100%;

          @media (min-width: $md-width) {
            background-color: color(mono, light);
            padding: 1rem 0;
            width: 100%;
          }
        }

        .submenu {
          @include animated(right);
          @include position(absolute, 45px, null, null, 0);
          background: color(mono, light);
          height: calc(100% - 45px);
          left: auto;
          margin: 0;
          overflow-x: hidden;
          overflow-y: auto;
          padding: 2.5rem 2rem;
          pointer-events: none;
          right: -101%;
          width: 100%;
          z-index: 200;

          @media (min-width: $md-width) {
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            display: none;
            height: auto;
            left: calc($gutter-ctn - 1.25rem);
            max-height: calc(100vh - 22rem);
            padding: 4rem 2rem;
            pointer-events: all;
            position: absolute;
            top: 100%;
            width: calc((100% - calc($gutter-ctn * 2)) + 2.5rem);
          }
          @media (min-width: $lg-width) {
            padding: 4.5rem 6rem;
          }
          @media (min-width: $xxxl-width) {
            left: 3.5rem;
            margin-left: auto;
            margin-right: auto;
            max-width: calc(192rem - calc($gutter-ctn * 2));
            right: 3.5rem;
            width: auto;
          }

          &-back-title {
            align-items: center;
            color: color(text);
            cursor: pointer;
            display: flex;
            font-size: 1.6rem;
            justify-content: flex-start;
            position: relative;

            .icon {
              font-size: 1.6rem;
              margin-right: 1.5rem;
              transform: rotate(180deg);
            }
          }

          &-title {
            margin-bottom: 2rem;
          }

          &-item {
            @media (min-width: $md-width) {
              margin-bottom: 3rem;
            }

            &.open {
              > .submenu-link {
                padding-bottom: 1rem;

                &::after {
                  content: icon(arrow-right);
                }
              }

              .subsubmenu {
                pointer-events: auto;
                right: 0;
              }
            }

            &-back {
              font-size: 1.4rem;
              font-weight: default(weight, medium);
              margin-bottom: 2.5rem;

              @media (min-width: $md-width) {
                display: none;
              }

              .icon {
                font-size: 1.2rem;
                margin-right: 1rem;
              }
            }
          }

          &-link {
            align-items: center;
            color: color(text);
            display: flex;
            font-size: 1.4rem;
            font-weight: default(weight, medium);
            justify-content: space-between;
            line-height: 1.4;
            padding: 1rem 0;
            position: relative;
            transition: all 0.2s linear;

            @media (min-width: $md-width) {
              display: block;
              margin-bottom: 1rem;
              padding: 0;
            }

            &:hover,
            &:focus,
            &:active {
              text-decoration: none;
            }

            &.has-child {
              &::after {
                content: icon(arrow-right);
                display: inline-block;
                font-family: default(font-family, icon);
                font-size: 1.2rem;

                @media (min-width: $md-width) {
                  content: none;
                }
              }
            }
          }

          &-seeall {
            font-size: 1.4rem;
            font-weight: default(weight, medium);
            margin-bottom: 1rem;
            text-decoration: underline;

            @media (min-width: $md-width) {
              margin-bottom: 0;
            }
          }

          &-mea {
            display: none;

            @media (min-width: $md-width) {
              display: block;
            }

            &-item {
              display: block;
              line-height: 0;

              img {
                max-width: 100%;
              }
            }
          }
        }

        &.open {
          .submenu {
            overflow-y: auto;
            pointer-events: auto;
            right: 0;

            &.open-child {
              overflow: hidden;
            }

            /* right: 5%;

            @media (min-width: $xs-width) {
              right: 8%;
            }
            @media (min-width: $sm-width) {
              right: 0%;
            } */
          }
        }

        &.hover {
          .navbar-root-link {
            &::after {
              opacity: 100%;
              width: calc(100% - 2.5rem);
            }
          }

          .submenu {
            @media (min-width: $md-width) {
              display: block;
            }
          }
        }

        .subsubmenu {
          @include animated(right);
          @include position(absolute, 0, null, null, auto);
          background: color(mono, light);
          height: 100%;
          margin: 0;
          overflow: hidden;
          padding: 2.5rem 2rem;
          pointer-events: none;
          right: -101%;
          width: 100%;
          z-index: 200;

          /*   @media (min-width: $xs-width) {
            width: 92%;
          }
          @media (min-width: $sm-width) {
            width: 90%;
          } */
          @media (min-width: $md-width) {
            max-height: 100%;
            padding: 0;
            pointer-events: auto;
            position: static;
          }

          &-item-back {
            font-size: 1.4rem;
            font-weight: default(weight, medium);
            margin-bottom: 2.5rem;

            @media (min-width: $md-width) {
              display: none;
            }

            .icon {
              font-size: 1.2rem;
              margin-right: 1rem;
            }
          }

          &-seeall {
            font-size: 1.2rem;
            font-weight: default(weight, medium);
            text-decoration: underline;
          }

          .subsubmenu-item {
            line-height: 1;
          }

          &-link {
            color: color(text);
            display: inline-block;
            font-size: 1.2rem;
            line-height: 1.6;
            padding: 1rem 0;

            @media (min-width: $md-width) {
              margin-bottom: 2px;
              padding: 0;
            }
          }
        }

        &:nth-last-child(2) {
          @media (max-width: $md-width-max) {
            padding: 0 1rem;
          }

          .navbar-root-link {
            background-color: color(primary);
            border-radius: 1rem;
            color: color(mono, light);
            white-space: nowrap;

            @media (max-width: $md-width-max) {
              padding-left: 1rem;
              padding-right: 1rem;
            }

            &::after {
              content: none;
            }

            .icon {
              color: color(mono, light);
            }
          }

          @media (min-width: $md-width) {
            .navbar-root-link {
              height: auto;
            }

            .navbar-root-item-content {
              align-items: center;
              display: flex;
            }
          }
        }
      }
    }
  }

  &-mobile {
    @media (min-width: $md-width) {
      display: none;
    }

    &-inner {
      background-color: color(mono, light);
      border-bottom: 1px solid color(border, medium);
      padding: 0 1rem;
      position: relative;

      .navbar {
        background: color(mono, light);
        border: 0;
        height: 100%;
        margin: 0;
        min-height: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: static;
        width: 100%;
        z-index: 3;

        &::after,
        &::before {
          content: none;
        }
      }

      .navbar-header {
        display: none;
      }

      .navbar-content {
        display: flex;
        justify-content: flex-start;
        overflow: auto;
      }

      .navbar-root-item {
        align-items: center;
        display: flex;
      }

      .navbar-root-link {
        align-items: center;
        color: color(text);
        display: flex;
        font-size: 1.4rem;
        font-weight: default(weight, medium);
        height: 100%;
        padding: 1rem;
        position: relative;
        transition: none;
        white-space: nowrap;

        &::after {
          background-color: color(secondary);
          bottom: 0;
          content: "";
          height: 6px;
          left: 1rem;
          opacity: 0%;
          position: absolute;
          transition: all 0.2s ease;
          width: 0;
          z-index: 200;
        }

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;

          &::after {
            opacity: 100%;
            width: calc(100% - 2rem);
          }
        }
      }
    }
  }

  &-cta {
    margin-top: 3rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }

    ul {
      margin-left: 0;

      @media (min-width: $md-width) {
        display: flex;
        flex-wrap: nowrap;
      }
    }

    li {
      display: block;
      padding: 0 1.5rem;

      @media (min-width: $md-width) {
        display: inline-block;
      }
      @media (min-width: $lg-width) {
        padding: 0 2.5rem;
      }
    }

    a {
      @extend .btn;
      @extend .btn-ternary;
      margin-bottom: 1rem;
      padding: 1rem 2rem;
      white-space: nowrap;

      @media (min-width: $md-width) {
        margin-bottom: 0;
      }

      &::after {
        content: none;
      }
    }
  }

  &-utils {
    margin-top: 3rem;

    @media (min-width: $md-width) {
      display: none;
    }

    > div {
      display: block !important;
      padding: 0 1.5rem 1.5rem;

      a {
        align-items: center;
        display: flex;
      }

      .title {
        display: inline-block !important;
      }

      .icon,
      .glyphicon {
        color: color(primary);
        display: inline-block;
        font-family: default(font-family, icon);
        font-size: 2.2rem;
        margin-right: 1rem;
      }
    }

    [data-name="Rbs_Storeshipping_ShortStore"] {
      .glyphicon::before {
        content: icon(store);
      }
    }

    [data-name="Rbs_User_AccountShort"] .glyphicon::before {
      content: icon(account);
    }
  }

  &-submenu {
    @media (min-width: $md-width) {
      display: none;
    }

    .navbar-header {
      display: none;
    }

    .navbar-root-link {
      color: color(grey, dark);
    }
  }
}

.navbar-overlay {
  background-color: color(shadow, overlay);
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  @media (min-width: $md-width) {
    display: none;
  }
}

.dropdown-backdrop {
  background-color: color(shadow, overlay);
}

.dropdown-cart {
  background-color: color(background, light);
  border-radius: 1rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 15%);
  font-weight: default(weight);
  margin-top: 1rem;
  min-width: 38rem;
  padding: 2rem;
  right: -2rem;

  // price
  .dl-inline-items + strong {
    font-size: 1.8rem;
    position: absolute;
    right: 2rem;
  }

  &::before {
    border-bottom: 9px solid color(background, light);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    height: 0.9rem;
    position: absolute;
    right: 1.8rem;
    top: -9px;
    width: 2rem;
  }

  h3 {
    display: none;
  }

  .media {
    background-color: color(mono, light);
    border-radius: 1rem;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 5%);
    margin: 0;
    margin-bottom: 2rem;
    padding: 2rem 1.5rem;
    position: relative;

    &-heading {
      font-family: default(font-family);
      font-size: 1.8rem;
      font-weight: default(weight);
      margin-bottom: 1rem;
    }

    &-left {
      a {
        padding: 0 !important;
      }
    }

    &-body {
      padding-right: 1.5rem;

      .btn-default {
        border: 0;
        font-size: 0;
        margin-top: 0;
        padding: 0;

        &:hover {
          background-color: transparent;
        }

        .glyphicon {
          font-size: 2rem;
          line-height: 1.2;
          margin: 0;

          &.glyphicon-remove::before {
            content: icon(trash);
            font-family: default(font-family, icon);

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  .dropdown-menu-footer {
    background-color: color(mono, light);
    border-radius: 1rem;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 5%);
    padding: 2rem 1.5rem;

    li {
      strong {
        display: flex;
        font-size: 1.8rem;
        font-weight: default(weight, medium);
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .dl-inline-items {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;

    dd,
    dt {
      margin-bottom: 5px;
      width: 50%;
    }
  }

  .btn-primary {
    display: block;
    justify-content: center;
    margin-top: 2rem;
    padding: 1rem 3rem 0.9rem !important;

    @media (min-width: $md-width) {
      padding: 1.4rem 3rem 1.3rem !important;
    }
  }
}

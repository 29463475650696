#trackers-ask-consent-gdpr {
  @extend .ctn-fluid;
  align-items: center;
  background-color: color(mono, light);
  border: none;
  bottom: 0;
  box-shadow: 0 0 10px color(shadow, overlay);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  padding: 2rem;
  position: fixed;
  width: 100%;
  z-index: 999999999;

  @media (max-width: $md-width-max) {
    flex-wrap: wrap;
    width: 100%;
  }
  @media (max-width: $xs-width-max) {
    text-align: center;
  }

  .richtext {
    color: color(text);
    margin: 0 15px;
    margin-bottom: 10px;
    max-width: 100%;
    text-align: center;
    width: 100%;

    @media (min-width: $md-width-max) {
      flex-basis: auto;
      margin-bottom: 0;
      max-width: 40%;
      text-align: left;
      width: auto;
    }
    @media (min-width: $lg-width-max) {
      flex-basis: auto;
      max-width: 50%;
    }
  }

  a {
    margin: 0 5px;
    text-decoration: underline;

    @media (max-width: 500px) {
      display: block;
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
    }
  }

  .btn {
    @extend .btn-small;
    margin: 0 1rem;

    .glyphicon {
      display: none;
    }

    &-default + .btn-default {
      background: color(button);
      border: 1px solid color(button);
      color: color(text, light);

      @media (hover: hover) {
        &:hover {
          background-color: color(button, light);
        }
      }
    }

    @media (max-width: $xs-width-max) {
      display: block;
      margin: 0 5px 5px;
      width: 100%;
    }
  }

  .btn-link {
    background: transparent !important;
    border: 0;
    padding: 0;

    @media (max-width: $xs-width-max) {
      display: inline-block;
      margin-bottom: 1.5rem;
      width: auto;
    }

    &:hover,
    &:focus {
      color: color(text);
    }
  }
}

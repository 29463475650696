.product-detail-wishlist {
  height: 0;

  .content {
    @include poa(7rem, 0, null, null);
    padding-right: 1rem;
    text-align: right;
    z-index: 2;

    @media (min-width: $sm-width) {
      @include poa(9rem, 58.333%, null, null);
    }

    .btn-group {
      .btn {
        border: none;
        position: relative;

        .icon {
          margin-right: 0;
        }
      }
    }

    @media (min-width: $sm-width) {
      padding-right: 3.5rem;
    }
  }

  .text-danger,
  .text-success {
    bottom: 0;
    font-size: 1.4rem;
    left: 20px;
    padding-right: 0.5rem;
    position: absolute;

    @media (min-width: $sm-width) {
      left: auto;
      padding-right: 0;
      right: 40px;
    }
  }

  .modal-footer {
    .text-danger,
    .text-success {
      bottom: auto;
      font-size: 1.4rem;
      left: auto;
      margin-bottom: 2rem;
      padding-right: 0;
      position: static;
      right: auto;
      text-align: center;
    }
  }

  .btn-group {
    padding: 1rem 0;

    @media (min-width: $sm-width) {
      padding: 2rem 0;
    }

    .btn {
      align-items: center;
      background-color: transparent;
      border-radius: 0 !important;
      box-shadow: none !important;
      color: color(text);
      display: flex;
      font-size: 0;
      font-weight: default(weight);
      padding: 0;

      @media (min-width: $md-width) {
        font-size: 1.6rem;
      }

      &::before {
        color: color(secondary);
        content: icon(fav);
        display: inline-block;
        font-family: default(font-family, icon);
        font-size: 2rem;

        @media (min-width: $md-width) {
          margin-right: 1rem;
        }
      }
    }

    .dropdown-toggle {
      display: none;

      @media (min-width: $md-width) {
        display: flex;
      }

      .caret {
        display: none;
      }

      &:focus,
      &:hover,
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }

      &::before {
        color: color(text);
        content: icon(dropdown);
        display: inline-block;
        font-family: default(font-family, icon);
        font-size: 1.4rem;
        margin-left: 1rem;
        margin-right: 0;
        margin-top: 3px;
      }
    }

    .dropdown-menu {
      left: auto;
      right: 4.5rem;
    }
  }
}

[data-layout-rbs-catalog-product-detail] {
  // @extend .ctn-fluid;

  .product-details {
    margin: 2.5rem auto 0;

    @media (min-width: $sm-width) {
      margin: 5rem auto 0;
      padding-bottom: 6rem;
    }

    .product-header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &__info {
        &__title {
          margin: 0;

          span {
            display: block;
          }
        }

        &__ref {
          font-family: inherit;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: inherit;
        }

        &__reviews {
          .star-rating {
            span:not(.text-muted) {
              .glyphicon-star {
                color: color(quaternary);
              }
            }

            span.text-muted {
              .glyphicon-star {
                color: color(background, dark);
              }
            }
          }
        }
      }

      &__brand-image {
        img {
          max-width: 100%;
        }
      }
    }

    .inversion-container {
      display: flex;
      flex-direction: column-reverse;

      @media (min-width: $sm-width) {
        flex-direction: column;
      }
    }

    .product-features {
      margin-bottom: 4rem;
      margin-top: 2rem;

      @media (min-width: $sm-width) {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 2rem;
        margin-top: 0;
      }

      &__items,
      &__buttons {
        @media (min-width: $sm-width) {
          width: 50%;
        }
      }

      &__items {
        font-size: 1.4rem;
      }

      &__item {
        margin-bottom: 0.5rem;
      }

      &__buttons {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        @media (min-width: $sm-width) {
          padding-left: 2rem;
        }

        .btn {
          display: block;
          font-size: 1.4rem;
          margin-bottom: 1rem;
          margin-top: 2rem;
          text-align: left;
          text-decoration: underline;

          @media (min-width: $sm-width) {
            margin-top: 0;
          }

          &::after {
            display: none;
          }
        }

        .pdf {
          text-decoration: none;

          .icon {
            font-size: 2rem;
            margin-right: 0.5rem;
          }
        }

        .pdf:nth-child(2) {
          margin-top: 2rem;
        }
      }
    }

    .product-variants {
      padding: 1rem 3rem;

      &__content {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin: 0 !important;
      }

      &__label {
        color: color(text);
        margin: 0;
      }

      &__axes {
        margin-left: 1rem;
      }
    }

    .product-promo-code {
      border: 2px solid color(tertiary);
      border-radius: default(radius, medium);
      color: color(tertiary);
      margin-bottom: 2rem;
      padding: 0.5rem 0;
      text-align: center;
    }

    .cart-box {
      &__content {
        @media (min-width: $sm-width) {
          display: flex;
          justify-content: flex-start;
        }

        &__description {
          @media (min-width: $sm-width) {
            border-right: 2px solid color(border, medium);
            padding-right: 2rem;
            width: 50%;
          }

          &__shipping {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;

            @media (min-width: $sm-width) {
              display: block;
            }
          }

          &__product-price {
            font-size: 1.2rem;

            .advised-price {
              .price-old-value {
                font-size: 1.8rem;
                font-weight: default(weight);
                text-decoration: line-through;
                color: color(tertiary);
              }
            }

            .normal-price {
              margin-top: -5px;

              .price-value {
                display: block;
                font-size: 2.8rem;
                font-weight: default(weight, medium);
              }
            }
          }

          .repairability-index {
            max-width: 11rem;
            display: block;

            @media (min-width: $sm-width) {
              margin-left: -1rem;
              margin-top: 2rem;
            }

            img {
              max-width: 100%;
            }
          }
        }

        &__buttons {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 2rem;

          @media (min-width: $sm-width) {
            margin-top: 0;
            padding-left: 2rem;
            width: 50%;
          }

          &__actions {
            width: 100%;

            &:nth-child(2) {
              margin-top: 2rem;
            }

            .collection-in-store {
              margin-bottom: 1rem;
              &__store {
                font-weight: default(weight, bold);
                font-size: 1.4rem;
              }
              &__date {
                color: color(tertiary);
                font-weight: default(weight, bold);
                font-size: 1.2rem;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }

          &__label {
            font-size: 1.4rem;
            padding-top: 0.5rem;

            strong {
              color: color(alert, success3);
              font-weight: 500; 
            }
          }
        }
      }
    }

    .product-services {
      margin-bottom: 2rem;

      &__label {
        h4 {
          font-family: default(font-family);
          font-weight: default(weight, medium);
          margin-bottom: 0;
          margin-top: 2rem;
        }
      }

      &__items {
        ul {
          padding-left: 4rem;

          li {
            list-style: disc;
          }
        }
      }

      &__terms-link {
        margin-top: 1.5rem;

        .link {
          text-decoration: underline;
        }
      }

      &__distribution-contract-info {
        font-size: 1.4rem;
        font-style: italic;
        margin-top: 1rem;
      }
    }
  }

  .product-visuals-ctn {
    position: relative;

    .product-flags {
      font-size: 0;
      position: absolute;
      right: 3rem;
      top: 1.5rem;
      z-index: 1;

      li {
        span {
          background-color: color(primary);
          border-radius: 4px;
          color: color(mono, light);
          display: inline-block;
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 0.3rem;
          padding: 2px 6px;
        }
      }
    }
  }

  .product-tags {
    left: 2rem;
    position: absolute;
    top: 2rem;
    z-index: 2;

    .tag {
      display: block;
    }
  }

  .comparator-button {
    margin-bottom: 2rem;
    position: relative;
    text-align: center;

    .listing-text {
      display: none;
    }

    @media (min-width: $sm-width) {
      position: absolute;
      right: 10vw;
      top: 28vw;
      z-index: 2;
    }

    & a {
      margin-left: 0.5rem;
      text-decoration: underline;
    }
  }

  .media-visuals-container__desktop {
    display: none;

    @media (min-width: $sm-width) {
      display: initial;
    }

    .media-visuals {
      display: block;

      &-main {
        height: 30vw;

        &-item {
          height: 100%;
          width: 100%;
        }

        &-img {
          border: none !important;
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      &-thumbnails {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-height: 10rem;
        overflow: hidden;
        transition: all 0.2s ease-in-out;

        &.open {
          max-height: 200rem;

          .thumbnail {
            margin-bottom: 1.5rem;
          }

          .thumbnail-more {
            display: none;
          }
        }

        .thumbnail {
          border: none;
          border-radius: default(radius, medium);
          margin-bottom: 10rem;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          padding: 0;
          padding-bottom: calc(20% - 1rem);
          position: relative;
          width: calc(20% - 1rem);

          @media (min-width: $lg-width) {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            padding-bottom: calc(20% - 3rem);
            width: calc(20% - 3rem);
          }

          &__img {
            height: 100%;
            left: 0;
            object-fit: contain;
            position: absolute;
            top: 0;
            width: 100%;
          }

          &-more {
            align-items: center;
            background-color: transparent;
            border: 1px solid color(border, medium);
            color: color(text);
            cursor: pointer;
            display: flex;
            font-size: 1.8rem;
            font-weight: default(weight, medium);
            justify-content: center;

            &-text {
              display: block;
              left: 0;
              position: absolute;
              text-align: center;
              top: 50%;
              transform: translateY(-50%);
              width: 100%;
            }
          }
        }
      }
    }
  }

  .media-visuals-container {
    &__mobile {
      display: block;
      height: 90vw;
      position: relative;

      @media (min-width: $sm-width) {
        display: none;
      }

      .owl-carousel .owl-stage {
        display: flex;

        .owl-item {
          float: none;
        }
      }

      .media-visuals {
        height: 100%;
        width: 100%;

        .owl-stage-outer,
        .owl-stage {
          height: 100%;
        }

        &__item {
          height: 100%;
          width: 100%;

          &__img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        .owl-dots {
          bottom: -1.5rem;
          position: absolute;
          right: -1.5rem;

          .owl-dot {
            &.active > span {
              background-color: color(mono, dark);
            }

            span {
              border: 1px solid color(border);
              height: 7px;
              width: 7px;
            }
          }
        }
      }
    }
  }

  .product-header {
    &-title {
      line-height: 2.5rem;
      margin-bottom: 1rem;

      @media (min-width: $md-width) {
        line-height: 4rem;
      }
    }

    &-brand {
      img {
        margin-bottom: 1rem;
        max-height: 2.7rem;
      }

      @media (min-width: $md-width) {
        img {
          margin-bottom: 1.5rem;
          max-height: 3rem;
        }
      }
    }

    &-attribut {
      margin-bottom: 3rem;
    }

    &-description {
      margin-bottom: 3rem;
    }
  }

  .product-links-ctn {
    .product-header-guide-link {
      display: inline-block;
      font-size: 1.4rem;
      margin-bottom: 3rem;
      text-decoration: underline;
    }

    .dropdown {
      &-toggle {
        background-color: transparent;
        border: 0;
        font-size: 1.4rem;
        font-weight: default(weight, medium);
        padding: 0;
        padding-right: 2.5rem;
        position: relative;

        &::after {
          content: icon(dropdown);
          display: inline-block;
          font-family: default(font-family, icon);
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.2s linear;
        }
      }

      &.open {
        .dropdown-toggle {
          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }

  .product-set-item-container {
    .product-set-item {
      position: relative;

      &__ctn {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        &__left {
          width: 40%;

          .product-tags {
            left: 0;
            top: 0;
          }
        }

        &__right {
          width: 60%;

          .product-cashback-offers {
            margin-bottom: 1rem;

            &__item {
              & > span {
                font-size: 1.2rem !important;
              }
            }
          }
        }
      }

      &__header {
        &__title {
          font-weight: default(weight);
          margin-bottom: 0;
        }
      }

      &__price {
        font-size: 1.2rem;

        .advised-price {
          .price-old-value {
            font-size: 1.8rem;
            font-weight: 500;
          }
        }

        .normal-price {
          margin-top: -5px;

          .price-value {
            display: block;
            font-size: 2.4rem;
            font-weight: 500;
          }
        }
      }

      &__media {
        &__item {
          &__img {
            width: 100%;
          }
        }
      }

      &__availability {
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        justify-content: space-between;
        margin-left: 40%;
        width: 60%;

        &__item {
          .icon {
            color: red;
          }

          &.success {
            .icon {
              color: green;
            }
          }
        }
      }
    }
  }

  .product-sidebar {
    border: 1px solid color(grey);
    border-radius: 5px;
    padding: 4rem 2rem;
    position: relative;

    @media (max-width: $md-width-max) {
      margin: 4rem -2rem 0;
    }
    @media (min-width: $lg-width) {
      padding: 4rem 6rem;
    }

    .alert-warning {
      font-size: 1.4rem;
    }
  }

  .product-reinsurance {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 0 !important;
    margin-top: 1rem;
    padding: 0;

    a {
      text-decoration: underline;
    }
  }

  .list-social-buttons {
    display: none;
  }
}

.quantity_input_ctn {
  margin-bottom: 3rem;

  .quantity_input {
    margin: 0;
    position: relative;

    span {
      background-color: color(grey);
      border-radius: 2rem;
      cursor: pointer;
      font-size: 1.4rem;
      height: 4rem;
      left: 2rem;
      line-height: 4rem;
      position: absolute;
      text-align: center;
      top: 0.5rem;
      width: 4.6rem;
      z-index: 1;

      &:last-child {
        left: auto;
        right: 2rem;
      }
    }

    .form-control {
      text-align: center;

      /* hide arrows */

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        appearance: textfield;
      }
    }
  }
}

.cart-box-content {
  .show-store-availability {
    text-align: center;

    a {
      font-size: 1.4rem;
      position: relative;

      &::after {
        border-bottom: 1px solid color(text);
        bottom: -2px;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;

        &::after {
          border-bottom: 1px solid color(primary);
        }
      }
    }

    .glyphicon {
      display: none;
    }
  }
}

.add-to-cart-description {
  h2 {
    &.product-set-title {
      display: block;
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }

  .product-price {
    .price {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    .price-value {
      font-size: 2.5rem;
      font-weight: default(weight, bold);
      margin-right: 1.5rem;
    }

    .colored-price {
      .price-value {
        color: color(secondary);
      }
    }

    .price-old-value {
      font-size: 1.8rem;
    }
  }
}

.add-to-cart-actions {
  .btn {
    .glyphicon {
      display: none;
    }
  }
}

.add-to-cart-shipment {
  margin-bottom: 2rem;

  .btn {
    &:disabled {
      display: none;
    }
  }

  .add-to-cart-description {
    padding-bottom: 3rem;

    > p {
      display: none;
    }

    .stock-available {
      margin-bottom: 0;

      h4 {
        font-size: 1.6rem;
        font-weight: default(weight);
        margin-bottom: 0;

        &::before {
          color: color(alert, success);
          content: icon(dot);
          display: inline-block;
          font-family: default(font-family, icon);
          font-size: 8px;
          margin-right: 5px;
        }
      }
    }
  }
}

.add-to-cart-store {
  .add-to-cart-description {
    p {
      margin-bottom: 2rem;
    }
  }

  .add-to-cart-actions {
    .btn {
      @extend .btn-secondary;
      margin-bottom: 2rem;
    }
  }

  .store-pick-up-title {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .store-availablility {
    text-align: center;

    .stock-available {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;

      &::before {
        color: color(alert, success);
        content: icon(dot);
        display: inline-block;
        font-family: default(font-family, icon);
        font-size: 8px;
        margin-right: 5px;
      }
    }

    .store-availablility-change-store {
      a {
        font-size: 1.4rem;
        position: relative;

        &::after {
          border-bottom: 1px solid color(text);
          bottom: -2px;
          content: "";
          display: block;
          left: 0;
          position: absolute;
          width: 100%;
        }

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;

          &::after {
            border-bottom: 1px solid color(primary);
          }
        }
      }
    }
  }
}

[data-product-set-item-index] {
  .form-group {
    margin: 0;
    margin-top: 1.5rem;
    position: relative;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    .col-sm-8 {
      flex-basis: 100%;
      max-width: 100%;
      padding: 0;
      width: 100%;
    }

    .control-label {
      font-size: 1.2rem;
      font-weight: default(weight);
      margin-left: 1.5rem;
      padding: 7px 0 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .form-control-static {
      line-height: 4.3rem;
      padding-bottom: 0;
      padding-left: 1.5rem;
    }

    .custom-select {
      > span {
        line-height: 6.2rem;
      }
    }
  }
}

.product-information-anchors {
  background-color: color(grey, light);
  display: none;
  justify-content: flex-start;
  margin-bottom: 4rem;
  margin-top: 6rem;
  padding: 0 1rem;

  @media (min-width: $md-width) {
    display: flex;
  }

  a {
    display: block;
    flex-grow: 1;
    font-size: 16px;
    padding: 1.7rem 1.5rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
      background-color: #008a8b;
      bottom: 1.5rem;
      content: "";
      height: 2px;
      left: 2rem;
      opacity: 0%;
      position: absolute;
      transition: all 0.3s linear;
      width: 0;
    }

    &:hover,
    &:active,
    &:focus {
      color: color(text);
      line-height: 1;

      &::after {
        opacity: 100%;
        width: calc(100% - 4rem);
      }
    }
  }
}

.product-information-attribute {
  h2 {
    @extend .h3;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 2rem 0;

    &::after {
      content: icon(dropdown);
      font-family: default(font-family, icon);
      font-size: 2rem;
    }
  }

  > .attribute-value-container {
    margin-bottom: 0;
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    transition: all 0.3s linear;
  }

  &.open {
    > .attribute-value-container {
      margin-bottom: 6rem;
      max-height: 100rem;
      padding-top: 1rem;
    }

    h2 {
      &::after {
        content: icon(dropdown-expand);
      }
    }
  }
}

.information-tabs {
  background-color: color(mono, light);
  margin-left: -2rem;
  margin-right: -2rem;

  @media (min-width: $md-width) {
    margin-left: (-$gutter-ctn);
    margin-right: (-$gutter-ctn);
  }

  &__inner {
    padding: 2rem;

    @media (min-width: $md-width) {
      padding: 5rem 3.5rem 0;
    }
  }

  .nav-tabs {
    background-color: color(primary);
    border: none;
    border-radius: default(radius, medium);
    display: flex;
    justify-content: center;
    margin: auto;
    overflow: hidden;

    @media (max-width: $md-width-max) {
      display: none;
    }

    .description-tab {
      order: 0;
    }

    .specificaton-tab {
      order: 1;
    }

    li {
      color: color(text, light);
      margin-left: -2px;
      order: 2;

      &.active {
        a {
          background-color: color(tertiary);
          border: 0;
          color: color(text, light);

          &:hover {
            border: none;
          }
        }
      }

      a {
        border: none;
        border-radius: 0;
        padding: 1.5rem 3rem;
      }
    }
  }

  .tab-content {
    border: none;
    display: block;
    margin: auto;
    max-width: none;
    visibility: visible;

    @media (min-width: $md-width) {
      max-width: 73.3%;
      padding: 0 2rem;
    }
    @media (min-width: $xl-width) {
      max-width: 60%;
    }
    @media (max-width: $md-width-max) {
      .tab-pane {
        display: block;
        opacity: 100%;
      }
    }
  }

  .warranty-and-services-panel {
    h3 + p {
      text-transform: uppercase;
    }

    hr {
      margin-top: 0;
    }
  }

  #product-information-attribute-energeticDiagnostic {
    text-align: center;

    img {
      display: inline-block;
      margin-bottom: 1rem;
      margin-top: 1rem;
      max-width: 40rem;
      width: 100%;
    }
  }
}

.tab-collapse-title {
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  font-weight: default(weight, medium);
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: left;

  @media (min-width: $md-width) {
    &.hidden-md {
      display: none;

      ~ hr {
        display: none;
      }
    }
  }

  &::after {
    content: icon(arrow-up);
    font-family: default(font-family, icon);
    font-size: 1rem;
    transform: rotate(0deg);
  }

  &.collapse-title-active {
    &::after {
      transform: rotate(180deg);
    }
  }

  ~ hr {
    border-color: color(border, dark);
    margin: 0;
  }
}

// carac tab
#product-information-specifications {
  th {
    display: none;
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: transparent;
  }

  .table-bordered {
    border: 0;

    > tbody > tr > td {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      padding-left: 0;
    }
  }

  @media (max-width: $sm-width-max) {
    .table-vertical-responsive tr {
      flex-basis: 100%;
      min-width: 100%;

      td {
        flex-basis: 50%;
        min-width: 50%;
      }
    }
  }
}

.product-information-content {
  @media (min-width: $md-width) {
    display: none;
    padding: 5rem 0;
  }

  &.active {
    display: block;
  }
}

#product-information-attribute-ENERGYDIAGNOSTIC {
  .attribute-value-container {
    img {
      margin: auto;
    }
  }
}

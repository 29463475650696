/* stylelint-disable */
@mixin heading(
  $color: color(mono, dark),
  $font-size: default(font-size),
  $margin-bottom: 30,
  $bold: true,
  $uppercase: true
) {
  color: $color;
  display: block;
  font-size: $font-size;
  margin-bottom: #{$margin-bottom}px;

  @if $bold == true {
    font-weight: default(weight, bold);
  }
  @if $uppercase == true {
    text-transform: uppercase;
  }
}
@mixin rem($pixel-size: default(font-size)) {
  font-size: #{calc($pixel-size)}px;
  font-size: #{calc($pixel-size/10)}rem;
}
@mixin animated($prop: all, $time: 0.3s, $easing: ease, $delay: 0s) {
  transition: $prop $time $easing $delay;
}
@mixin poa($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
  @if $right != null {
    right: $right;
  }
  @if $top != null {
    top: $top;
  }
}
@mixin position(
  $type: "absolute",
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $type;

  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
  @if $right != null {
    right: $right;
  }
  @if $top != null {
    top: $top;
  }
}
@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
  @if $bottom != null {
    padding-bottom: $bottom;
  }
  @if $left != null {
    padding-left: $left;
  }
  @if $right != null {
    padding-right: $right;
  }
  @if $top != null {
    padding-top: $top;
  }
}
@mixin size($width, $height: $width) {
  height: #{$height}px;
  width: #{$width}px;
}
@mixin transform($transform) {
  transform: $transform;
}
@mixin line-clamp($numLines: 1, $lineHeight: 1.412) {
  -webkit-box-orient: vertical;
  display: block;

  /* autoprefixer: off */
  display: box;
  display: -webkit-box;
  -webkit-line-clamp: $numLines;

  // max-height: $numLines * $lineHeight + unquote("em");
  // min-height: $numLines * $lineHeight + unquote("em");
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
}
@mixin txt-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin placeholder {
  &::input-placeholder {
    @content;
  }

  &:placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }

  &:input-placeholder {
    @content;
  }
}

@mixin labelAnimated {
  height: 4.8rem;
  left: 1px;
  letter-spacing: 0 !important;
  line-height: 4.8rem;
  max-width: 100%;
  overflow: hidden;
  padding: 0 1.5rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 1px;
  //transition: 0.2s line-height;
  transition: 0.2s top;
  white-space: nowrap;
  width: auto !important;
  z-index: 4;
}

@mixin labelFocused {
  background: none;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 3rem;
  pointer-events: none;
}
/* stylelint-enable */
/////////// surcharge bootstrap //////////
/// utilisé dans le tunnel et le compte (containers de largeurs différentes)
.table-vertical-responsive {
  position: relative;

  > .table {
    background-color: transparent;

    > tr {
      background-color: transparent !important;
      border-bottom: 1px solid color(border);
      display: flex;
      flex-wrap: nowrap;
      padding-bottom: 6rem;
      position: relative;

      &:last-child {
        border-bottom: 0;
      }

      > td {
        border: none;
        flex-grow: 1;
        padding: 2.5rem 0;
      }
    }
  }

  .line-visual {
    padding-left: 0;

    img {
      max-width: 100%;
    }
  }

  .line-detail,
  .line-details {
    // titre produit
    li:first-child {
      font-size: 1.8rem;
    }

    [data-ng-if="line.options.axesInfo.length"] {
      margin-top: 1.2rem;
    }

    [data-ng-click="openUpdateVariantPopup()"] {
      display: inline-block;
      font-size: 1.4rem;
      margin-bottom: 2rem;
      margin-top: 0;
      text-decoration: underline;
    }

    .dl-inline-items {
      display: flex;
      flex-wrap: wrap;

      dt {
        @include txt-overflow;
        width: 30%;
        &::after{
          content: " : ";
        }
      }

      dd {
        @include txt-overflow;
        width: 70%;
        padding-left:0.5rem;
      }
    }

    [data-ng-if="line.product.common.quickBuyURL"] {
      margin-top: 1.5rem;
    }
  }

  .line-prices {
    .main-price {
      border: 0 !important;
      padding: 0;
    }

    ul {
      align-items: flex-end;
      display: flex;
      margin-top: 1rem;

      li {
        display: inline;
      }

      span {
        font-size: 1.8rem;
        font-weight: default(weight, bold);
      }

      // old-price
      [data-ng-show="parameters.displayPricesWithTax && line.baseAmountWithTaxes"] {
        font-size: 1.8rem;
        padding-right: 1.5rem;
      }

      [data-ng-show="parameters.displayPricesWithTax"] {
        span {
          font-size: 2.5rem;
          font-weight: default(weight, bold);
          line-height: 2.5rem;
        }
      }

      &.colored-price {
        [data-ng-show="parameters.displayPricesWithTax"] {
          span {
            color: color(secondary);
          }
        }
      }

      .discount-detail {
        border-radius: default(radius);
        font-size: 1.2rem;
        margin-left: 1rem;
        order: 3;
        padding: 0.1rem 0.8rem;
      }

      @media (min-width: $sm-width) {
        justify-content: flex-end;
      }
    }
  }

  // tablet
  @media (min-width: $xs-width) and (max-width: $sm-width-max) {
    .line-visual {
      max-width: 30%;
      position: absolute;
    }

    .line-detail,
    .line-details {
      padding-left: 30%;
    }
  }

  // medium desktop
  @media (min-width: $sm-width) and (max-width: $xxl-width-max) {
    .line-visual {
      max-width: 10rem;
    }
  }

  // mobile paysage
  @media (max-width: $xs-width-max) {
    .line-visual {
      border-top: 0;
      max-width: 28%;
      position: absolute;
    }

    .line-detail,
    .line-details {
      flex-basis: 75%;
      padding-bottom: 0 !important;

      [data-ng-if="line.options.axesInfo.length"] {
        font-size: 14px;
        margin-top: 1.5rem;
      }
    }
  }

  // mobile portrait
  @media (max-width: $sm-width-max) {
    tr {
      display: flex;
      flex-wrap: wrap !important;
    }

    thead {
      th:nth-child(n + 1) {
        opacity: 0%;
      }
    }

    .line-quantity {
      flex-basis: 75%;
      margin-left: 25%;
      order: 1;
      padding: 0 !important;
      text-align: left;
    }

    .line-prices {
      flex-basis: 75%;
      justify-content: flex-start;
      margin-left: 25%;
      min-width: 0;
      order: 2;
      padding: 0 !important;
      text-align: left;
    }

    .line-status {
      flex-basis: 100%;
      font-weight: bold;
      margin-bottom: -10px;
      order: 0;
      padding-bottom: 0 !important;
      text-align: left;
    }
  }

  // desktop
  @media (min-width: $sm-width) {
    .line-visual {
      padding-right: 15px;
    }
  }
}

//////////////////////////////
// TOTAUX
.table-amounts {
  tr:last-child {
    align-items: center;
    font-size: 1.6rem;
    font-weight: default(weight, bold);

    td strong {
      display: block;
      font-weight: default(weight);
    }

    td:last-child {
      align-items: flex-end;
      font-weight: default(weight, bold);
    }
  }

  td {
    border-top: 0 !important;
    padding: 4px 0 !important;

    &:first-child {
      text-align: left;
    }
  }
}

// sous-total
[data-rbs-commerce-cart-delivery],
[data-rbs-commerce-process-delivery-lines] {
  .table-amounts {
    td {
      border-top: 0 !important;
      color: color(text, dark);

      &:first-child {
        text-align: left;
      }
    }
  }
}

$carousel-dot-bg: color(grey, medium);
$carousel-dot-border-color: color(grey, medium);
$carousel-dot-active-border-color: color(mono, dark);
$carousel-dot-active-bg: color(mono, dark);

.carousel {
  &-inner {
    .item {
      padding: 0 1.5rem;

      @media (min-width: $sm-width) {
        padding: 0;
      }
    }

    .item.active {
      display: flex;

      //justify-content: center;
    }

    img {
      max-width: 100%;
    }
  }

  &-control {
    opacity: 0%;
    text-shadow: none;

    &.left,
    &.right {
      background: none;
    }

    .glyphicon {
      color: color(secondary);

      // font-family: default(font-family, icon);
    }

    // .glyphicon-chevron-left::before {
    //   content: icon(arrow-left);
    // }

    // .glyphicon-chevron-right::before {
    //   content: icon(arrow-right);
    // }
  }

  &:hover {
    .carousel-control {
      opacity: 100%;
    }
  }

  &-indicators {
    font-size: 0;
    left: auto;
    margin-left: 0;
    margin-top: 0;
    position: static;
    width: 100%;

    li {
      &::before {
        background-color: $carousel-dot-bg;
        border-color: $carousel-dot-border-color;
      }
      border: 0;
      border-radius: 0;
      height: 15px;
      margin: 0;
      text-indent: -999px;
      width: 15px;
    }

    .active {
      &::before {
        background-color: $carousel-dot-active-bg;
        border-color: $carousel-dot-active-border-color;
      }
      height: 15px;
      margin: 0;
      width: 15px;
    }
  }
}

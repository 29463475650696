[data-layout-rbs-order-order-list],
[data-layout-rbs-storeshipping-reservation-list],
[data-layout-rbs-geo-manage-addresses],
[data-layout-rbs-user-change-email],
[data-layout-rbs-user-change-mobile-phone],
[data-layout-rbs-user-change-password],
[data-layout-rbs-wishlist-wishlist-list],
[data-layout-rbs-productreturn-return-list],
[data-layout-rbs-mailinglist-manage-subscriptions] {
  h1 {
    @extend .body-large;
  }

  h2 {
    @extend .body-large;
  }

  h3 {
    color: color(text, dark);
    font-size: 1.8rem;
    font-weight: default(weight, medium);
    margin-bottom: 1rem;
  }

  .row {
    &::before {
      display: none;
    }
  }

  .table,
  .table-striped {
    border: 0;

    > thead {
      > tr {
        th {
          background-color: color(grey, light);
          border: 1px solid color(grey) !important;
          font-size: 1.6rem;
          font-weight: default(weight, bold);
          padding: 1.5rem 2.5rem;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          &:first-child {
            font-weight: default(weight, bold);
          }
          background-color: color(mono, light);
          border: 1px solid color(grey);
          padding: 1.5rem 2.5rem;

          .price {
            padding-left: 2rem;
          }
        }
      }
    }
  }

  [data-ng-repeat-start="address in data.addresses"] {
    padding-bottom: 2rem;

    address {
      margin-bottom: 1rem;
    }

    .btn {
      font-size: 1.4rem;
      font-weight: default(weight);
      margin-top: 0.5rem;
      min-height: auto;

      .glyphicon-credit-card {
        display: none;
      }

      &-danger {
        @extend .btn-default;
      }
    }
  }

  [data-ng-click="openNewAddressForm()"] {
    margin: 2rem auto 0;
  }
}

// my information : static fields
[data-name="Rbs_User_EditAccount"] {
  .form-group {
    position: relative;

    > label {
      @include labelAnimated;
      @include labelFocused;
    }
  }
}

[data-layout-rbs-geo-manage-addresses] {
  margin-bottom: 20rem;

  h2 {
    display: none;
  }

  .vertical-if-animation-content {
    [data-ng-repeat-start="address in data.addresses"] h3 {
      border-top: 1px solid color(grey);
      padding-top: 3.5rem;
    }
  }

  .col-md-6 {
    form {
      border-top: 1px solid color(grey);
      padding-top: 3.5rem;
    }

    &:last-child {
      form {
        border-bottom: 1px solid color(grey);
      }
    }
  }

  [data-rbs-geo-address-editor] + .row {
    > div {
      margin: 0;
      max-width: 100%;

      > p {
        margin-bottom: 1rem;
      }
    }

    @media (min-width: $md-width) {
      .btn-primary {
        float: right;
      }
    }
    @media (max-width: $md-width-max) {
      .btn {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }

  h2 + .row {
    .col-md-6 {
      flex-basis: 100%;
      margin-bottom: 15px;
      max-width: 100%;
      width: 100%;

      address {
        line-height: 1.4;
      }

      form {
        padding-bottom: 2rem;
      }

      p {
        margin-top: 1rem;
      }

      @media (min-width: $md-width) {
        p:last-child {
          bottom: 1.5rem;
          position: absolute;
          right: 1.5rem;
        }
      }

      .btn {
        @extend .btn-small;

        .glyphicon-heart-empty,
        .glyphicon-plane {
          display: none;
        }
      }
    }
  }
}

[data-layout-rbs-order-order-list] {
  @media (max-width: $sm-width-max) {
    .table-vertical-responsive {
      thead {
        display: none;
      }
    }

    tr {
      background-color: color(mono, light) !important;
      border: 1px solid color(grey);
      margin-bottom: 2rem;
      padding: 2rem;

      td {
        border: 0 !important;
        margin-bottom: 1rem;
        padding: 0 !important;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

[data-layout-rbs-order-order-detail] {
  padding-bottom: 12rem;

  h1 {
    display: flex;
    flex-wrap: wrap;

    span {
      @extend .h2;
      margin-top: 2rem;
      order: 1;
      width: 100%;
    }
  }

  ul.pull-right {
    align-items: center;
    display: flex;
    float: none !important;
    justify-content: flex-end;
    margin-bottom: 3rem;

    @media (max-width: $sm-width-max) {
      justify-content: flex-start;
    }

    a {
      display: inline-block;
      margin-left: 2rem;
      text-align: center;
    }

    .btn {
      @extend .btn-ternary;
      margin-left: 0;
    }
  }

  .image-format-cartItem-max-size {
    margin-top: 0.5rem;
  }

  .row {
    &::before,
    &::after {
      display: none;
    }

    .col-sm-6 {
      @media (min-width: $xs-width) {
        width: 50%;
      }
    }
  }

  .dl-inline-items {
    margin-bottom: 5rem;

    dt {
      float: left;
      font-weight: default(weight, medium);
      padding-right: 1rem;
    }
  }

  .col-md-6 {
    width: 100%;

    @media (min-width: $md-width) {
      width: 50%;
    }
  }
}

[data-layout-rbs-productreturn-return-process] {
  padding-bottom: 12rem;

  h1 {
    @extend .h2;
    font-size: 2.1rem;

    @media (min-width: $md-width) {
      font-size: 3rem;
    }

    span {
      display: none;
    }
  }

  .list-unstyled.pull-right {
    float: none !important;
    margin-bottom: 2rem;
    text-align: left;

    .btn {
      @extend .btn-ternary;
    }
  }

  .line-quantity a {
    display: inline-block;
    margin-top: 0.5rem;
    text-decoration: underline;
  }

  .form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;
  }

  [data-rbs-productreturn-shipment-line-return] {
    td {
      padding: 0;

      &:first-child {
        display: none;
      }
    }

    .panel {
      margin-bottom: 0;
    }

    .no-bottom-margin {
      margin-bottom: 0;
    }

    [data-ng-repeat^="fileInfo"] {
      // add file :
      .form-control-static {
        background-color: inherit;
        height: auto;
        padding: 0;
      }

      // remove file :
      .btn-xs {
        background-color: transparent;
        border: 0;
        color: inherit;
        padding: 5px;
      }

      .glyphicon-remove {
        display: block !important;

        &::before {
          content: icon(trash);
          font-family: default(font-family, icon);
        }
      }
    }

    [data-ng-click="cancelLine(shipmentIndex, lineIndex, returnLineIndex)"] {
      margin-bottom: 1rem;
      width: 100%;

      + .btn {
        width: 100%;
      }

      .visible-md-inline {
        display: block !important;
      }

      @media (min-width: $md-width) {
        margin-bottom: 0;
        width: auto;

        + .btn {
          width: auto;
        }
      }
    }

    // summary return
    [data-rbs-productreturn-return-line-summary] {
      padding: 1.5rem;
    }
  }

  // return shipping
  [data-rbs-productreturn-store-return-mode] {
    .btn-default {
      margin-bottom: 1rem;
    }
  }

  [data-rbs-productreturn-return-line-edition] {
    @extend .white-box-padded;
    border: 0;
  }

  tr.active > td {
    background-color: transparent !important;
  }

  tr.success > td {
    background-color: transparent !important;
  }

  .btn {
    .glyphicon {
      display: none;
    }
  }
}

[data-ng-controller="RbsProductreturnReturnListController"],
[data-layout-rbs-order-order-detail],
[data-layout-rbs-storeshipping-reservation-detail],
[data-rbs-productreturn-process] {
  .table-vertical-responsive {
    margin-bottom: 5rem;
    margin-top: 5rem;

    th {
      border-bottom: 0;
      font-weight: default(weight, medium);
    }

    tr {
      background-color: transparent !important;
    }

    .line-details {
      @media (max-width: $sm-width - 1px) {
        flex-basis: 100%;
        padding: 1rem 0 1rem 30% !important;
      }
    }

    .line-quantity {
      @media (max-width: $sm-width - 1px) {
        flex-basis: 70%;
        margin-left: 30%;
        padding: 1rem 0 !important;
      }
    }

    .line-precisions {
      @media (max-width: $sm-width - 1px) {
        flex-basis: 70%;
        margin-left: 30%;
        padding: 1rem 0 !important;
      }
    }

    .line-status {
      @media (max-width: $sm-width - 1px) {
        flex-basis: 70%;
        margin-bottom: 0;
        margin-left: 30%;
        padding: 1rem 0 !important;
      }
    }

    .line-prices {
      @media (max-width: $sm-width - 1px) {
        flex-basis: 70%;
        margin-left: 30%;
        padding: 1rem 0 !important;
      }

      ul {
        margin-top: 0;
      }
    }

    .btn {
      @extend .btn-small;
      white-space: nowrap;

      .glyphicon {
        display: none;
      }
    }
  }

  hr {
    display: none;
  }
}

[data-ng-controller="RbsProductreturnReturnListController"] {
  padding-top: 6rem;

  .btn {
    @extend .btn-ternary;
  }

  .panel-heading {
    border-bottom: 0 !important;
    border-top: 1px solid color(grey);
    color: color(text);
    margin-top: 6rem;
    padding-left: 0;
    padding-right: 0;
  }
}

[data-name="Rbs_User_EditAccount"] div.form-group > div,
[data-name="Rbs_User_ChangeMobilePhone"] div.form-group > div,
[data-name="Rbs_User_ChangePassword"] div.form-group > div,
[data-name="Rbs_User_ChangeEmail"] div.form-group > div {
  margin: 0;
  width: 100%;
}

// wishlist
[data-name="Rbs_Wishlist_WishlistDetail"] {
  .container-fluid {
    padding: 0;
  }

  .list-title {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    width: 100%;

    &::after {
      border-bottom: 1px solid color(border, medium);
      content: "";
      display: block;
      flex-basis: 100%;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-top: 2rem;
    }

    h1 {
      @extend .body-large;
      margin-bottom: 0.3rem;
    }
  }

  .wishlist-title {
    padding-top: 2rem;

    .btn-link {
      @extend .btn-ternary;

      &::after {
        display: none;
      }
    }
  }

  .wishlist-actions {
    flex-basis: 65rem;
    margin-top: 2rem;

    > div {
      float: none !important;

      @extend .flex;

      @media (min-width: $sm-width) {
        .btn {
          flex-basis: 48%;
        }
      }
    }

    .glyphicon {
      display: none;
    }

    .btn {
      margin-bottom: 1rem;

      &-danger {
        @extend .btn-secondary;
      }

      @media (max-width: $sm-width) {
        display: block;
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }

  .wishlist-products-actions {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    padding-bottom: 2rem;
  }

  .wishlist-edition {
    flex-basis: 100%;
    max-width: 100%;

    .btn {
      height: 5rem;
    }

    .glyphicon-remove {
      &::before {
        content: icon(close);
        font-family: icomoon;
      }
    }

    input {
      height: 5rem;
    }
  }

  .product-list-selector {
    display: flex;
    justify-content: center;

    label {
      align-items: center;
      display: flex;
    }
  }

  .product-list {
    .thumbnail {
      .product-price-layout {
        @media (max-width: $xs-width) {
          padding-bottom: 2.4rem;
        }
      }

      .wishlist-checkbox {
        @media (max-width: $xs-width) {
          bottom: 3rem;
          left: 0;
          margin: 0;
          padding-left: 17.8rem;
          position: absolute;
        }
        @media (max-width: $xxs-width) {
          padding-left: 14rem;
        }
      }
    }
  }
}

[data-layout-rbs-wishlist-wishlist-list] {
  .wishlist-list {
    justify-content: flex-start;

    .wishlist-list-list {
      border: 1px solid color(grey);
      padding: 2rem;

      &-title {
        font-weight: default(weight, bold);
        margin-bottom: 1rem;
        text-decoration: none;
      }
    }

    .list-unstyled {
      a {
        display: block;
        text-decoration: underline;
      }

      p {
        margin-bottom: 1rem;

        &.small ~ div {
          align-items: center;
          display: flex;
          flex-wrap: nowrap;

          .glyphicon {
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

.home-agency {
  .stores-list-ctn {
    position: relative;
  }

  .btn {
    margin-top: 1rem;
  }

  .stores-list {
    background-clip: padding-box;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 10px color(shadow, navbar);
    font-size: 14px;
    left: 0;
    list-style: none;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 100%;
    width: 100%;
    z-index: 1000;

    li {
      padding: 0.5rem 2rem;
    }
  }

  .store-selected {
    position: relative;

    .picto-store {
      background: color(primary);
      border-radius: 50%;
      color: color(mono, light);
      font-size: 2rem;
      height: 50px;
      left: 2rem;
      line-height: 5rem;
      position: absolute;
      text-align: center;
      width: 50px;
    }

    .details-store {
      padding-left: 10rem;

      &-title {
        margin-bottom: 1.5rem;
      }

      &-phone {
        font-size: 2rem;
        font-weight: default(weight, medium);
        margin-top: 3rem;
      }
    }

    .links {
      margin-top: 3rem;
      padding-left: 10rem;

      a {
        display: inline-block;
        margin-bottom: 0.5rem;
        text-decoration: underline;
      }
    }
  }
}

[data-layout-project-expressorder-most-buyed-products] {
  padding-bottom: 12rem;

  .product-list {
    padding: 0;
  }

  .checkbox {
    margin-top: 1.5rem;
  }

  .add-most-buyed {
    /* stylelint-disable-next-line prettier/prettier */
    background:
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0%) 0%,
        rgba(255, 255, 255, 100%) 90%
      );
    bottom: 0;
    left: 0;
    padding: 2rem 0;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 10;
  }
}

[data-layout-rbs-user-edit-account],
[data-layout-rbs-user-change-password],
[data-layout-rbs-user-change-email],
[data-layout-rbs-user-change-mobile-phone] {
  h2 {
    @extend .body-large;
  }
  margin-bottom: 9rem;

  @media (min-width: $md-width) {
    margin-bottom: 12rem;
  }
}

[data-layout-rbs-storeshipping-reservation-detail] {
  margin-bottom: 12rem;

  > .row {
    margin-bottom: 6rem;
  }

  .btn-responsive-toolbar {
    .btn {
      @extend .btn-ternary;
      margin-top: 2rem;
    }
  }
}

[data-layout-rbs-user-edit-account] {
  .form-control-static {
    background-color: color(grey, light);
    border-radius: 4px;
    font-weight: default(weight) !important;
    opacity: 80%;

    //padding: 0.7rem 1.5rem;

    strong {
      font-weight: default(weight) !important;
    }
  }
}

[data-layout-rbs-storeshipping-reservation-list] {
  &[data-rbs-block-container="6"] {
    margin-bottom: 12rem;
  }
}

.coupons-ctn {
  padding-top: 12rem;

  .coupon-list {
    margin-top: 3rem;

    .btn {
      margin-right: 3rem;
    }
  }

  #coupon-tooltip {
    background-color: color(mono, light);
    border-radius: 5px;
    box-shadow: 0 0 5px color(shadow, overlay);
    color: color(text);
    opacity: 0%;
    padding: 10px 20px;
    pointer-events: none;
    position: fixed;
    text-transform: none;
    transition: opacity 0.75s ease;
    z-index: 10;

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid color(mono, light);
      content: "";
      font-size: 0;
      left: 50%;
      line-height: 0;
      margin-left: -5px;
      position: absolute;
      top: 42px;
      width: 0;
    }

    &.active {
      opacity: 100%;
    }
  }
}

[data-layout-rbs-mailinglist-manage-subscriptions] {
  h1 {
    @extend .h2;
  }

  #rbsMailinglistSubscriptionsEmail {
    margin: 2rem 0;
  }

  .form-control {
    display: block;
  }
}

[data-layout-rbs-dataprotection-erasure-request] {
  h1 {
    display: none;
  }
}

// in modal-rbs-catalog-show-store-availability (fiche produit et panier) ou order-process
.storeshipping-stores-list {
  padding: 0;

  &-item {
    border: 1px solid color(grey);
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding: 2rem;

    .row {
      margin: 0;
      padding-left: 0;
      position: relative;

      @media (min-width: $xs-width) {
        padding-left: 7rem;
      }

      &::before {
        background-color: color(primary);
        border-radius: 50%;
        color: color(mono, light);
        content: icon(store);
        display: block;
        font-family: default(font-family, icon);
        font-size: 2rem;
        height: 5rem;
        left: 0;
        line-height: 5rem;
        position: absolute;
        text-align: center;
        top: 0;
        width: 5rem;
      }
    }

    address {
      strong {
        align-items: center;
        display: flex;
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        min-height: 5rem;
        padding-left: 7rem;

        @media (min-width: $xs-width) {
          min-height: 0;
          padding-left: 0;
        }
        @media (min-width: $sm-width) {
          font-size: 2rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  > div {
    .opening-hours {
      margin: 1rem 0;
    }

    > h4 {
      display: none;
    }

    .col-sm-7,
    .col-sm-5 {
      flex-basis: 100%;
      max-width: 100%;
      padding: 0;
    }

    .col-sm-5 > p:first-child {
      // heure de retrait
      color: color(alert, success);
      font-size: 1.6rem;
      font-weight: default(weight);

      &::before {
        color: color(alert, success);
        content: icon(dot);
        display: inline-block;
        font-family: default(font-family, icon);
        font-size: 8px;
        margin-right: 5px;
      }
    }
  }

  .glyphicon-star {
    display: none;
  }

  .btn-primary {
    margin-top: 1rem;

    @media (min-width: $md-width) {
      width: auto;
    }
  }
}

[data-rbs-storeshipping-product-locator] {
  [data-ng-if="useGoogleMap"] {
    .form-inline {
      .form-group {
        margin-bottom: 1.5rem;
        max-width: 100%;
      }
      padding-left: 0 !important;
      position: relative;

      [data-ng-click="locateMe()"] {
        @extend .btn-unstyled;
        bottom: 0;
        font-size: 0;
        margin: auto;
        position: absolute;
        right: 1.5rem;
        top: 0;

        .glyphicon-map-marker {
          font-size: 2rem;

          &::before {
            content: icon(search);
            font-family: icomoon;
          }
        }
      }
    }
  }
}

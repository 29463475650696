// index
.slider-hp {
  padding-top: 2rem;

  &-video {
    margin-bottom: 6.7rem;
    overflow: hidden;
    padding-bottom: 33.33%; // = (1 / cinemascope)*100
    position: relative;

    // ratio 3
    iframe {
      height: 169.49%;
      margin-left: -35%;
      margin-top: -12%;
      position: absolute;
      width: 169.49%;
    }

    img {
      height: 169.49%;
      margin-top: -12%;
      position: absolute;
      width: 100%;
    }

    @media (min-width: $md-width) {
      // cinemascope = 2.35
      padding-bottom: 42.55%; // = (1 / cinemascope)*100
      // ratio 3
      padding-bottom: 33.33%; // = (1 / ratio 3)*100

      iframe {
        height: 135%; // =  cinemascope / 16/9 *100 = 132,76% arrondi
        margin-left: -16.5%;
        margin-top: -7.5%;
        width: 135%; // = cinemascope / 16/9 *100 = 132,76% arrondi
      }

      // ratio 3
      iframe {
        height: 169.49%;
        margin-left: -35%;
        margin-top: -12%;
        width: 169.49%;
      }
    }
  }

  .owl-nav {
    margin-top: 0;
  }

  .owl-dots {
    display: none;
  }

  @media (min-width: $md-width) {
    padding-top: 3.5rem;
  }
}

.call-to-store-locator {
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  padding: 3rem;

  @media (min-width: $sm-width) {
    flex-direction: row;
  }

  &-icon {
    @media (min-width: $sm-width) {
      margin-right: 1rem;
    }

    .icon {
      font-size: 3rem;
    }
  }

  &-text {
    align-items: center;
    display: flex;
    text-align: left;

    p {
      font-size: 1.4rem;

      @media (min-width: $sm-width) {
        font-size: 1.8rem;
      }
    }

    @media (min-width: $sm-width) {
      max-width: 50%;
    }
  }

  &-btn {
    width: 100%;

    @media (min-width: $sm-width) {
      margin-left: 6rem;
      width: auto;
    }

    .btn {
      border-color: color(border, light);
      color: color(text, light);
      width: 100%;

      &:hover {
        background-color: color(background, light);
        color: color(tertiary);
      }
    }
  }

  .btn-link {
    color: color(text, light);
    font-size: 1.4rem;

    &::after {
      border-color: color(border, light);
      bottom: -1px;
    }
  }
}

.brands-hp {
  margin-top: 5rem;

  .y-padded {
    padding-bottom: 0 !important;
  }

  .owl-item {
    background-color: color(mono, light);
    border-radius: default(radius, button);
    padding: 1.5rem 2.7rem;

    @media (min-width: $sm-width) {
      padding: 2.5rem 3rem;
    }

    .item {
      height: 22px;
      margin: auto;
      width: 96px;

      @media (min-width: $sm-width) {
        height: auto;
        width: auto;
      }

      img {
        margin: 0 auto;
        width: auto;
      }
    }
  }

  .owl-nav {
    display: none;
    margin-top: 0;

    @media (min-width: $sm-width) {
      display: block !important;
    }

    .owl-prev {
      left: -4rem;
    }

    .owl-next {
      right: -4rem;
    }
  }
}

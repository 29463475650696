.read-more {
  &__btn {
    .read-more__btn__text:nth-child(2) {
      display: none;
    }

    &.less {
      .read-more__btn__text:first-child {
        display: none;
      }

      .read-more__btn__text:nth-child(2) {
        display: inline;
      }
    }
  }
}

[data-layout-rbs-user-login],
[data-layout-rbs-user-create-account] {
  @media (min-width: $md-width) {
    float: left;
    width: 50%;
  }

  > [data-ng-controller="RbsUserLogin"], .social-login__ctn, [data-rbs-user-create-account] {
    @extend .white-box-padded;
  }
  h1 {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 3rem;
    padding: 1.5rem;
    text-align: center;
    border-bottom: 1px solid color(border, medium);

    @media (min-width: $md-width) {
      margin-bottom: 4rem;
    }
  }

  fieldset {
    > p,
    > div {
      margin: 0 auto 2rem !important;
    }

    > .btn {
      display: block;
      margin: 0 0 3rem;
      width: calc(100% - 4rem);

      @media (min-width: $md-width) {
        display: inline-block;
        margin: 0 0 6rem;
        width: auto;
      }
    }

    .col-sm-8 {
      max-width: 100%;
      padding: 0;
      width: 100%;

      &.radio-status {
        @media (min-width: $xs-width) {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .form-group {
            margin-right: 2rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .form-group {
      margin-left: 0;
      margin-right: 0;
    }

    .col-md-offset-3 {
      margin-left: 0;
    }
  }
}

[data-layout-rbs-user-login] {
  //padding: 0 2rem 3rem;

  @media (min-width: $md-width) {
    padding: 0 1.5rem 0 3.5rem;
  }

  .content > p {
    margin: 0 0 4rem !important;
    padding: 0 2rem;

    @media (min-width: $md-width) {
      padding: 0 6rem;
    }

    &:first-child {
      margin: 4rem 0 1rem !important;
    }
  }

  [data-rbs-user-forgot-password] {
    font-size: 1.4rem;
    margin-bottom: 3rem !important;
    text-align: left !important;
    text-decoration: underline;
  }

  .checkbox {
    .custom-checkbox {
      position: absolute;
    }

    label {
      padding-left: 3.5rem !important;
    }
  }
}

[data-layout-rbs-user-create-account] {
  //padding: 0 2rem 12rem;

  @media (min-width: $md-width) {
    padding: 0 3.5rem 12rem 1.5rem;
  }

  [data-ng-show="accountConfirmed"] {
    .text-success {
      color: color(alert, success2) !important;
    }

    a {
      text-decoration: underline;
    }
  }

  &::after {
    clear: both;
    content: " ";
    display: block;
  }

  .btn {
    display: block;
    margin-bottom: 1rem;
    margin-top: 10px;
    width: 100%;

    @media (min-width: $md-width) {
      display: inline-block;
      margin-bottom: 4rem;
      width: auto;
    }
  }

  .input-group-btn {
    .btn {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
      z-index: 10;
    }
  }

  .checkbox {
    .required-symbol {
      display: none;
    }
  }
}

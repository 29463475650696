[data-rbs-catalog-products-list-v3],
[data-project-catalog-products-list-v3] {
  padding-top: 5rem;

  &.grid {
    .product-list-display-grid {
      color: color(text) !important;
    }
  }

  > .one-line-bottom-margin {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    @media (min-width: $md-width) {
      justify-content: flex-end;
    }

    .product-list-number {
      font-size: 1.4rem;

      @media (min-width: $md-width) {
        flex-grow: 1;
      }
    }

    .product-list-display {
      display: none;
      font-size: 0;
      margin-right: 4rem;

      @media (min-width: $md-width) {
        display: block;
      }

      button {
        color: color(grey, disabled);
        font-size: 2rem;
        padding: 0 0.75rem;

        &.active {
          color: color(text);
        }
      }
    }

    .result-sorting-method {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0;

      .input-group-addon {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        color: color(text);
        display: inline-block;
        flex-grow: 1;
        font-size: 1.4rem;
        font-weight: default(weight);
        line-height: 1.2;
        margin-right: 0.6rem;
        padding: 0;
        text-align: left;
      }

      .input-group-btn {
        display: inline-block;
        position: static;
        width: auto;

        .dropdown-toggle {
          background-color: transparent !important;
          border: 0;
          border-radius: 0 !important;
          box-shadow: none !important;
          color: color(text);
          display: block;
          font-size: 14px;
          font-weight: default(weight, medium);
          height: auto;
          line-height: 1.2;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: left;
          text-decoration: underline;
          vertical-align: initial;
          width: 100%;
          z-index: 1;

          .caret {
            display: none;
          }
        }

        .dropdown-menu {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &.list {
    .product-list-display-list {
      color: color(text) !important;
    }

    .product-list {
      .xxs12 {
        @media (min-width: $sm-width) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      .thumbnail {
        &-inner {
          @media (min-width: $sm-width) {
            align-items: stretch;
            flex-flow: row wrap;
            flex-direction: row;
            height: auto;
          }
        }

        .product-visual {
          @media (min-width: $sm-width) {
            flex-basis: 30%;
            max-width: 30%;
            min-height: 100%;
            min-width: 0;
            padding-bottom: 30%;
            padding-right: 2rem;
          }
          @media (min-width: $md-width) {
            padding-right: 3rem;
          }
          @media (min-width: $lg-width) {
            padding-right: 5rem;
          }
          @media (min-width: $xxl-width) {
            padding-bottom: 28%;
          }
          @media (min-width: $xxxl-width) {
            padding-bottom: 22%;
          }
        }

        .product-content {
          @media (min-width: $sm-width) {
            display: flex;
            flex-basis: auto;
            flex-basis: 50%;
            flex-direction: column;
            flex-grow: 1;
            max-width: 50%;
            padding-right: 2.5rem;
          }
        }

        .product-reviews {
          @media (min-width: $xs-width) {
            display: inline-flex;

            &__ctn {
              display: block;
            }

            &__rate {
              margin-right: 1rem;
            }
          }
        }

        .product-footer {
          @media (min-width: $sm-width) {
            display: flex;
            flex-basis: 20%;
            flex-direction: column;
            justify-content: flex-end;
            max-width: 20%;
            width: auto;
          }

          .product-price {
            justify-content: flex-end;
            margin-top: 0;
            text-align: right;

            .price-with-taxes {
              text-align: right;
            }
          }

          .product-availability {
            display: none;
          }
        }

        .product-infos {
          @media (min-width: $sm-width) {
            display: flex;
          }
        }

        .price-for-grid {
          @media (min-width: $sm-width) {
            display: none;
          }
        }

        .price-for-list {
          @media (min-width: $sm-width) {
            display: block;
          }
        }
      }
    }
  }
}

.nosidebar [data-id="mainContent"] {
  position: relative;
}

.facets-responsive-buttons {
  margin-top: 5rem;
  text-align: center;
  width: 100%;

  @media (min-width: $sm-width) {
    margin-top: 0;
  }
  @media (min-width: $md-width) {
    display: none;
  }

  .btn {
    @extend .btn-secondary;
    width: 100%;
  }
}

.page-heading-category {
  padding: 5rem 0;
  width: 100%;

  @media (min-width: $md-width) {
    width: 60%;
  }

  &__title {
    margin-bottom: 0;
  }

  &__content {
    margin-top: 3.8rem;

    span {
      @include line-clamp(2, 1.4);

      @media (min-width: $xs-width) {
        display: inline;
      }
    }
  }

  &__link {
    display: block;
    margin-top: 2rem;
    text-decoration: underline;
  }
}

.page-footer-category {
  margin-bottom: 5rem;
  margin-top: 5rem;
}

.product-list,
[data-layout-rbs-catalog-cross-selling],
[data-layout-rbs-catalog-cart-cross-selling],
.df-results,
.df-mobile__content {
  justify-content: flex-start;

  .product-list-title {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media (min-width: $sm-width) {
      justify-content: flex-start;
    }

    .btn {
      white-space: nowrap;

      @media (min-width: $sm-width) {
        margin-left: 3rem;
      }
    }
  }

  .row {
    justify-content: flex-start;
  }

  > .ng-scope,
  > [class^="product-list"] {
    @extend .row;
    justify-content: flex-start;
  }

  .product-item {
    margin-bottom: 3rem;

    @media (min-width: $sm-width) {
      margin-bottom: 6rem;
    }
  }

  .thumbnail {
    background-color: transparent !important;
    border: 0;
    border-radius: default(radius, medium);
    height: 100%;
    margin-bottom: 0;
    padding: 0 0 2rem;
    transition: none;

    &-inner {
      align-items: stretch;
      background-color: color(mono, light);
      border-radius: default(radius, block);
      box-shadow: default(shadow, light);
      display: flex;
      flex-flow: row wrap;

      // height: calc(100% - 5rem);
      justify-content: flex-start;
      padding: 1.5rem;
      position: relative;

      @media (min-width: $xs-width) {
        flex-direction: column;
        height: 100%;
        padding: 2rem;
      }
    }

    .comparator-button {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
      position: relative;

      @media (min-width: $xs-width) {
        margin-bottom: 0;
        margin-top: 0;
        position: absolute;
        right: 3rem;
        top: 3rem;
        z-index: 2;
      }

      .icon {
        display: inline-block;
        font-size: 1.3rem;
        margin-right: 0.8rem !important;
        margin-right: 5px;
      }

      span {
        cursor: pointer;
        font-size: 1.2rem;
        text-decoration: underline;
      }

      .product-page-text {
        display: none;
      }
    }

    .product-list-item-attributes {
      font-size: 0;
      left: 1.5rem;
      position: absolute;
      top: 1.5rem;
      z-index: 1;

      &-flag span {
        background-color: color(button, light);
        border-radius: 4px;
        color: color(mono, light);
        display: inline-block;
        font-size: 1.2rem;
        font-weight: default(weight, medium);
        margin-bottom: 0.3rem;
        padding: 2px 6px;
      }
    }

    .product-visual {
      background-color: color(mono, light);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 50%;
      min-width: 50%;
      overflow: hidden;
      position: relative;

      @media (min-width: $xs-width) {
        max-width: 100%;
        min-height: 0;
        min-width: 0;
        padding-bottom: 100%;
        width: 100%;
      }

      a:not(.product-repairabilityIndex) {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }

      .product-description {
        background-color: color(mono, light);
        border-radius: 0 0 5px 5px;
        border-top: 1px solid color(grey);
        bottom: -10rem;
        font-size: 1.4rem;
        left: 0;
        max-height: 7.5rem;
        opacity: 0%;
        padding: 1rem;
        position: absolute;
        transition: all 0.3s linear;
        width: 100%;

        @include line-clamp(3, 1.4);
      }

      .wishlist-btn {
        bottom: 0;
        position: absolute;
        right: 3px;
      }
    }

    .product-repairabilityIndex {
      font-size: 0;
      z-index: 1;
      bottom: 1rem;
      left: 1rem;
      position: absolute;
    }

    .product-tag {
      &-discount,
      &-attribute {
        @extend .tag;
        display: block;
      }

      &-ctn {
        left: 1.5rem;
        position: absolute;
        top: 1.5rem;
        z-index: 1;

        @media (min-width: $xs-width) {
          left: 3rem;
          top: 3rem;
        }
      }
    }

    .product-content {
      flex-grow: 1;
      max-width: 50%;

      @media (min-width: $xs-width) {
        max-width: 100%;
      }
    }

    .caption {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;
      line-height: 1;
      padding: 0 0 1rem 1.5rem;
      text-align: left;

      @media (min-width: $xs-width) {
        margin-top: 1.8rem;
        padding: 0;
      }
    }

    .product-info {
      font-weight: normal;
      font-family: default(font-family);
      margin: 0;

      span {
        display: block;
      }
      &__label {
        font-size: 1.8rem;
        font-weight: default(weight);
        line-height: 1.4;
        text-align: left;
      }
      &__brand {
        font-size: 1.8rem;
        font-weight: default(weight, medium);
        line-height: 1.4;
      }
      &__ref {
        font-size: 1.2rem;
        line-height: 1.4;
      }
    }

    .product-reviews {
      &__rate {
        display: inline-flex;
      }

      &__info {
        font-size: 1rem;
        margin-top: 0.5rem;
      }

      @media (min-width: $sm-width) {
        text-align: right;

        &__rate {
          display: flex;
          justify-content: flex-end;

          ul {
            margin: 0;
          }
        }

        &__info {
          font-size: 1.2rem;
          margin-top: 0.5rem;
        }
      }
    }

    @media (min-width: $sm-width) {
      .price-for-grid {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
      }
    }

    .product-price {
      align-items: flex-end;
      display: flex;
      height: 100%;
      margin-top: 1rem;

      @media (min-width: $xs-width) {
        margin-top: 2rem;
      }
    }

    .product-infos {
      border: 1px solid color(grey, medium);
      border-radius: default(radius, medium);
      display: none;
      margin-top: 1rem;
      overflow: hidden;

      .product-attributs {
        border-right: 1px solid color(grey, medium);
        flex-basis: 50%;
        font-size: 1.2rem;
        line-height: 1.7;
        max-width: 50%;
        min-width: 50%;
        padding: 1.5rem 3rem 1.5rem 2rem;
      }

      .product-availability {
        flex-grow: 1;
        margin-top: 0;

        .row {
          align-items: stretch;
          height: 100%;

          .col-xs-6 {
            align-items: center;
            display: flex;
            flex-grow: 1;
            max-width: 100%;
            padding: 1rem 2rem;
            width: 100%;

            ~ div {
              border-top: 1px solid color(grey, medium);
            }
          }
        }
      }
    }

    .price-for-list {
      display: none;
    }

    .advised-price {
      .icon-infos {
        font-size: 1.2rem;
      }
    }

    .price-with-taxes {
      display: block;
      text-align: left;

      .price-value {
        display: block;
        font-size: 2.8rem;
        font-weight: default(weight, medium);
        line-height: 1.4;
        margin-top: 6px;

        @media (min-width: $xs-width) {
          margin-top: 0;
        }
      }

      .price-old-value-block {
        span {
          display: inline-block;
          font-size: 1.2rem;
          line-height: 1.4;

          &:first-child {
            margin-right: 0.5rem;
          }
        }
      }

      .price-old-value {
        display: inline-block;
        font-size: 1.8rem;
        text-decoration: line-through;
        color: color(tertiary);
      }

      .discount-detail {
        display: none;
      }
    }

    .product-add {
      margin-top: 1rem;

      .btn {
        width: 100%;
      }

      .preorder-buttons {
        // reorder to keep alignment of buttons
        &__ctn {
          display: flex;
          flex-direction: column;

          > *:first-child {
            order: 2;
          }

          > *:nth-child(2) {
            order: 1;
          }
        }

        &__dates {
          padding-bottom: 0.5rem;
        }
      }
    }

    .btn {
      .glyphicon {
        display: none;
      }
    }

    .product-attribut-stock {
      flex-basis: 100%;
      margin-top: 1rem;
      max-width: 100%;
    }

    .product-attribute {
      display: inline-block;
      font-size: 1.4rem;
      line-height: 1.4;
      margin-right: 1rem;
    }

    .product-availability {
      font-size: 1.2rem;
      line-height: 1;
      margin-top: 2rem;
      min-height: 1.2rem;

      .row {
        align-items: center;
        justify-content: space-between;
        margin: 0;

        &.hidden {
          display: flex !important;
          visibility: hidden;
        }
      }

      .col-xs-6 {
        flex-basis: auto;
        float: none;
        padding: 0;
        width: auto;

        > span {
          color: color(text);

          &::before {
            background-color: color(alert, success);
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: 9px;
            margin-right: 1rem;
            width: 9px;
          }
        }

        &.text-danger {
          > span::before {
            background-color: color(alert, danger);
          }
        }

        .glyphicon {
          display: none;
        }
      }

      &__collect {
        display: none;

        &.visible {
          display: flex;
        }
      }

      &__item,
      &__collect {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__icon {
          margin-right: 1rem;

          .icon {
            font-size: 9px;
            color: color(alert, success);
          }
        }

        &.text-danger {
          .icon {
            color: color(alert, danger);
          }
        }
        & > span {
          display: block;
          &::before {
            display: none !important;
          }

          strong {
            display: block;
            font-weight: 300;
          }
        }
      }
    }

    .eco-tax {
      font-size: 1.2rem;
      line-height: 1.4;
    }

    .color-axis-list {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      padding-bottom: 1.5rem;

      li {
        line-height: 1;
      }

      &-item {
        border-radius: default(radius, axis);
        display: inline-block;
        height: 2rem;
        margin-right: 5px;
        text-decoration: none;
        width: 2rem;
      }

      &-seemore {
        font-size: 1rem;
        text-decoration: underline;
      }
    }

    .product-footer {
      width: 100%;

      @media (max-width: $xs-width-max) {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-end;
      }

      .btn {
        @extend .btn-secondary;
      }
    }

    /*  &.has-comparator {
      .product-content {
        @media (max-width: $xs-width) {
          padding-top: 2.5rem;
        }
      }

      .comparator-button {
        @media (max-width: $xxs-width) {
          left: calc(50% + 1.5rem);
          right: auto;
          top: 1.5rem;
        }
      }
    } */
  }
}

.product-list-one-line {
  overflow: hidden;
  padding: 6rem 0 0;

  .thumbnail {
    flex-direction: column;

    .caption {
      margin-left: 0;
      margin-top: 2rem;
    }

    .product-visual {
      min-width: auto;
      width: 100%;

      @media (min-width: $xxs-width) {
        min-width: auto;
        width: 100%;
      }
    }
  }

  .row {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 2rem;

    @media (min-width: $sm-width) {
      padding: 0 4.5rem;
    }
  }

  .product-item {
    @media (max-width: ($sm-width - 1px)) {
      flex-basis: 38%;
      max-width: 38%;
      min-width: 38%;
    }
    @media (max-width: ($xs-width - 1px)) {
      flex-basis: 60%;
      max-width: 60%;
      min-width: 60%;
    }
  }
}

.category-navbar {
  margin-right: -2rem;

  @media (min-width: $md-width) {
    margin-right: -3.5rem;
  }

  nav {
    overflow: auto;
  }

  .row {
    flex-wrap: nowrap;

    @media (min-width: $sm-width) {
      flex-wrap: wrap;
    }
  }

  .xxs6 {
    flex-grow: 0;
  }

  &-title {
    margin: 5rem 0;
  }

  &-item {
    display: block;
    margin-bottom: 3rem;
    text-align: center;

    &-img {
      background-color: color(mono, light);
      border-radius: default(radius, menu);
      font-size: 0;
      overflow: hidden;
      height: 6rem;
      width: 10rem;

      @media (min-width: $md-width) {
        height: 8.5rem;
        width: 14.5rem;
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &-title {
      font-family: inherit;
      font-size: 1.4rem;
      line-height: 1.2;
      margin: 1rem 0 0;
      text-align: center;
    }
  }

  &.in-the-grid {
    margin-right: 0;

    nav {
      overflow: hidden;
    }

    .row {
      flex-wrap: wrap;
      width: auto !important;
    }

    .category-navbar-item-img {
      height: auto;
      width: 100%;

      img {
        min-width: none;
        width: 100%;
      }

      @media (min-width: $md-width) {
        height: auto;
        width: 100%;
      }
    }
  }

  &.horizontal-centered {
    nav {
      overflow: hidden;
    }

    .row {
      flex-wrap: wrap;

      @media (min-width: $sm-width) {
        justify-content: center;
      }
    }
  }
}

// more product by line for very big screen

/* @media (min-width: $xxxl-width) {
  [data-layout-rbs-seo-facet-url-decorator] + [data-layout-rbs-catalog-product-list] {
    [data-rbs-catalog-products-list-v3]:not(.list) .product-list > {
      .row,
      [class^="product-list"] {
        > .lg4 {
          flex-basis: 25%;
          min-width: 25%;
        }
      }
    }
  }
} */

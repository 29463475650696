@use "sass:color";

.styleguide {
  // modal surcharge

  .styleguide-subbloc {
    overflow: visible;
  }

  pre {
    overflow: scroll;
  }

  /* helper */
  .text-color-sg {
    color: mix(#fff, color(text), 70%);
  }

  .code {
    color: color(secondary);
    font-style: italic;
    font-weight: default(weight, medium);
  }

  .bloc-info-sg {
    background-color: color.mix(#fff, color(primary), 70%);
    border-radius: 3px;
    padding: 12px 15px 1px;
  }

  /* structure */

  &-logo {
    padding: 20px 0;

    img {
      margin: auto;
      width: 250px;
    }
  }

  &-header {
    background: color(background, light);
    box-shadow: default(shadow, secondary);
    position: relative;
    z-index: 1;

    .styleguide-title {
      display: block;
      font-size: 2rem;
      font-weight: default(weight, medium);
      padding: 10px;
      text-align: center;
    }
  }

  &-aside {
    background: color(background, light);

    &-inner {
      overflow: auto;
      padding: 20px;
      position: relative;
      width: 100%;

      &.sticky {
        bottom: 0;
        left: 0;
        position: fixed;
        top: 50px;
        width: 16.667%;
      }
    }

    ul {
      li {
        a {
          display: block;
          font-size: 1.8rem;
          font-weight: default(weight, medium);
          padding: 7px 0;

          @include animated;
        }
      }
    }
  }

  &-details {
    &-inner {
      > .h1 {
        margin-bottom: 10px;
        padding-top: 3rem;
      }

      @media (min-width: $xs-width) {
        padding: 0 15px;
      }
      @media (min-width: $md-width) {
        padding: 0 30px;
      }
    }

    .isCode {
      background: color(background, dark);
      border-radius: 3px;
      box-shadow: 1px 1px 5px black;
      font-family: default(font-family);
      font-size: 1.2rem;
      font-weight: default(weight, medium);
      letter-spacing: 0.2px;
      overflow-x: auto;
      padding: 15px;
      white-space: nowrap;

      @media (min-width: $md-width) {
        font-size: 1.4rem;
      }
    }
  }

  &-subbloc {
    margin-bottom: 7rem;
  }

  &-bloc {
    max-width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
    position: relative;

    &::before {
      @include poa(null, 1.5rem, 0, 1.5rem);
      border-bottom: 1px solid color(border, primary);
      content: "";
    }

    &-title {
      margin-bottom: 10px;
    }

    &-subtitle {
      font-weight: default(weight, bold);
      margin-bottom: 1rem;
    }

    &-content-infos {
      margin-bottom: 2rem;
    }
  }

  &-colors,
  &-fonts {
    .inner {
      border: 1px solid color(border);
    }
  }

  &-colors,
  &-fonts,
  &-contours,
  &-containers {
    ul {
      li {
        margin-bottom: 15px;

        .inner {
          padding: 8px;

          span {
            display: block;
            padding: 2px 0;
          }
        }
      }
    }
  }

  &-contours {
    .radius {
      border: 1px solid color(border);
    }
  }

  &-colors {
    ul {
      li {
        .inner {
          .banner {
            height: 30px;
            line-height: 27px;
            margin-bottom: 15px;
            width: 100%;

            &.bordered {
              &.bg-white {
                border: 1px solid #efefef;
              }
            }
          }

          span {
            font-weight: default(weight, base);
          }
        }
      }
    }
  }

  &-titles {
    .h1,
    .h2,
    .h3,
    .h4,
    .h1-mea {
      margin-bottom: 10px;
    }

    .exemple-hn {
      margin-bottom: 30px;

      > span {
        margin-bottom: 5px;
      }
    }
  }

  &-buttons {
    .exemple-btn {
      border: 1px solid color(border);
      margin-bottom: 30px;
      padding: 15px 0;

      @media (min-width: $md-width) {
        padding: 15px;
      }

      .button-bloc {
        margin-bottom: 10px;

        @media (min-width: $md-width) {
          margin-bottom: 0;
        }
      }

      > span {
        margin-bottom: 5px;
      }
    }
  }
}

/* code bloc */
.code-bloc {
  position: relative;

  .copy-btn {
    @include poa(0, 0, null, null);
    background-color: white;
    border: 1px solid #333;
    display: inline-block;
    line-height: 1;
    padding: 2px 10px;

    &:hover {
      // background-color: color(background, dark);
    }
  }
}

/*
    Orginal Style from ethanschoonover.com/solarized (c) Jeremy Hull <sourdrums@gmail.com>
    */

.hljs {
  background: #002b36;
  color: #fff;
  display: block;
  font-family: verdana, arial, sans-serif;
  letter-spacing: 0.2px;
  line-height: 1.3;
  overflow-x: auto;
  padding: 1.5rem;
}

.hljs-comment,
.hljs-quote {
  color: #586e75;
}

/* Solarized Green */
.hljs-keyword,
.hljs-selector-tag,
.hljs-addition {
  color: #859900;
}

/* Solarized Cyan */
.hljs-number,
.hljs-string,
.hljs-meta .hljs-meta-string,
.hljs-literal,
.hljs-doctag,
.hljs-regexp {
  color: #41d1c6;
}

/* Solarized Blue */
.hljs-title,
.hljs-section,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #63beff;
  font-weight: normal;
}

/* Solarized Yellow */
.hljs-attribute,
.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-class .hljs-title,
.hljs-type {
  color: #fbd039;
}

/* Solarized Orange */
.hljs-symbol,
.hljs-bullet,
.hljs-subst,
.hljs-meta,
.hljs-meta .hljs-keyword,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-link {
  color: #cb4b16;
}

/* Solarized Red */
.hljs-built_in,
.hljs-deletion {
  color: #dc322f;
}

.hljs-formula {
  background: #073642;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: normal;
}

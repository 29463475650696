.glossary {
  padding-top: 7rem;

  @media (min-width: $md-width) {
    padding-bottom: 27rem;
  }

  &-block {
    .title-glossary {
      font-size: 2.5rem;
      font-weight: default(weight, medium);
      line-height: 4rem;
      margin-bottom: 1rem;
      text-transform: uppercase;

      @media (min-width: $sm-width) {
        font-size: 2.5rem;
        line-height: 4rem;
      }
    }

    li {
      @media (min-width: $sm-width) {
        display: inline-block;
        width: 33%;
      }
    }

    a {
      font-size: 1.6rem;
      line-height: 2.5;
      text-decoration: none;

      @extend .link2;
    }
  }
}

/////////////////////////////////////////////////////////////////////////////
// basique bootstrap panel
.panel {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &-heading {
    border: 0;
    border-radius: 0;
    font-weight: default(weight, bold);
  }

  &-body {
    border: none;
    font-weight: default(weight, medium);
    line-height: 1.4;
    padding: 1rem 0;
    text-align: left;
  }

  &-default {
    > .panel-heading {
      background: transparent;
      border-bottom: 1px solid color(border);
      color: color(primary);
      font-size: 1.8rem;
      font-weight: default(weight, medium);
      padding: 1rem 0;
    }
  }

  @media (min-width: $md-width) {
    &-body {
      padding: 3rem 0;
    }
  }

  &-warning,
  &-success,
  &-danger {
    @extend .panel-default;
  }
}

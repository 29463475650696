.footer-main {
  clear: both;

  &-inner {
    background-color: color(grey, light);
    padding: 2.5rem 7.5rem 2rem;

    @media (min-width: $md-width) {
      padding-bottom: 3rem;
      padding-top: 3rem;
    }

    .xxs12 {
      @media (min-width: $md-width) {
        flex-grow: 0;
        min-width: 25%;
      }
      @media (min-width: $xxl-width) {
        min-width: 20%;
      }
    }
  }

  [data-rbs-website-menu] {
    margin-bottom: 2rem;

    @media (min-width: $md-width) {
      margin-bottom: 0;
    }

    h3 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }

  ul.bullet {
    padding: 0;

    li {
      font-size: 1.4rem;
      line-height: 1.4;

      &::before {
        content: none;
      }
    }
  }

  &-joinus {
    a {
      font-size: 1.8rem;
      font-weight: default(weight, medium);
      text-decoration: none;
    }
  }

  &-social {
    background-color: color(primary);
  }

  .social-ctn {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0;
    padding: 1.2rem 0;

    .social-title {
      color: color(mono, light);
      display: inline-block;
      flex-basis: auto;
      font-family: default(font-family);
      font-size: 1.8rem;
      font-weight: default(weight, medium);
      line-height: 1;
      margin-bottom: 0;
      margin-right: 1.5rem;
      max-width: 100%;
    }

    a {
      color: color(mono, light);
      display: inline-block;
      font-size: 2rem;
      line-height: 1;
      margin-right: 1.5rem;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &-nav {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;

    .list-inline {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        font-size: 1.4rem;
        line-height: 1.6;
        padding: 0 1.5rem;
      }
    }
  }
}

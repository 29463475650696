@use "sass:map";

// génération de class utilitaires de couleur
$borders: (
  "base": (
    color: color(border),
    width: 1px,
  ),
  "dark": (
    color: color(border, dark),
    width: 1px,
  ),
  "medium": (
    color: color(border, medium),
    width: 1px,
  ),
);

@mixin border-mixin($selector, $data) {
  $width: map-get($data, width);
  $color: map-get($data, color);
  #{$selector} {
    border: $width solid $color;

    &-top {
      border-top: $width solid $color;
    }

    &-bottom {
      border-bottom: $width solid $color;
    }

    &-left {
      border-left: $width solid $color;
    }

    &-right {
      border-right: $width solid $color;
    }
  }
}
// stylelint-disable
@each $key, $data in $borders {
  @if ($key == "base") {
    @include border-mixin(".border", $data);
  } @else {
    @include border-mixin(".border-#{$key}", $data);
  }
}

.border {
  &-0 {
    border: 0;
  }
  &-top-0 {
    border-top: 0;
  }
}

.thumbnail-announcement {
  .announcement {
    background-color: color(secondary);
    border-radius: default(radius, block);
    box-shadow: default(shadow, light);
    color: color(mono, light);
    font-size: 2.4rem;
    height: 100%;
    min-height: 25rem;
    overflow: hidden;
    position: relative;

    &-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-family: default(font-family, secondary);
      height: 100%;
      justify-content: center;
      left: 0;
      padding: 0 5.7rem;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
    }
  }
}

@use "sass:color";
@use "sass:map";

// Breakpoint
$xxxl-width: 1920px;
$xxl-width: 1500px;
$xl-width: 1320px;
$lg-width: 1200px;
$md-width: 992px;
$sm-width: 768px;
$xs-width: 480px;
$xxs-width: 360px;
$xxxl-width-max: $xxxl-width - 1px;
$xxl-width-max: $xxl-width - 1px;
$xl-width-max: $xl-width - 1px;
$lg-width-max: $lg-width - 1px;
$md-width-max: $md-width - 1px;
$sm-width-max: $sm-width - 1px;
$xs-width-max: $xs-width - 1px;
$xxs-width-max: $xxs-width - 1px;
$gutter: 30px;
$gutter-ctn: 3.5rem;
$breadcrumb-height: 6rem;
$search-height: 5rem;
$container-order-process: 80rem;

// Grid
$grid-columns: 12;
$column-width: calc(100 / $grid-columns);
$breakpoints: (
  xxs: (
    container: 100%,
    container-small: 100%,
    gutter: 2rem,
    gutter-ctn: 2rem,
  ),
  xs: (
    container: 100%,
    container-small: 100%,
    min-width: $xs-width,
    gutter: $gutter,
    gutter-ctn: 2rem,
  ),
  sm: (
    container: 100%,
    container-small: 100%,
    min-width: $sm-width,
    gutter: $gutter,
    gutter-ctn: 2rem,
  ),
  md: (
    container: 960px,
    container-small: 960px,
    min-width: $md-width,
    gutter: $gutter,
    gutter-ctn: $gutter-ctn,
  ),
  lg: (
    container: 1170px,
    container-small: 1170px,
    min-width: $lg-width,
    gutter: $gutter,
    gutter-ctn: $gutter-ctn,
  ),
  xl: (
    container: 1290px,
    container-small: 1290px,
    min-width: $xl-width,
    gutter: $gutter,
    gutter-ctn: $gutter-ctn,
  ),
  xxl: (
    container: 1500px,
    container-small: 1390px,
    min-width: $xxl-width,
    gutter: $gutter,
    gutter-ctn: $gutter-ctn,
  ),
);

@function break($breakpoint, $break) {
  @return map-get(map-get($breakpoints, $breakpoint), $break);
}

$colors: (
  mono: (
    light: #fff,
    dark: #000,
  ),
  primary: (
    base: #511742,
    dark: rgba(#511742, 0.8),
    medium: color.mix(#511742, #000, 50%),
    light: rgba(#511742, 0.2),
  ),
  secondary: (
    base: #a2195b,
    dark: rgba(#a2195b, 0.8),
    medium: color.mix(#a2195b, #000, 50%),
    light: rgba(#a2195b, 0.2),
  ),
  tertiary: (
    base: #e95a1a,
    dark: rgba(#e95a1a, 0.8),
    medium: color.mix(#e95a1a, #000, 50%),
    light: rgba(#e95a1a, 0.2),
  ),
  quaternary: (
    base: #fdce59,
    dark: rgba(#fdce59, 0.8),
    medium: color.mix(#fdce59, #000, 50%),
    light: rgba(#fdce59, 0.2),
  ),
  grey: (
    base: #313131,
    dark: #858585,
    disabled: #c4c4c4,
    medium: #e5e5e5,
    light: #f6f6f6,
  ),
  ternary: (
    base: #e30613,
  ),
  text: (
    base: #313131,
    dark: #858585,
    medium: #e5e5e5,
    light: #f6f6f6,
  ),
  alert: (
    warning: #ffc52f,
    danger: #f00,
    success: #63f91c,
    success2: #96d074,
    success3: #4fc716,
    info: #2fcdff,
  ),
  shadow: (
    base: #e5e5e5,
    overlay: rgba(0, 0, 0, 50%),
    navbar: rgba(0, 0, 0, 10%),
    button: rgba(0, 0, 0, 50%),
  ),
  border: (
    base: #313131,
    dark: #858585,
    medium: #e5e5e5,
    light: #f6f6f6,
    star: #b4b4b4,
  ),
  button: (
    base: #313131,
    light: rgba(#313131, 0.8),
    dark: color.mix(#313131, #000, 50%),
    disabled: rgba(#313131, 0.3),
    green: #55c07a,
  ),
  background: (
    base: #313131,
    dark: #858585,
    medium: #e5e5e5,
    light: #f6f6f6,
  ),
);

@function color($color, $tone: "base") {
  @return map.get(map.get($colors, $color), $tone);
}

$default: (
  font-size: (
    base: 1.6rem,
    h1: 5rem,
    h2: 3.5rem,
    h3: 2.4rem,
    mh1: 3.5rem,
    mh2: 2.4rem,
    mh3: 2.4rem,
  ),
  font-family: (
    base: "Fira",
    secondary: "Poppins",
    icon: "icomoon",
  ),
  weight: (
    base: 400,
    medium: 500,
    bold: 500,
  ),
  radius: (
    base: 3px,
    medium: 1rem,
    button: 1rem,
    input: 1rem,
    alert: 0.5rem,
    block: 2rem,
    menu: 1.2rem,
    big: 10rem,
  ),
  line-height: (
    base: 1.4,
  ),
  shadow: (
    base: 0 0.3rem 0.6rem rgba(0, 0, 0, 16%),
    light: 0 0.5rem 0.8rem rgba(0, 0, 0, 5%),
    medium: 5px 5px 5px #e5e5e5,
  ),
);

@function default($property, $value: "base") {
  @return map.get(map.get($default, $property), $value);
}

$heights: (
  banner: (
    "base": 4rem,
    "xs": 4rem,
    "sm": 4rem,
    "md": 4rem,
    "lg": 4rem,
    "stack": 4rem,
  ),
  header: (
    "base": 14rem,
    "xs": 14rem,
    "sm": 14rem,
    "md": 17.9rem,
    "lg": 17.9rem,
    "nomenu": 17.9rem,
    "stack": 17.9rem,
  ),
);

@function height($type, $size: "base") {
  @return map.get(map.get($heights, $type), $size);
}

$paddings: (
  panel-x: (
    "base": 2rem,
    "md": 2rem,
  ),
);

@function padding($type, $size: "base") {
  @return map.get(map.get($paddings, $type), $size);
}

$widths: (
  ctn: (
    "sm": 47rem,
  ),
);

@function width($type, $size: "base") {
  @return map.get(map.get($widths, $type), $size);
}

$icons: (
  bulle_sourir: "\e900",
  carte: "\e901",
  centrer: "\e902",
  checkbox-false: "\e903",
  checkbox-true: "\e904",
  arrow-down: "\e905",
  arrow-left: "\e906",
  arrow-right: "\e907",
  arrow-up: "\e908",
  check: "\e909",
  comparator: "\e90a",
  close: "\e90b",
  close-round: "\e94c",
  talk: "\e90c",
  empty-star: "\e90d",
  full-star: "\e90e",
  arrow: "\e90f",
  circle-arrow-down: "\e910",
  circle-arrow-right: "\e911",
  circle-arrow-left: "\e912",
  circle-arrow-top: "\e913",
  mail: "\e914",
  phone: "\e915",
  geoloc: "\e916",
  heart: "\e917",
  print: "\e918",
  infos: "\e919",
  installation: "\e91a",
  list: "\e91b",
  store: "\e91c",
  magasin_petit: "\e91d",
  map-marker: "\e91e",
  menu: "\e91f",
  minus: "\e920",
  pictos_univers-conseils: "\e921",
  pictos_univers-lessive: "\e922",
  pictos_univers-top_marques: "\e923",
  pictos_univers-top_ventes: "\e924",
  plus: "\e925",
  point: "\e946",
  produits_similaires: "\e926",
  user: "\e927",
  radio-false: "\e928",
  radio-true: "\e929",
  rdv_conseil: "\e92a",
  refresh: "\e92b",
  e-resa: "\e92c",
  retrait-magasin: "\e92d",
  livraison_et_installation: "\e92f",
  sav_reactif: "\e930",
  retour_colis: "\e931",
  sav1: "\e932",
  sav2: "\e933",
  search: "\e934",
  shopping_cart_plein: "\e935",
  shopping_cart_vide: "\e936",
  smiley_triste: "\e937",
  switch-desabled: "\e938",
  switch-false: "\e93a",
  switch-true: "\e93c",
  trash: "\e947",
  download: "\e93e",
  union: "\e93f",
  alert: "\e940",
  facebook: "\e92e",
  linkedin: "\e941",
  reassurance_carrier: "\e943",
);

@function icon($icon) {
  @return map.get($icons, $icon);
}
